export default {
  fi: {
    // Routes
    'change-reservation': 'muuta-varausta',
    'confirm-change': 'vahvista-muutos',
    'show-next-3-days': 'Näytä seuraavat kolme päivää',
    'show-next-week': 'Näytä ensi viikko',
    'show-previous-3-days': 'Näytä edelliset kolme päivää',
    'show-previous-week': 'Näytä edellinen viikko',
    'skip-to-main-content': 'Siirry pääsisältöön',
    confirm: 'vahvistus',
    confirmed: 'vahvistettu',
    logout: 'logout',
    payment: 'maksu',
    preinformation: 'esitiedot',
    reservation: 'ajanvaraus',
    // General
    'a service ticket': 'palvelusetelillä',
    'Add to calendar': 'Lisää kalenteriin',
    'Advance reservation times': 'Ennakkovarausajat',
    'All available times': 'Kaikki vapaat ajat',
    'Are you sure you wish to cancel the reservation?':
      'Oletko varma, että haluat keskeyttää varauksen?',
    'Available times': 'vapaita aikoja',
    'Change reservation': 'Muuta varausta',
    'Choose new time': 'Valitse uusi aika',
    'Choose payment method': 'Valitse maksutapa',
    'Confirm change of reservation': 'Vahvista uusi aika',
    'Confirm change': 'Vahvista muutos',
    'Confirm Reservation': 'Vahvista varaus',
    'Contract ID': 'Kampanjakoodi',
    'Date of birth': 'Syntymäpäivä',
    'DD-MM-YYYY': 'PP-KK-VVVV',
    'Dentist reservation': 'Hammaslääkäri ajanvaraus',
    'Fill in information': 'Esitietolomake',
    'Fill in your medical information and make your visit smoother.':
      'Täytä esitietolomake, niin nopeutat asiointiasi.',
    'Form contains invalid values': 'Lomake sisältää virheellisiä arvoja',
    'I have read and agree to': 'Olen lukenut ja hyväksyn',
    'Implant care times': 'Hammasimplanttiajat',
    'implant care': 'implanttihoitoon',
    'in advance': 'ennakkovarauksen -10%',
    'Information about the visit': 'Tutustu ohjeisiin',
    'Insert contract ID': 'Syötä kampanjakoodi',
    'Invalid email address': 'Virheellinen sähköpostiosoite',
    'Invalid phone number': 'Virheellinen puhelinnumero',
    'Invalid ssn': 'Virheellinen sosiaaliturvatunnus',
    'Limited availability of treatments':
      'Tässä aikaikkunassa ei tehdä kaikkia mahdollisia hoitoja',
    'Making a reservation': 'Vinkkejä varauksen tekemiseen',
    'More information': 'Lisätietoa',
    'My health information has not changed':
      'Terveystietoni eivät ole muuttuneet',
    'No advance pricing slots available':
      'Ei ennakkovaraajan aikoja tälle päivälle',
    'No available times': 'ei vapaita aikoja',
    'No times available': 'Ei aikoja saatavilla',
    'Pay reservation fee': 'Maksa varausmaksu',
    'Phone number': 'Puhelinnumero',
    'Please check that you have filled in correct information.':
      'Ole hyvä ja tarkista syöttämäsi tiedot (virhekoodi 2).',
    'please go back and reselect a slot':
      'palaa aikatauluun ja valitse aikaikkuna uudelleen',
    'Please note': 'Huom!',
    'Preliminary Information': 'Esitiedot',
    'Proceed to update your information': 'Siirry päivittämään tietosi',
    'Proceed with payment': 'Siirry maksamaan',
    'Reservation cannot be confirmed. Please contact the customer service.':
      'Varauksen vahvistaminen epäonnistui (virhekoodi 1). Ota yhteys asiakaspalveluun.',
    'Reservation Fee': 'Varausmaksu',
    'Reserve a time by phone': 'Varaa aika puhelimella',
    'Save information': 'Tallenna tiedot',
    'Select Timeslot': 'Ajan valinta',
    'Service ticket times': 'Palveluseteliajat',
    'Service voucher number': 'Palvelusetelin numero',
    'Show all available times': 'Näytä kaikki vapaat ajat',
    'Social security number': 'Henkilötunnus',
    'Something went wrong. Please contact the customer service.':
      'Jotain meni vikaan. Ota yhteys asiakaspalveluun.',
    'Something went wrong': 'Jokin meni pieleen',
    'Special Services': 'Erityispalvelut',
    'Temporary reservation expired. Please start over.':
      'Väliaikainen varauksesi on ehtinyt vanhentua. Ole hyvä, ja palaa alkuun  (virhekoodi 3).',
    'The clinic is closed': 'Klinikkamme on suljettu',
    'The new time slot you selected has higher reservation fee than the original one. The difference will be paid at the clinic.':
      'Valitsemassasi uudessa aikaikkunassa on korkeampi varausmaksu kuin alkuperäisessä. Erotus maksetaan klinikalla sisäänkirjautumisen yhteydessä.',
    'the terms': 'sopimusehdot',
    'Time Recommendations': 'Ehdotetut ajat',
    'Too many reservation reasons':
      'Liian monta hoitoonhakeutumisen syytä (suurin sallittu määrä on',
    'Too short first name': 'Liian lyhyt etunimi',
    'Too short last name': 'Liian lyhyt sukunimi',
    'Verify reservation': 'Vahvista varaus',
    'You are making a change to your reservation. Your new time slot will be:':
      'Olet muuttamassa varaustasi. Uusi aikaikkunasi on:',
    'You need to select a reservation reason':
      'Valitse hoitoonhakeutumisen syy',
    'Your reservation is confirmed': 'Varauksesi on vahvistettu',
    'YYYY-MM-DD': 'VVVV-KK-PP',
    "I'm coming for": 'Tulen',
    "I'm reserving with": 'Tulen hoitoon',
    "I'm reserving": 'Teen',
    "Root canal treatment isn't done in this timeframe":
      'Juurihoitoa ei voida tehdä tässä aikaikkunassa',
    "This reservation can't be moved anymore.":
      'Tätä varausta ei voi enää siirtää.',
    Cancel: 'Peruuta',
    Change: 'Muuta',
    Checkin: 'Ilmoittautuminen',
    Close: 'Sulje',
    Confirmation: 'Vahvistus',
    Date: 'Päivämäärä',
    Details: 'Lisätiedot',
    Email: 'Sähköposti',
    Firstname: 'Etunimi',
    go_to_clinic_page: 'Siirry verkkosivuillemme',
    Klo: 'Klo',
    Lastname: 'Sukunimi',
    No: 'Ei',
    noRootCanalTreatments:
      'Huom! Päivän viimeisessä aikaikkunassa emme välttämättä voi toteuttaa pitkiä hoitoja. Esim: juurihoito, valkaisu, laaja hammaskiven poisto.',
    noRootCanalTreatmentsLineOne:
      'Päivän viimeisessä aikaikkunassa emme välttämättä voi toteuttaa pitkiä hoitoja.',
    noRootCanalTreatmentsLineTwo:
      'Esim: juurihoito, valkaisu, laaja hammaskiven poisto.',
    Price: 'Hinta',
    Reasons: 'Hoitoon hakeutumisen syyt',
    Reservation: 'Ajanvaraus',
    Timeframe: 'Aikaikkuna',
    Wk: 'Vk', // Week,
    Yes: 'Kyllä',
    // Authentication
    authenticating: 'Autentikoidaan...',
    logged_out: 'Sinut on kirjattu ulos ja voit sulkea selainikkunan.',
    logging_out: 'Kirjaudutaan ulos...',
    logout_error: 'Uloskirjautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
    Logout: 'Kirjaudu ulos',
    redirecting_to_idp: 'Sinut ohjataan autentikointipalveluun...',
    user_mismatch: 'Kirjautuneen käyttäjän tulee täsmätä varaukseen.',
    // Checkin
    'You have a reservation': 'Sinulla on ajanvaraus',
    'Checkin info':
      'Ilmoittauduthan saapuneeksi vasta kun olet klinikan tiloissa.',
    'Checkin button text': 'Ilmoittaudu saapuneeksi',
    'General checkin error':
      'Tapahtui virhe, ole hyvä ja yritä uudelleen. Jos virhe jatkuu, ilmoittaudu vastaanottotiskillä.',
    'Checkin error':
      'Ilmoittautumisessa tapahtui virhe, ole hyvä ja yritä uudelleen. Jos virhe jatkuu, ilmoittaudu vastaanottotiskillä.',
    'Checkin successful': 'Ilmoittautuminen onnistui',
    'Checkin successful info':
      'Ole hyvä ja istu odottamaan. Sinut kutsutaan huoneeseen nimellä.',
    'Unknown reservation code': 'Varausta ei löytynyt',
    'Unknown reservation code info':
      'Ole hyvä ja ilmoittaudu vastaanottotiskillä.',
    'Checkin done': 'Olet ilmoittautunut',
    'Missing anamnesis info':
      'Ole hyvä ja täytä esitietolomake ennen ilmoittautumista.',
    'Fill out preinformation': 'Täytä esitietolomake',
    // Reservation reasons
    ACHE: 'Särky',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_10_WITH_PROBLEM:
      'Vuositarkastus ja puhdistus +10 vuotta vaivaa',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_10_WITHOUT_PROBLEM:
      'Vuositarkastus ja puhdistus +10 vuotta ilman vaivaa',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15_WITH_PROBLEM:
      'Vuositarkastus ja puhdistus +15 vuotta vaivaa',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15_WITHOUT_PROBLEM:
      'Vuositarkastus ja puhdistus +15 vuotta ilman vaivaa',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15:
      'Vuositarkastus ja puhdistus +15 vuotta',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_10_WITH_PROBLEM:
      'Vuositarkastus ja puhdistus -10 vuotta vaivaa',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_10_WITHOUT_PROBLEM:
      'Vuositarkastus ja puhdistus -10 vuotta ilman vaivaa',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15_WITH_PROBLEM:
      'Vuositarkastus ja puhdistus -15 vuotta vaivaa',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15_WITHOUT_PROBLEM:
      'Vuositarkastus ja puhdistus -15 vuotta ilman vaivaa',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15:
      'Vuositarkastus ja puhdistus -15 vuotta',
    BARN_K0: 'Tarkastuskäynti, lapsi (ryhmä 0)',
    BARN_K1: 'Tarkastuskäynti, lapsi (ryhmä 1)',
    BARN_K2: 'Tarkastuskäynti, lapsi (ryhmä 2)',
    BROKEN_TOOTH_OR_FILLING: 'Murtunut hammas tai paikka',
    CALCULUS: 'Hammaskiven poisto',
    CBCT: 'CBCT',
    CERAMIC_CONSULTATION: 'Keramia - Konsultaatiokäynti',
    CERAMIC_PROSTETIC_ATTACHMENT: 'Keramia - Proteettinen kiinnitystyö',
    CERAMIC_REPLICA: 'Keramia - Jäljennöskäynti',
    CHECK_UP_AND_ASSESSMENT: 'Tarkastus ja hoidon tarpeen arviointi',
    CHECK_UP: 'Tarkastus',
    CLEANING_WITH_AIRFLOW_DPSI_1_2: 'Soodapuhdistus (DPSI 1 tai 2)',
    CLEANING_WITH_AIRFLOW_DPSI_3: 'Soodapuhdistus (DPSI 3- tai 3+)',
    CLEANING_WITH_AIRFLOW_DPSI_4: 'Soodapuhdistus (DPSI 4)',
    CLEANING_WITH_AIRFLOW: 'Soodapuhdistus',
    CONTINUATION_OF_TREATMENT: 'Jatkohoito',
    CRACK: 'Lohkeama',
    CROWN_AND_BRIDGE_PLACEMENT: 'Kruunun tai sillan asettaminen',
    CROWN_AND_BRIDGE_PREP: 'Kruunun tai sillan valmistelu',
    CROWN_CEMENTING: 'Kruunun sementointi',
    DENTAL_NURSE: 'Hammashoitaja',
    DENTIST_CHECKUP: 'Hammaslääkärin tarkastus',
    DIGITAL_SMILE_DESIGN_FACINGS_OR_CROWNS: 'DSD Facings/Crowns',
    DOCUMENT_RETRIEVAL: 'Asiakirjojen nouto',
    DSD_CONSULTATION: 'Digital Smile Design ensimmäinen konsultointi',
    DSD_FACINGS_OR_CROWNS: 'DSD Facings/Crowns',
    DSD_INTAKE: 'Digital Smile suunnittelu',
    DSD_PHOTOSHOOT: 'DSD maskeeraus / valokuvaus',
    DSD_TEST_DRIVE: 'Digital Smile Design koe-aja hymysi',
    FACIAL_ANALYSIS_DETERMINATION: 'Kasvojen analyysi',
    FILLING_DECAY_1_OLDER_10: '1 paikka +10 vuotta',
    FILLING_DECAY_1_YOUNGER_10: '1 paikka -10 vuotta',
    FILLING_DECAY_1: '1 paikka',
    FILLING_DECAY_2_OLDER_10: '2 paikkaa +10 vuotta',
    FILLING_DECAY_2_YOUNGER_10: '2 paikkaa -10 vuotta',
    FILLING_DECAY_2: '2 paikkaa',
    FILLING_DECAY_3_OLDER_10: '3 tai enemmän paikkaa +10 vuotta',
    FILLING_DECAY_3_YOUNGER_10: '3 tai enemmän paikkaa -10 vuotta',
    FILLING_DECAY_3: '3 tai enemmän paikkaa',
    FILLING_DECAY: 'Paikkaus',
    GRINDING_OR_CLENCHING: 'Hampaiden narskuttelu',
    HOMEWHITENING: 'Kotivalkaisu',
    HYGIENIST_CHECKUP: 'Suuhygienistin tarkistus',
    HYGIENIST: 'Suuhygienisti',
    IMPLANT_CHECKUP: 'Implantti - Tarkastuskäynti',
    IMPLANT_CONSULTATION: 'Implantti - Konsultaatio',
    IMPLANT_HOME_CARE_CONTROL_VISIT: 'Implantti - Kotihoidon kontrollikäynti',
    IMPLANT_PLACEMENT: 'Implantin asettaminen',
    IMPLANT_PROSTETIC_ATTACHMENT:
      'Implantti - Proteettisen työn kiinnityskäynti',
    IMPLANT_PROSTETIC_CONTROL_VISIT:
      'Implantti - Proteettisen työn kontrollikäynti',
    IMPLANT_PROSTETIC_IMPRESSION: 'Implantti - Proteettinen jäljennöskäynti',
    IMPLANT_SURGICAL: 'Implantti - Kirurginen käynti',
    INMAN_APPLICATION: 'Inman Aligner asetuskäynti',
    INMAN_CHECKUP: 'Inman Aligner tarkastuskäynti',
    INMAN_CONSULTATION: 'Inman Aligner konsultaatiokäynti',
    INMAN_FINAL: 'Inman Aligner loppukäynti',
    INTERPRETER: 'Tulkki',
    INVISALIGN_CHECK_UP: 'Invisalign tarkistuskäynti',
    NEW_PATIENT: 'Uusi asiakas',
    OCCLUSAL_SPLINT: 'Purentakisko',
    OPG: 'OPG',
    OREBRO_PATIENT_LIFT_NEEDED: 'Potilasnostimen tarve',
    ORTHODONTICS_ASSESSMENT: 'Ortodontia - assesment',
    ORTHODONTICS_LOOSE_BRACKET: 'Orthodontie - loose bracket',
    ORTHODONTICS_LOOSE_THREAD: 'Orthodontie - loose thread',
    PARO: 'Parodontologinen hoito',
    POSTCONTROL: 'Jälkitarkastus',
    PREPARE_CROWN: 'Kruunu',
    PRE_SURGERY_CHECK_UP: 'Infektiofokustutkimus',
    PROSTHETIC_CONSULTATION: 'Proteettinen konsultaatio',
    RECALL: 'Recall',
    REMOVAL: 'Hampaan poisto',
    RESERVATIONS_SLOT: 'Varauksen aikaikkuna',
    RESTORATION: 'Paikkaus',
    RETURNING_DENTURE: 'Hammasproteesin luovutus',
    ROOM_BREAK: 'siivous',
    ROOT_TREATMENT_CONTINUATION: 'Juurihoidon jatko',
    SENSITIVITY: 'Vihlonta',
    SURGICAL_CONSULTATION: 'Kirurgia - Konsultaatio',
    SURGICAL_OPERATION: 'Kirurgia - Leikkauskäynti',
    SURGICAL_SUTURE_REMOVAL: 'Ompeleiden poisto',
    TEETH_CLEANING: 'Soodapuhdistus',
    UNKNOWN: 'Tuntematon',
    WHITENING: 'Valkaisu',
  },

  sv: {
    // Routes
    'change-reservation': 'andra-bokningen',
    'confirm-change': 'bekrafta-andringen',
    'show-next-3-days': 'Visa de kommande tre dagarna',
    'show-next-week': 'Visa nästa vecka',
    'show-previous-3-days': 'Visa de tre senaste dagarna',
    'show-previous-week': 'Visa föregående vecka',
    'skip-to-main-content': 'Hoppa till huvudinnehållet',
    confirm: 'bekfraftelse',
    confirmed: 'bekraftelse',
    logout: 'logout',
    payment: 'betalning',
    preinformation: 'forhandsuppgifter',
    reservation: 'tidsbokning',
    // General
    'a service ticket': 'servicesedel',
    'Add to calendar': 'Lägg i kalendern',
    'Advance reservation times': 'Tider för förhandsbokning',
    'All available times': 'Alla lediga tider',
    'Are you sure you wish to cancel the reservation?':
      'Är du säker på att du vill avboka din tid?',
    'Available times': 'lediga tider',
    'Change reservation': 'Ändra på bokningen',
    'Choose new time': 'Välj en ny tid',
    'Choose payment method': 'Välj betalningssätt',
    'Confirm change of reservation': 'Bekräfta bokningsändringen',
    'Confirm change': 'Bekräfta ändringen',
    'Confirm Reservation': 'Godkänn bokningen',
    'Contract ID': 'Kampanjkod',
    'Date of birth': 'Födelsedag',
    'DD-MM-YYYY': 'DD-MM-ÅÅÅÅ',
    'Dentist reservation': 'Tandläkarbokning',
    'Fill in information': 'Förhandsblankett',
    'Fill in your medical information and make your visit smoother.':
      'Gör ditt besök smidigare och fyll i blanketten för förhandsuppgifter samt ange orsak till behov av behandling.',
    'Form contains invalid values': 'Formuläret innehåller ogiltiga värden',
    'I have read and agree to': 'Jag har läst och godkänner',
    'Implant care times': 'Tider för tandimplantat',
    'implant care': 'tandimplantat',
    'in advance': 'förhandsbokning -10%',
    'Information about the visit': 'Bekanta dej med instruktionerna',
    'Insert contract ID': 'Fyll i ditt kampanjkod',
    'Invalid email address': 'Ogiltig e-postadress',
    'Invalid phone number': 'Ogiltig telefonnummer',
    'Invalid ssn': 'Ogiltig personnummer',
    'Limited availability of treatments':
      'vissa besöks-orsaker är ej tillgängliga',
    'Making a reservation': 'Information om tidsbokningen',
    'More information': 'Mer information',
    'My health information has not changed':
      'Min hälsoinformation har inte ändrats.',
    'No advance pricing slots available':
      'Inga tider för förhandsbokning för denna tidpunkt.',
    'No available times': 'inga lediga tider',
    'No times available': 'Inga tider tillgängliga',
    'Pay reservation fee': 'Betala bokningsavgiften',
    'Phone number': 'Mobil',
    'Please check that you have filled in correct information.':
      'Var god och kontrollera att du har fyllt i rätt information (felkod 2).',
    'please go back and reselect a slot': 'gå tillbaka och välj pånytt en tid',
    'Please note': 'OBS!',
    'Preliminary Information': 'Grundinformation',
    'Proceed to update your information':
      'Fortsätt till uppdatering av dina uppgifter.',
    'Proceed with payment': 'Fortsätt till betalningen',
    'Reservation cannot be confirmed. Please contact the customer service.':
      'Bokningen går inte att verifiera (felkod 1). Var god och ta kontakt i kundservicen.',
    'Reservation Fee': 'Bokningsavgift',
    'Reserve a time by phone': 'Boka tid per telefon',
    'Save information': 'Spara uppgifterna',
    'Select Timeslot': 'Välj tid',
    'Service ticket times': 'Tider för servicesedlar',
    'Service voucher number': 'Servicesedel nummer',
    'Show all available times': 'Visa alla lediga tider',
    'Social security number': 'Personnummer (t.ex. 19901231-1234)',
    'Something went wrong. Please contact the customer service.':
      'Något gick fel. Var god och ta kontakt i kundservicen.',
    'Something went wrong': 'Något gick snett',
    'Special Services': 'Specialtjänster',
    'Temporary reservation expired. Please start over.':
      'Tillfällig bokning har gått ut. Var god och börja om från början (felkod 3).',
    'The clinic is closed': 'Kliniken är stängd',
    'The new time slot you selected has higher reservation fee than the original one. The difference will be paid at the clinic.':
      'Tidsfönstret du valde har högre bokningsavgift än den urspungliga. Skillnaden betalas på mottagningen.',
    'the terms': 'villkor',
    'Time Recommendations': 'Rekommenderade tider',
    'Too many reservation reasons':
      'För många bokningsskäl (det maximala antalet är',
    'Too short first name': 'Förnamnet är för kort',
    'Too short last name': 'Efternamnet är för kort',
    'Verify reservation': 'Bekräfta bokningen',
    'You are making a change to your reservation. Your new time slot will be:':
      'Du ändrar din bokning.  Ditt nya tidsfönster är:',
    'You need to select a reservation reason': 'Välj en bokningsskäl',
    'Your reservation is confirmed': 'Din bokning har bekräftats',
    'YYYY-MM-DD': 'ÅÅÅÅ-MM-DD',
    "I'm coming for": 'Jag kommer för',
    "I'm reserving with": 'Jag bokar med',
    "I'm reserving": 'Jag gör',
    "This reservation can't be moved anymore.":
      'Denna reservering kan inte ändras mera.',
    Cancel: 'Avbryt',
    Change: 'Ändra',
    Checkin: '',
    Close: 'Stäng',
    Confirmation: 'Bekräftelse',
    Date: 'Datum',
    Details: 'Details',
    Email: 'E-post',
    Firstname: 'Förnamn',
    go_to_clinic_page: 'Gå till vår hemsida',
    Klo: 'Kl',
    Lastname: 'Efternamn',
    No: 'Nej',
    noRootCanalTreatments:
      'Den sista tiden på dagen kanske vi inte hinner utföra långa behandlingar tex rotbehandlingar tandblekning eller omfattande tandstensborttagning.',
    noRootCanalTreatmentsLineOne:
      'Den sista tiden på dagen kanske vi inte hinner utföra långa behandlingar ',
    noRootCanalTreatmentsLineTwo:
      'tex rotbehandlingar tandblekning eller omfattande tandstensborttagning.',
    Price: 'Pris',
    Reasons: 'Orsak till behov av behandling',
    Reservation: 'Tidsbokning',
    Timeframe: 'Tidsfönster',
    Wk: 'v.', // Week
    Yes: 'Ja',
    // Authentication
    authenticating: 'Autentiserar...',
    logged_out: 'Du är nu utloggad och kan stänga detta fönster.',
    logging_out: 'Loggar ut...',
    logout_error: 'Utloggningen misslyckades. Vänligen försök igen.',
    Logout: 'Logga ut',
    redirecting_to_idp: 'Dirigerar om till autentiseringstjänsten...',
    user_mismatch:
      'Det måste vara samma person som för den ursprungliga bokningen.',
    // Checkin
    'You have a reservation': 'Du har en bokning',
    'Checkin info': 'Vänligen checka in först när du kommer till kliniken.',
    'Checkin button text': 'Checka in',
    'General checkin error':
      'Ett fel inträffade, vänligen försök igen. Kontakta receptionen om felet kvarstår.',
    'Checkin error':
      'Ett fel inträffade, vänligen försök igen. Kontakta receptionen om felet kvarstår.',
    'Checkin successful': 'Du är nu incheckad',
    'Checkin successful info':
      'Vänligen slå dig ner i väntrummet tills du blir uppropad.',
    'Unknown reservation code': 'Bokningen hittades inte',
    'Unknown reservation code info': 'Vänligen anmäl dig i receptionen.',
    'Checkin done': 'Du är nu incheckad',
    'Missing anamnesis info':
      'Vänligen fyll i dina anamnesuppgifter innan du checkar in',
    'Fill out preinformation': 'Fyll i anamnesuppgifter',
    // Reservation reasons
    ACHE: 'Värk tandläkare',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_10_WITH_PROBLEM:
      'Årskontroll och rengöring +10 år med problem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_10_WITHOUT_PROBLEM:
      'Årskontroll och rengöring +10 år utan problem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15_WITH_PROBLEM:
      'Årskontroll och rengöring +15 år med problem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15_WITHOUT_PROBLEM:
      'Årskontroll och rengöring +15 år utan problem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15: 'Årskontroll och rengöring +15 år',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_10_WITH_PROBLEM:
      'Årskontroll och rengöring -10 år med problem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_10_WITHOUT_PROBLEM:
      'Årskontroll och rengöring -10 år utan problem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15_WITH_PROBLEM:
      'Årskontroll och rengöring -15 år med problem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15_WITHOUT_PROBLEM:
      'Årskontroll och rengöring -15 år utan problem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15: 'Årskontroll och rengöring -15 år',
    BARN_K0: 'Undersökning, barn (grupp 0)',
    BARN_K1: 'Undersökning, barn (grupp 1)',
    BARN_K2: 'Undersökning, barn (grupp 2)',
    BROKEN_TOOTH_OR_FILLING: 'Sprickad tand eller fyllning',
    CALCULUS: 'Tandstensborttagning tandhygienist',
    CBCT: 'CBCT',
    CERAMIC_CONSULTATION: 'Keramik - Konsultationsbesök',
    CERAMIC_PROSTETIC_ATTACHMENT: 'Keramik - Prostetisk fästningsarbete',
    CERAMIC_REPLICA: 'Keramik - Avtrycksbesök',
    CHECK_UP_AND_ASSESSMENT: 'Kontroll och bedömning av vårdbehov',
    CHECK_UP: 'Undersökning',
    CLEANING_WITH_AIRFLOW_DPSI_1_2: 'Sodarengöring (DPSI 1 eller 2)',
    CLEANING_WITH_AIRFLOW_DPSI_3: 'Sodarengöring (DPSI 3- eller 3+)',
    CLEANING_WITH_AIRFLOW_DPSI_4: 'Sodarengöring (DPSI 4)',
    CLEANING_WITH_AIRFLOW: 'Sodarengöring',
    CONTINUATION_OF_TREATMENT: 'Fortsättning av behandlingen',
    CONTROL: 'Kontroll',
    CRACK: 'Fraktur/trasig tand tandläkare',
    CROWN_AND_BRIDGE_PLACEMENT: 'Tandkrona och tandbro placering',
    CROWN_AND_BRIDGE_PREP: 'Tandkrona och tandbro beredning',
    DENTAL_NURSE: 'Tandsköterska',
    DIGITAL_SMILE_DESIGN_FACINGS_OR_CROWNS: 'DSD Facings/Crowns',
    DSD_CONSULTATION: 'Digital Smile Design första consultering',
    DSD_FACINGS_OR_CROWNS: 'DSD Facings/Crowns',
    DSD_INTAKE: 'Digital Smile designintag',
    DSD_PHOTOSHOOT: 'DSD makeup / fotografering',
    DSD_TEST_DRIVE: 'Digital Smile Design provkör dit leende',
    FACIAL_ANALYSIS_DETERMINATION: 'Ansiktsanalys',
    FILLING_DECAY_1_OLDER_10: '1 fyllning +10 år',
    FILLING_DECAY_1_YOUNGER_10: '1 fyllning -10 år',
    FILLING_DECAY_1: '1 fyllning',
    FILLING_DECAY_2_OLDER_10: '2 fyllningar +10 år',
    FILLING_DECAY_2_YOUNGER_10: '2 fyllningar -10 år',
    FILLING_DECAY_2: '2 fyllningar',
    FILLING_DECAY_3_OLDER_10: '3 eller fler fyllningar +10 år',
    FILLING_DECAY_3_YOUNGER_10: '3 eller fler fyllningar -10 år',
    FILLING_DECAY_3: '3 eller fler fyllningar',
    FILLING_DECAY: 'Fyllning',
    GRINDING_OR_CLENCHING: 'Gnissla eller pressa tänder',
    HOMEWHITENING: 'Hemmablekning',
    IMPLANT_CHECKUP: 'Implantat - Kontrollbesök',
    IMPLANT_CONSULTATION: 'Implantat - Konsultation',
    IMPLANT_HOME_CARE_CONTROL_VISIT: 'Implantat - Hemvårdens kontrollbesök',
    IMPLANT_PLACEMENT: 'Implantplacering',
    IMPLANT_PROSTETIC_ATTACHMENT:
      'Implantat - Prostetiska arbetets fästningsbesök',
    IMPLANT_PROSTETIC_CONTROL_VISIT: 'Implantat - Kontrollbesök',
    IMPLANT_PROSTETIC_IMPRESSION: 'Implantat - Prostetisk avtryckbesök',
    IMPLANT_SURGICAL: 'Implantat - Kirurgisk besök',
    INMAN_APPLICATION: 'Inman Aligner inställningsbesök',
    INMAN_CHECKUP: 'Inman Aligner kontrollbesök',
    INMAN_CONSULTATION: 'Inman Aligner konsultationsbesök',
    INMAN_FINAL: 'Inman Aligner slutbesök',
    INTERPRETER: 'Tolk',
    INVISALIGN_CHECK_UP: 'Invisalign undersökning',
    OCCLUSAL_SPLINT: 'Bettskena',
    OPG: 'OPG',
    OREBRO_CHECKUP_DENTIST: 'Undersökning hos tandläkare',
    OREBRO_CHECKUP_HYGIENIST: 'Undersökning hos tandhygienist',
    OREBRO_CHECKUP_PERIODONTAL: 'Parodkontroll',
    OREBRO_PATIENT_LIFT_NEEDED: 'Behov av lyft',
    OREBRO_PREVENTATIVE_CARE_HYGIENIST:
      'Sjukdomsförebyggande vård hos tandhygienist',
    OREBRO_PROPHYLAXIS: 'Profylax',
    OTHER: 'Övrigt tandläkare',
    PARO: 'Parodontal behandling',
    POSTCONTROL: 'Efterkontroll',
    PREPARE_CROWN: 'Prep krona',
    PRE_SURGERY_CHECK_UP: 'Preoperativ kontroll',
    PROSTHETIC_CONSULTATION: 'Proteskonsultation',
    RECALL: 'Recall',
    REMOVAL: 'Tandborttagning tandläkare',
    RESERVATIONS_SLOT: 'Reservationens slot',
    RESTORATION_FRONTAL: 'Lagning framtand',
    RESTORATION: 'Lagning tandläkare',
    RETURNING_DENTURE: 'Ger tillbaka proteser',
    RVN_HYGIENIST_TREATMENT: 'Tandhygienistbehandling',
    RVN_CHECK_UP: 'Undersökning',
    RVN_CHECK_UP_PERIODONTAL: 'Parodontal stödbehandling',
    RVN_OTHER: 'Övrigt tandläkare',
    RVN_PREPARE_CROWN: 'Protetik, preparation',
    RVN_PROSTHETIC: 'Protetik, avtagbar',
    RVN_REMOVAL: 'Extraktion',
    RVN_RESTORATION: 'Lagning',
    RVN_RETRIEVAL: 'Protetik, utlämning',
    RVN_ROOT_TREATMENT: 'Rotbehandling',
    RVN_SPLINT_MODELING: 'Skanning',
    RVN_TEMPEROMANIBULAR_DISORDER: 'Bettfysutredning',
    RVN_TRAUMA_ACHE: 'Akut värk',
    RVN_TRAUMA_CRACK: 'Akut fraktur',
    RVN_TRAUMA: 'Akut trauma',
    RVN_TRAUMA_OTHER: 'Akut övrigt',
    RVN_WISDOM_TEETH: 'Akut visdomstand',
    ROOM_BREAK: 'städning',
    ROOT_TREATMENT_CONTINUATION: 'Fortsatt rotbehandling',
    SENSITIVITY: 'Ilning',
    SPLINT_MODELING: 'Avtryck bettskena/idrottsskena',
    SURGICAL_CONSULTATION: 'Kirurgi - Konsultation',
    SURGICAL_OPERATION: 'Kirurgi - Operationsbesök',
    SURGICAL_SUTURE_REMOVAL: 'Suturborttagning',
    TEMPOROMANDIBULAR_DISORDER: 'Käkledsbesvär tandläkare',
    TRAUMA: 'Olycksfall tandläkare',
    UNKNOWN: 'Okänd',
    WHITENING: 'Blekning',
    WISDOM_TEETH: 'Besvär visdomstand tandläkare',
  },

  en: {
    // Routes
    'change-reservation': 'change-reservation',
    'confirm-change': 'confirm-change',
    'show-next-3-days': 'Show next three days',
    'show-next-week': 'Show next week',
    'show-previous-3-days': 'Show previous three days',
    'show-previous-week': 'Show previous week',
    'skip-to-main-content': 'Skip to main content',
    confirm: 'confirm',
    confirmed: 'confirmed',
    logout: 'logout',
    payment: 'payment',
    preinformation: 'preinformation',
    reservation: 'reservation',
    // General
    'a service ticket': 'a service ticket',
    'Add to calendar': 'Add to calendar',
    'Advance reservation times': 'Advance booking times',
    'All available times': 'All available times',
    'Are you sure you wish to cancel the reservation?':
      'Are you sure you want to cancel the booking?',
    'Available times': 'available times',
    'Change reservation': 'Change booking',
    'Choose new time': 'Choose a new time',
    'Choose payment method': 'Choose payment method',
    'Confirm change of reservation': 'Confirm a new time',
    'Confirm change': 'Confirm the change',
    'Confirm Reservation': 'Confirm Booking',
    'Contract ID': 'Campaign code',
    'Date of birth': 'Date of birth',
    'DD-MM-YYYY': 'DD-MM-YYYY',
    'Dentist reservation': 'Dental appointment',
    'Fill in information': 'Fill in medical history form',
    'Fill in your medical information and make your visit smoother.':
      'Make your visit smoother by filling in your medical history form and reasons for visit.',
    'Form contains invalid values': 'Form contains invalid values',
    'I have read and agree to': 'I have read and agree to',
    'Implant care times': 'Implant treatment times',
    'implant care': 'implant care',
    'in advance': 'in advance -10%',
    'In order to proceed to the online time reservation you need to login by providing your personal details.':
      'In order to proceed to the online time reservation you need to login by providing your personal details.',
    'Information about the visit': 'Information about the appointment',
    'Insert contract ID': 'Insert campaign code',
    'Invalid email address': 'Invalid email address',
    'Invalid phone number': 'Invalid phone number',
    'Invalid ssn': 'Invalid social security number',
    'Limited availability of treatments': 'Limited availability of treatments',
    'Making a reservation': 'Tips for booking an appointment',
    'More information': 'More information',
    'My health information has not changed': 'My information has not changed',
    'No advance pricing slots available': 'No advance pricing slots available',
    'No available times': 'no times available',
    'No times available': 'No online times available',
    'Pay reservation fee': 'Pay booking fee',
    'Phone number': 'Phone number',
    'Please check that you have filled in correct information.':
      'Please check that you have filled in correct information (error code 2).',
    'please go back and reselect a slot':
      'please return to calender and reselect a time slot',
    'Please note': 'Please note!',
    'Preliminary Information': 'Medical History',
    'Proceed to update your information': 'Proceed to update your information',
    'Proceed with payment': 'Proceed with payment',
    'Reservation cannot be confirmed. Please contact the customer service.':
      'Reservation cannot be confirmed (error code 1). Please contact the customer service.',
    'Reservation Fee': 'Booking Fee',
    'Reserve a time by phone': 'Reserve a time by phone',
    'Save information': 'Save information',
    'Select Timeslot': 'Select Time Slot',
    'Service ticket times': 'Service voucher times',
    'Service voucher number': 'Service voucher number',
    'Show all available times': 'Show all available times',
    'Social security number': 'Social security number',
    'Something went wrong. Please contact the customer service.':
      'Something went wrong. Please contact the customer service.',
    'Something went wrong': 'Something went wrong',
    'Special Services': 'Special Services',
    'Temporary reservation expired. Please start over.':
      'Temporary reservation expired. Please start over (error code 3).',
    'The clinic is closed': 'Our clinic is closed',
    'The new time slot you selected has higher reservation fee than the original one. The difference will be paid at the clinic.':
      'The new time slot you selected has higher booking fee than the original one. The difference will be paid at the clinic.',
    'the terms': 'the terms',
    'Time Recommendations': 'Time Recommendations',
    'Too many reservation reasons':
      'Too many reservation reasons (maximum amount is',
    'Too short first name': 'Too short first name',
    'Too short last name': 'Too short last name',
    'Verify reservation': 'Confirm Booking',
    'You are making a change to your reservation. Your new time slot will be:':
      'You are making a change to your booking. Your new time slot will be:',
    'You need to select a reservation reason':
      'You need to pick a reservation reason',
    'Your reservation is confirmed': 'Your booking is confirmed',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    "I'm coming for": "I'm coming for",
    "I'm reserving with": "I'm reserving with",
    "I'm reserving": "I'm reserving",
    "This reservation can't be moved anymore.":
      "This booking can't be changed anymore.",
    Cancel: 'Cancel',
    Change: 'Change',
    Checkin: 'Self-checkin',
    Close: 'Close',
    Confirmation: 'Confirmation',
    Date: 'Date',
    Details: 'Extra information',
    Email: 'Email',
    Firstname: 'First name',
    go_to_clinic_page: 'Go to our website',
    Klo: 'at', // Reservation time
    Lastname: 'Last name',
    No: 'No',
    noRootCanalTreatments:
      'Please note that we cannot guarantee the availability of longer treatments for the final slot of the day. Longer treatments include root canal, tooth whitening and extensive calculus removal.',
    noRootCanalTreatmentsLineOne:
      'Please note that we cannot guarantee the availability of longer treatments for the final slot of the day.',
    noRootCanalTreatmentsLineTwo:
      'Longer treatments include root canal, tooth whitening and extensive calculus removal.',
    Price: 'Price',
    Reasons: 'Reasons for visit',
    Reservation: 'Reservation',
    Timeframe: 'Time slot',
    Wk: 'Wk', // Week
    Yes: 'Yes',
    // Authentication:
    authenticating: 'Authenticating...',
    logged_out:
      'You have been logged out and you can close this browser window.',
    logging_out: 'Logging out...',
    logout_error: 'Logging out failed. Please try again.',
    Logout: 'Log out',
    redirecting_to_idp: 'Redirecting to authentication service...',
    user_mismatch: 'The authenticated user must match the reservation.',
    // Checkin
    'You have a reservation': 'You have a reservation',
    'Checkin info': 'Please check in only when you are at the clinic premises.',
    'Checkin button text': 'Check in',
    'General checkin error':
      'An error occurred, please try again. If the error persists, please check in at the reception counter.',
    'Checkin error':
      'An error occurred while attempting to check in, please try again. If the error persists, please check in at the reception counter.',
    'Checkin successful': 'Check-in successful',
    'Checkin successful info':
      'Please take a seat. You will be called to the room by name.',
    'Unknown reservation code': 'Reservation not found',
    'Unknown reservation code info':
      'Please check in at the reception counter.',
    'Checkin done': 'You have checked in',
    'Missing anamnesis info':
      'Please fill out the preliminary information form before check-in.',
    'Fill out preinformation': 'Fill out information',
    // Reservation reasons
    'CB_IMPLANTS_IMPRESSION/SCAN': 'CB, implants: impression/scan',
    'CB_NATURAL_TEETH_PREP_AND_SCAN/IMPRESSION':
      'CB, natural teeth: prep and scan/impression',
    'CONE_BEAM_COMPUTED_TOMOGRAPHY_(CBCT)':
      'Cone Beam computed tomography (CBCT)',
    'ORTHODONTICS_PLACEMENT_HEADGEAR/MINISCREWS':
      'Orthodontics: Placement headgear/miniscrews',
    'ROOT_CANAL_FRONT/CANINE/PREMOLAR': 'Root canal Front/Canine/Premolar',
    'URGENT_PULPITIS/TRAUMA': 'Urgent-pulpitis/trauma',
    ACHE: 'Ache',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_10_WITH_PROBLEM:
      'Annual check up and cleaning +10 years old with problem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_10_WITHOUT_PROBLEM:
      'Annual check up and cleaning +10 years old without problem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15_WITH_PROBLEM:
      'Annual check up and cleaning +15 years old with problem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15_WITHOUT_PROBLEM:
      'Annual check up and cleaning +15 years old without problem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15:
      'Annual check up and cleaning +15 years old',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_10_WITH_PROBLEM:
      'Annual check up and cleaning -10 years old with problem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_10_WITHOUT_PROBLEM:
      'Annual check up and cleaning -10 years old without problem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15_WITH_PROBLEM:
      'Annual check up and cleaning -15 years old with problem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15_WITHOUT_PROBLEM:
      'Annual check up and cleaning -15 years old without problem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15:
      'Annual check up and cleaning -15 years old',
    ANNUAL_CHECK_UP: 'Annual check up',
    BARN_K0: 'Check-up, child (group 0)',
    BARN_K1: 'Check-up, child (group 1)',
    BARN_K2: 'Check-up, child (group 2)',
    BITE_PLATE_DENTIST: 'Bite plate (dentist)',
    BITE_PLATE_HYGIENIST: 'Bite plate (hygienist)',
    BROKEN_TOOTH_OR_FILLING: 'Broken tooth or filling',
    BUILD_UP: 'Build up',
    CANINE_EXPOSURE: 'Canine exposure',
    CB_IMPLANTS_BITE_REGISTRATION: 'CB, implants: Bite registration',
    CB_IMPLANTS_PLACEMENT: 'CB, implants: Placement',
    CB_IMPLANTS_WAX_FIT: 'CB, implants : Wax fit',
    CB_NATURAL_TEETH_BITE_REGISTRATION: 'CB, natural teeth: Bite registration',
    CB_NATURAL_TEETH_IMPLANTS_PLACEMENT:
      'CB, natural teeth: implants: Placement',
    CB_NATURAL_TEETH_PREPARATION: 'CB, natural teeth: Preparation',
    CB_NATURAL_TEETH_WAX_FIT: 'CB, natural teeth: Wax fit',
    CBCT_IMPLANTS: 'CBCT implants',
    CBCT: 'CBCT',
    CERAMIC_CONSULTATION: 'Ceramic consultation',
    CERAMIC_PROSTETIC_ATTACHMENT: 'Ceramic prostetic attachment',
    CERAMIC_REPLICA: 'Ceramic replica',
    CHECK_UP_AND_ASSESSMENT: 'Check up and assesment',
    CHECK_UP: 'Control',
    CLEANING_COMPREHENSIVE: 'Dental cleaning',
    CLEANING_WITH_AIRFLOW_DPSI_1_2: 'Cleaning with airflow (DPSI 1 or 2)',
    CLEANING_WITH_AIRFLOW_DPSI_3: 'Cleaning with airflow (DPSI 3- or 3+)',
    CLEANING_WITH_AIRFLOW_DPSI_4: 'Cleaning with airflow (DPSI 4)',
    CLEANING_WITH_AIRFLOW: 'Cleaning with airflow',
    CLEANING: 'Cleaning',
    CLEAR_OVERLAY_RETRIEVAL: 'CO retrieval',
    CLINICAL_CROWN_LENGTHENING: 'Clinical crown lengthening',
    COLLECTING_DATA: 'Collecting data',
    CONTINUATION_OF_TREATMENT: 'Continuation of treatment',
    CONTINUED_FILLING: 'Continued restoration',
    CRACK: 'Crack',
    CROWN_AND_BRIDGE_PLACEMENT: 'Crown and bridge placement',
    CROWN_AND_BRIDGE_PREP: 'Crown and bridge preparation',
    DENTAL_NURSE: 'Dental nurse',
    DENTIST_CHECKUP: 'Dentist control',
    DIAGNOSTIC_WAX_UP: 'Diagnostic wax-up',
    DIGITAL_SMILE_DESIGN_FACINGS_OR_CROWNS: 'DSD Facings/Crown',
    DISTRIBUTION_AND_INSTRUCTIONS_FOR_WHITENING_TRAYS:
      'Distribution and instructions for whitening trays',
    DOCUMENT_RETRIEVAL: 'Document retrieval',
    DSD_CONSULTATION: 'Digital Smile Design 1st',
    DSD_FACINGS_OR_CROWNS: 'DSD Facings/Crowns',
    DSD_INTAKE: 'Digital Smile Design Intake',
    DSD_PHOTOSHOOT: 'DSD makeup / photo shoot',
    DSD_TEST_DRIVE: 'Digital Smile Design test-drive your smile',
    EMERGENCY_ABSCESS: 'Emergency: Abscess',
    EMERGENCY_BROKEN_TEETH_OR_FILLING: 'Emergency: Broken teeth or filling',
    EMERGENCY_DENTURE_PROBLEM: 'Emergency: Denture problem',
    EMERGENCY_IMPLANT_PROBLEM: 'Emergency: Implant problem',
    EMERGENCY_LOOSE_CROWN: 'Emergency: Loose crown',
    EMERGENCY_OTHER: 'Emergency: Other',
    EMERGENCY_PAIN: 'Emergency: Pain',
    EMERGENCY_TEETH_PROBLEM: 'Emergency: Teeth problem',
    EMERGENCY_TRAUMA: 'Emergency: Trauma',
    ENDODONTIC_ADVICE: 'Endodontic advice',
    EQUIPMENT_MICROSCOPE: 'Microscope',
    ESTHETIC_FILLING: 'Esthetic Filling',
    ESTHETIC: 'Esthetic',
    EXTRACTION_AND_LPRF_OR_BONE_GRAFT: 'Extraction and LPRF or bone graft',
    EXTRACTION: 'Extraction',
    FACIAL_ANALYSIS_DETERMINATION: 'Facial analysis determination',
    FILLING_DECAY_1_OLDER_10: '1 filling +10 years old',
    FILLING_DECAY_1_YOUNGER_10: '1 filling -10 years old',
    FILLING_DECAY_1: '1 filling',
    FILLING_DECAY_2_OLDER_10: '2 fillings +10 years old',
    FILLING_DECAY_2_YOUNGER_10: '2 fillings -10 years old',
    FILLING_DECAY_2: '2 fillings',
    FILLING_DECAY_3_OLDER_10: '3 or more fillings +10 years old',
    FILLING_DECAY_3_YOUNGER_10: '3 or more fillings -10 years old',
    FILLING_DECAY_3: '3 or more fillings',
    FILLING_DECAY: 'Filling',
    FOLLOW_UP_INTERNAL_BLEACHING: 'Follow-Up internal bleaching',
    FORTUNA_ANNUAL_CHECK_UP_AND_CLEANING: 'Annual check up and cleaning',
    FORTUNA_CROWN_OR_BRIDGEWORK: 'Crown/bridgework',
    FORTUNA_EMERGENCY_BROKEN_TEETH_OR_FILLING:
      'Emergency: Broken teeth or filling',
    FORTUNA_EMERGENCY_PAIN: 'Emergency: Pain',
    FORTUNA_ENDODONTIC_TREATMENT_LONG: 'Endodontic treatment 90',
    FORTUNA_ENDODONTIC_TREATMENT_SHORT: 'Endondontic treatment 60',
    FORTUNA_ESTHETIC_FILLING: 'Esthetic Filling 50',
    FORTUNA_FILLING_DECAY: 'Filling - decay',
    FORTUNA_NEW_PATIENT: 'New patient',
    FORTUNA_OTHER: 'Other',
    FORTUNA_PERIODONTITIS_CLEANING: 'Periodontitis: Cleaning',
    FORTUNA_PERIODONTITIS_NEW_PATIENT: 'Periodontitis: New patient',
    FORTUNA_PERIODONTITIS_TREATMENT: 'Periodontitis: Treatment',
    FORTUNA_PROSTHESIS_LONG: 'Prosthesis long',
    FORTUNA_PROSTHESIS_SHORT: 'Prosthesis short',
    FORTUNA_TREATMENT_PLAN_DISCUSSION: 'Treatment plan discussion',
    GENERAL_CONSULTATION: 'General consultation',
    GENERAL_PARO_CONSULTATION: 'General paro consultation',
    GINGIVAL_GRAFT: 'Gingival graft',
    GINGIVECTOMIE_AND_ROOTPLANNING: 'Gingivectomie and rootplanning',
    GRINDING_OR_CLENCHING: 'Teeth grinding or clenching',
    GUM_TREATMENT_AFTERCARE: 'Gum treatment - aftercare',
    GUM_TREATMENT_INITIALLY: 'Gum treatment - initial',
    GUM_TREATMENT_INTAKE: 'Gum treatment - intake',
    HOMEWHITENING: 'Homewhitening',
    HYGIENIST_CHECKUP: 'Hygienist control',
    IMPLANT_CHECKUP: 'Implant - Control',
    IMPLANT_CONSULTATION: 'Implant - Consultation',
    IMPLANT_HOME_CARE_CONTROL_VISIT: 'Implant - Home care control',
    IMPLANT_PLACEMENT: 'Implant placement',
    IMPLANT_PROSTETIC_ATTACHMENT: 'Implant - Prosthetic attachment',
    IMPLANT_PROSTETIC_CONTROL_VISIT:
      'Implant - Prosthodontic follow-up appointment',
    IMPLANT_PROSTETIC_IMPRESSION: 'Implant - Prosthetic impression',
    IMPLANT_SURGICAL: 'Implant - Surgery',
    IMPRESSION_FOR_CUSTOMISED_WHITENING_TRAYS:
      'Impression for customised whitening trays',
    INMAN_APPLICATION: 'Inman Aligner - Application',
    INMAN_CHECKUP: 'Inman Aligner - Control',
    INMAN_CONSULTATION: 'Inman Aligner - Consultation',
    INMAN_FINAL: 'Inman Aligner - Final',
    INTERPRETER: 'Interpreter',
    INVISALIGN_CHECK_UP: 'Invisalign check up',
    NEW_PATIENT: 'New patient',
    OCCLUSAL_SPLINT: 'Occlusal splint',
    OPG: 'OPG',
    OREBRO_CHECKUP_DENTIST: 'Dentist Checkup',
    OREBRO_CHECKUP_HYGIENIST: 'Hygienist Checkup',
    OREBRO_CHECKUP_PERIODONTAL: 'Periodontal Checkup',
    OREBRO_OREBRO_PATIENT_LIFT_NEEDED: 'Need for a patient lift',
    OREBRO_PREVENTATIVE_CARE_HYGIENIST: 'Preventative care with Hygienist',
    OREBRO_PROPHYLAXIS: 'Prophylaxis',
    ORTHO_LOOSE_BRACKET_DENTIST: 'Orthodontie - Loose bracket (dentist)',
    ORTHODONTICS_APPLICATION: 'Orthodontie - Application',
    ORTHODONTICS_ASSESSMENT: 'Orthodontie - Assessment',
    ORTHODONTICS_BITE_REGISTRATION: 'Orthodontics: Bite registration',
    ORTHODONTICS_CHECK_INVISALIGN: 'Orthodontics: Check invisalign',
    ORTHODONTICS_CHECK_SPLINT: 'Orthodontics: Check Splint',
    ORTHODONTICS_CHECKUP: 'Orthodontie - Control',
    ORTHODONTICS_COLLECTING_DATA: 'Orthodontics: Collecting data',
    ORTHODONTICS_CONSULTATION: 'Orthodontie - Consultation',
    ORTHODONTICS_DOCUMENTATION: 'Orthodontie - Documentation',
    ORTHODONTICS_FINAL: 'Orthodontie - Final',
    ORTHODONTICS_FIRST_VISIT: 'Orthodontics: first visit',
    ORTHODONTICS_GENERAL_CHECK_AND_ADVICE:
      'Orthodontics: general check and advice',
    ORTHODONTICS_GENERAL_CHECK: 'Orthodontics: general check',
    ORTHODONTICS_LOOSE_BRACKET: 'Orthodontie - Loose bracket',
    ORTHODONTICS_LOOSE_THREAD: 'Orthodontie - Loose thread',
    ORTHODONTICS_PLACEMENT_FIXED_APPLIANCE:
      'Orthodontics: Placement fixed appliance',
    ORTHODONTICS_PLACEMENT_REMOVABLE_APPLIANCE:
      'Orthodontics: Placement removable appliance',
    ORTHODONTICS_PLACEMENT_SPLINT: 'Orthodontics: Placement Splint',
    ORTHODONTICS_REMOVE_BRACKETS_IMPRESSION_FULL_MOUTH:
      'Orthodontics: remove brackets, impression full mouth',
    ORTHODONTICS_REMOVE_BRACKETS_RETENTION_AND_IMPRESSION:
      'Orthodontics: remove brackets, retention and impression',
    ORTHODONTICS_REMOVE_BRACKETS_RETENTION_FULL_MOUTH:
      'Orthodontics: remove brackets, retention full mouth',
    ORTHODONTICS_REMOVE_HERBST: 'Orthodontics: remove herbst',
    ORTHODONTICS_REMOVE_HYRAX: 'Orthodontics: remove hyrax',
    ORTHODONTICS_SEPARATION: 'Orthodontics: separation',
    OTHER: 'Other',
    PARO: 'Parodontal treatment',
    PERIODONTAL_CHECK: 'Periodontal check',
    PLACEMENT_INTERRADICULAR_SCREW: 'Placement interradicular screw',
    POSTCONTROL: 'Postcontrol',
    PREPARE_CROWN: 'Prepare crown',
    PRE_SURGERY_CHECK_UP: 'Infection focus checkup',
    PROSTHETIC_CONSULTATION: 'Prosthetic consultation',
    RECALL: 'Recall',
    RELEASE_IMPLANTS: 'Release implants',
    REMOVABLE_DENTURE_BITE_REGISTRATION: 'Removable denture: Bite registration',
    REMOVABLE_DENTURE_CHECK_UP: 'Removable denture: Check up',
    REMOVABLE_DENTURE_DEFINITIVE_IMPRESSION:
      'Removable denture: Definitive impression',
    REMOVABLE_DENTURE_FIRST_IMPRESSION: 'Removable denture: First impression',
    REMOVABLE_DENTURE_PLACEMENT: 'Removable denture: Placement',
    REMOVABLE_DENTURE_WAX_FIT: 'Removable denture: Wax Fit',
    REMOVAL: 'Removal',
    RESERVATIONS_SLOT: "Reservation's slot",
    RESTORATION: 'Restoration',
    RETURNING_DENTURE: 'Returning denture',
    ROOM_BREAK: 'cleaning',
    ROOT_CANAL_MOLAR: 'Root canal molar',
    ROOT_TREATMENT_CONTINUATION: 'Root treatment continuation',
    ROOT_TREATMENT: 'Root treatment',
    RVN_HYGIENIST_TREATMENT: 'Hygienist treatment',
    RVN_CHECK_UP: 'Check up',
    RVN_CHECK_UP_PERIODONTAL: 'Check up periodontal',
    RVN_OTHER: 'Other',
    RVN_PREPARE_CROWN: 'Prepare crown',
    RVN_PROSTHETIC: 'Prosthetic',
    RVN_REMOVAL: 'Removal',
    RVN_RESTORATION: 'Restoration',
    RVN_RETRIEVAL: 'Retrieval',
    RVN_ROOT_TREATMENT: 'Root treatment',
    RVN_SPLINT_MODELING: 'Splint modeling',
    RVN_TEMPEROMANIBULAR_DISORDER: 'Temperomanibular disorder',
    RVN_TRAUMA_ACHE: 'Trauma ache',
    RVN_TRAUMA_CRACK: 'Trauma crack',
    RVN_TRAUMA: 'Trauma',
    RVN_TRAUMA_OTHER: 'Trauma other',
    RVN_WISDOM_TEETH: 'Wisdom teeth',
    SCALING_AND_ROOTPLANNING: 'Scaling and rootplanning',
    SENSITIVITY: 'Sensitivity',
    SINUS_LIFT: 'Sinus lift',
    SNORING_PROBLEMS: 'Snoring problems',
    SURGICAL_CONSULTATION: 'Surgery consultation',
    SURGICAL_EXTRACTION: 'Surgical extraction',
    SURGICAL_FLAP: 'Surgical flap',
    SURGICAL_OPERATION: 'Surgery - operation',
    SURGICAL_SUTURE_REMOVAL: 'Stitch removal',
    TEETH_CLEANING: 'Teeth cleaning',
    TEMPOROMANDIBULAR_DISORDER: 'Temporomandibular disorder',
    TRAUMA: 'Tooth damage / accident',
    TREATMENT_PLAN_DISCUSSION: 'Treatment plan discussion',
    TTN_AIRSMILE: 'Airsmile',
    TTN_ALLIGNERS: 'Aligners',
    TTN_ANNUAL_CHECK_UP_AND_CLEANING: 'Annual check up and cleaning',
    TTN_CROWN_OR_BRIDGEWORK: 'Crown/bridgework',
    TTN_EMERGENCY_BROKEN_TEETH_OR_FILLING: 'Emergency: Broken teeth or filling',
    TTN_EMERGENCY_PAIN: 'Emergency: Pain',
    TTN_ENDODONTIC_TREATMENT_LONG: 'Endodontic treatment long',
    TTN_ENDONDONTIC_TREATMENT_SHORT: 'Endondontic treatment',
    TTN_ESTHETIC_FILLING: 'Esthetic Filling',
    TTN_EXTRACTION: 'Extraction',
    TTN_FILLING_DECAY_PRIMARY: 'Restoration: Primary caries',
    TTN_FILLING_DECAY_SMALL: 'Filling - decay small',
    TTN_FILLING_DECAY: 'Filling - decay',
    TTN_NEW_PATIENT: 'New patient',
    TTN_PERIODONTITIS_CLEANING: 'Periodontitis: Cleaning',
    TTN_PERIODONTITIS_NEW_PATIENT: 'Periodontitis: New patient',
    TTN_PERIODONTITIS_TREATMENT: 'Periodontitis: Treatment',
    TTN_PREVENTIVE_CARE: 'Preventive care',
    TTN_PROSTHESIS_LONG: 'Prosthesis',
    TTN_PROSTHESIS_SHORT: 'Prosthesis short',
    TTN_SHORT_CONSULTATION_ASSIST: 'Short consultation (hygienist/assistant)',
    TTN_SHORT_CONSULTATION_DENTIST: 'Short consultation (dentist)',
    TTN_TREATMENT_PLAN_DISCUSSION: 'Treatment plan discussion',
    UNKNOWN: 'Unknown',
    WHITENING: 'Whitening',
  },

  nl: {
    // Routes
    'change-reservation': 'veranderene-reservering',
    'confirm-change': 'bevestigen-verandering',
    'show-next-3-days': 'Toon volgende drie dagen',
    'show-next-week': 'Volgende week tonen',
    'show-previous-3-days': 'Toon vorige drie dagen',
    'show-previous-week': 'Vorige week weergeven',
    'skip-to-main-content': 'Ga naar hoofdinhoud',
    confirm: 'bevestigen',
    confirmed: 'bevestigd',
    logout: 'logout',
    payment: 'betaling',
    preinformation: 'voorinformatie',
    reservation: 'reservering',
    // General
    'a service ticket': 'een dienstencheque',
    'Add another patient..': 'Voeg nog een patiënt toe',
    'Add to calendar': 'Toevoegen aan agenda',
    'Advance reservation times': 'Vooruit boeking tijden',
    'All available times': 'Alle beschikbare tijden',
    'Are you sure you wish to cancel the reservation?':
      'Weet u zeker dat u de reservering wilt annuleren?',
    'Available times': 'beschikbare tijden',
    'Change reservation': 'Boeking wijzigen',
    'Choose new time': 'Kies een nieuwe tijd',
    'Choose payment method': 'Kies betalingsmethode',
    'Confirm change of reservation': 'Bevestig een nieuwe tijd',
    'Confirm change': 'Bevestig de wijziging',
    'Confirm Reservation': 'Bevestig boeking',
    'Contract ID': 'Campagnecode',
    'Date of birth': 'Geboortedatum',
    'DD-MM-YYYY': 'DD-MM-JJJJ',
    'Dentist reservation': 'Tandarts afspraak',
    'Fill in information': 'Vul informatie in',
    'Fill in your medical information and make your visit smoother.':
      'Vul uw medische gegevens in en maak uw bezoek soepeler.',
    'Form contains invalid values': 'Formulier bevat ongeldige waarden',
    'I have read and agree to': 'Ik heb gelezen en ga akkoord',
    'Implant care times': 'Implantaatzorg tijden',
    'implant care': 'implantaatzorg',
    'in advance': 'alvast -10%',
    'In order to proceed to the online time reservation you need to login by providing your personal details.':
      'Om door te gaan naar de online tijdreservering, moet u inloggen door uw persoonlijke gegevens op te geven.',
    'Information about the visit': 'Informatie over de afspraak',
    'Insert contract ID': 'Campagnecode invoegen',
    'Invalid email address': 'Ongeldige E-mail',
    'Invalid phone number': 'Ongeldige mobiel nummer',
    'Invalid ssn': 'Ongeldige burgerservicenummer',
    'Limited availability of treatments':
      'Beperkte beschikbaarheid van behandelingen',
    'Making a reservation': 'Tips voor het maken van een afspraak',
    'More information': 'Meer informatie',
    'My health information has not changed':
      'Mijn gegevens zijn niet veranderd',
    'No advance pricing slots available': 'Geen slots vooraf beschikbaar',
    'No available times': 'geen tijden beschikbaar',
    'No times available': 'Online afspraak maken niet mogelijk',
    'Pay reservation fee': 'Boekingskosten betalen',
    'Phone number': 'Mobiel',
    'Please check that you have filled in correct information.':
      'Controleer of u de juiste informatie heeft ingevuld (error code 2)',
    'please go back and reselect a slot':
      'ga terug naar de kalender en selecteer een tijdslot',
    'Please note': 'Houd er rekening mee dat!',
    'Preliminary Information': 'Voorlopige informatie',
    'Proceed to update your information':
      'Ga verder met het bijwerken van uw gegevens',
    'Proceed with payment': 'Ga verder met betaling',
    'Reservation cannot be confirmed. Please contact the customer service.':
      'Reservering kan niet worden bevestigd. Neem dan contact op met de klantenservice.',
    'Reservation Fee': 'Boeking vergoeding',
    'Reserve a time by phone': 'Reserveer een tijd telefonisch',
    'Save information': 'Informatie opslaan',
    'Select Timeslot': 'Kiezen tijdvak',
    'Service ticket times': 'Dienstencheque tijden',
    'Service voucher number': 'Servicevoucher nummer',
    'Show all available times': 'Toon alle beschikbare tijden',
    'Social security number': 'Burgerservicenummer',
    'Something went wrong. Please contact the customer service.':
      'Er is iets fout gegaan. Neem dan contact op met de klantenservice.',
    'Something went wrong': 'Er is iets fout gegaan',
    'Special Services': 'Speciaal Diensten',
    'Temporary reservation expired. Please start over.':
      'Tijdelijke reservering is verlopen. Begin opnieuw (error code 3)',
    'The clinic is closed': 'De kliniek is gesloten',
    'The new time slot you selected has higher reservation fee than the original one. The difference will be paid at the clinic.':
      'Het nieuwe geselecteerde tijdslot heeft hogere reserveringskosten dan het oorspronkelijke tijdslot. Het verschil wordt in de kliniek betaald.',
    'the terms': 'aan de voorwaarden',
    'Time Recommendations': 'Bijzon Aanbeveling',
    'Too many reservation reasons':
      'U heeft teveel reserveringsredenen gekozen (het maximum is',
    'Too short first name': 'Te korte voornaam',
    'Too short last name': 'Te korte achternaam',
    'Verify reservation': 'Bevestig boeking',
    'You are making a change to your reservation. Your new time slot will be:':
      'U brengt een wijziging aan in uw reservering. Uw nieuwe tijdslot zal zijn:',
    'You need to select a reservation reason':
      'U moet een reden voor de reservering kiezen',
    'Your reservation is confirmed': 'Uw boeking is bevestigd',
    'YYYY-MM-DD': 'JJJJ-MM-DD',
    "I'm coming for": 'Ik kom voor',
    "I'm reserving with": 'Ik reserveer bij',
    "I'm reserving": 'Ik reserveer',
    "This reservation can't be moved anymore.":
      'Deze reservering kan niet meer worden gewijzigd.',
    Cancel: 'Annuleren',
    Change: 'Veranderen',
    Checkin: '',
    Close: 'Sluiten',
    Confirmation: 'Bevestiging',
    Date: 'Datum',
    Details: 'Opmerkingen',
    Email: 'E-mail',
    Firstname: 'Voornaam',
    Klo: 'at', // Reservation time
    Lastname: 'Achternaam',
    No: 'Nee',
    noRootCanalTreatments:
      'Houd er rekening mee dat we de beschikbaarheid van langere behandelingen voor het laatste slot van de dag niet kunnen garanderen. Langere behandelingen zijn onder meer wortelkanaalbehandeling, het bleken van tanden en uitgebreide verwijdering van tandsteen.',
    noRootCanalTreatmentsLineOne:
      'Houd er rekening mee dat we de beschikbaarheid van langere behandelingen voor het laatste slot van de dag niet kunnen garanderen.',
    noRootCanalTreatmentsLineTwo:
      'Langere behandelingen zijn onder meer wortelkanaalbehandeling, het bleken van tanden en uitgebreide verwijdering van tandsteen.',
    Price: 'Prijs',
    Reasons: 'Redenen',
    Reservation: 'Reservering',
    Timeframe: 'Tijdslot',
    Wk: 'Wk', // Week
    Yes: 'Ja',
    // Authentication
    authenticating: 'Authenticating...',
    go_to_clinic_page: 'Ga naar onze website',
    logged_out:
      'You have been logged out and you can close this browser window.',
    logging_out: 'Logging out...',
    logout_error: 'Logging out failed. Please try again.',
    Logout: 'Uitloggen',
    redirecting_to_idp: 'Redirecting to authentication service...',
    user_mismatch: 'The authenticated user must match the reservation.',
    // Checkin
    'Checkin button text': '',
    'Checkin done info': '',
    'Checkin done': '',
    'Checkin error': '',
    'Checkin info': '',
    'Checkin successful info': '',
    'Checkin successful': '',
    'General checkin error': '',
    'Missing anamnesis info': '',
    'Unknown reservation code info': '',
    'Unknown reservation code': '',
    'You have a reservation': '',
    // Reservation reasons
    'CB_IMPLANTS_IMPRESSION/SCAN': 'K&B Implantaten: afdruk/scan',
    'CB_NATURAL_TEETH_PREP_AND_SCAN/IMPRESSION':
      'K&B eigen tanden: Omslijpen en afdruk/scan',
    'CONE_BEAM_COMPUTED_TOMOGRAPHY_(CBCT)': 'CBCT',
    'ORTHODONTICS_PLACEMENT_HEADGEAR/MINISCREWS':
      'Orthodontie: Plaatsen HG/H/PB/Supraminischroeven',
    'ROOT_CANAL_FRONT/CANINE/PREMOLAR': 'Wortelkanaalbehandeling-front/HT/PM',
    'URGENT_PULPITIS/TRAUMA': 'Urgentie-expulp/trauma',
    ACHE: 'Pijn',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_10_WITH_PROBLEM:
      'Jaarlijks nazicht +10j met probleem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_10_WITHOUT_PROBLEM:
      'Jaarlijks nazicht +10j zonder probleem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15_WITH_PROBLEM:
      'Jaarlijks nazicht +15j met probleem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15_WITHOUT_PROBLEM:
      'Jaarlijks nazicht +15j zonder probleem',
    ANNUAL_CHECK_UP_AND_CLEANING_OLDER_15: 'Jaarlijks nazicht +15 jaar',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_10_WITH_PROBLEM:
      'Jaarlijks nazicht -10j met probleem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_10_WITHOUT_PROBLEM:
      'Jaarlijks nazicht -10j zonder probleem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15_WITH_PROBLEM:
      'Jaarlijks nazicht -15j met probleem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15_WITHOUT_PROBLEM:
      'Jaarlijks nazicht -15j zonder probleem',
    ANNUAL_CHECK_UP_AND_CLEANING_YOUNGER_15: 'Jaarlijks nazicht -15 jaar',
    ANNUAL_CHECK_UP: 'Jaarlijkse controle',
    BARN_K0: 'Controle, kind (groep 0)',
    BARN_K1: 'Controle, kind (groep 1)',
    BARN_K2: 'Controle, kind (groep 2)',
    BITE_PLATE_DENTIST: 'Opbeetplaat (tandarts)',
    BITE_PLATE_HYGIENIST: 'Opbeetplaat (mondhygiënist)',
    BROKEN_TOOTH_OR_FILLING: 'Gebroken vulling, kies of tand',
    BUILD_UP: 'Opetsing',
    CALCULUS: 'Tandsteen verwijderen',
    CANINE_EXPOSURE: 'Parondontologische: vrijleggen hoektand',
    CB_IMPLANTS_BITE_REGISTRATION: 'K&B Implantaten: beetname',
    CB_IMPLANTS_PLACEMENT: 'K&B implantaten: plaatsen',
    CB_IMPLANTS_WAX_FIT: 'K&B Implantaten:  pas',
    CB_NATURAL_TEETH_BITE_REGISTRATION: 'K&B eigen tanden: beetname',
    CB_NATURAL_TEETH_IMPLANTS_PLACEMENT: 'K&B eigen tanden: plaatsen',
    CB_NATURAL_TEETH_PREPARATION: 'K&B eigen tanden: Omslijpen',
    CB_NATURAL_TEETH_WAX_FIT: 'K&B eigen tanden: pas',
    CBCT_IMPLANTS: 'Parondontologische: CBCT  implantaten',
    CBCT: 'CBCT',
    CERAMIC_CONSULTATION: 'Keramisch - afspraak consult',
    CERAMIC_PROSTETIC_ATTACHMENT: 'Keramisch - afspraak passen prothetiek',
    CERAMIC_REPLICA: 'Keramisch - afspraak passen replicatie',
    CHECK_UP_AND_ASSESSMENT: 'Controle en beoordeling of behandeling nodig is',
    CHECK_UP: 'Controle',
    CLEANING_COMPREHENSIVE: 'Gebitsreiniging',
    CLEANING_WITH_AIRFLOW_DPSI_1_2: 'Reinigen met airflow (DPSI 1 of 2)',
    CLEANING_WITH_AIRFLOW_DPSI_3: 'Reinigen met airflow (DPSI 3- of 3+)',
    CLEANING_WITH_AIRFLOW_DPSI_4: 'Reinigen met airflow (DPSI 4)',
    CLEANING_WITH_AIRFLOW: 'Reinigen met airflow',
    CLEANING: 'Reiniging',
    CLEAR_OVERLAY_RETRIEVAL: 'CO ophalen',
    CLINICAL_CROWN_LENGTHENING: 'Parondontologische: klinische kroonverlenging',
    COLLECTING_DATA: 'Uitwerken algemeen',
    CONTINUATION_OF_TREATMENT: 'Voortzetting van behandeling',
    CONTINUED_FILLING: 'Voortzetting van behandeling',
    CRACK: 'Afgebroken kies/tand',
    CROWN_AND_BRIDGE_PLACEMENT: 'Kroon en brugwerk plaatsen',
    CROWN_AND_BRIDGE_PREP: 'Kroon en brugwerk prepareren',
    CROWN_CEMENTING: 'Cementeren kroon',
    DENTAL_NURSE: 'Tandartsassistente',
    DENTIST_CHECKUP: 'Controle door tandarts',
    DIAGNOSTIC_WAX_UP: 'Diagnostische opwas omzetten',
    DIGITAL_SMILE_DESIGN_FACINGS_OR_CROWNS: 'DSD Facings/kronen',
    DISTRIBUTION_AND_INSTRUCTIONS_FOR_WHITENING_TRAYS:
      'Afgeven bleekbeugel en uitleg geven',
    DOCUMENT_RETRIEVAL: 'Openbaarmaking patiëntinformatie',
    DSD_CONSULTATION: 'Digital Smile Design 1e consult',
    DSD_FACINGS_OR_CROWNS: 'DSD Facings/kronen',
    DSD_INTAKE: '  Digital Smile Design Intake',
    DSD_PHOTOSHOOT: 'DSD make-up / fotoshoot',
    DSD_TEST_DRIVE: 'Digital Smile Design test-drive your smile',
    EMERGENCY_ABSCESS: 'Urgenties: Abces (met zwelling)',
    EMERGENCY_BROKEN_TEETH_OR_FILLING: 'Urgenties: Afgebroken tand/vulling',
    EMERGENCY_DENTURE_PROBLEM: 'Dringend probleem aan uitneembaar gebit',
    EMERGENCY_IMPLANT_PROBLEM: 'Dringend probleem aan implantaten',
    EMERGENCY_LOOSE_CROWN: 'Urgenties: Porseleinen kroon/brug los',
    EMERGENCY_OTHER: 'Urgenties: Andere',
    EMERGENCY_PAIN: 'Urgenties: Pijnklacht',
    EMERGENCY_TEETH_PROBLEM: 'Dringend probleem aan eigen tanden',
    EMERGENCY_TRAUMA: 'Urgenties: Trauma',
    ENDODONTIC_ADVICE: 'Endodontisch advies',
    EQUIPMENT_MICROSCOPE: 'Apparatuur microscoop',
    ESTHETIC_FILLING: 'Esthetische vulling',
    ESTHETIC: 'Slijtage',
    EXTRACTION_AND_LPRF_OR_BONE_GRAFT: 'Parondontologische: extractie+ lprf',
    EXTRACTION: 'Extractie',
    FACIAL_ANALYSIS_DETERMINATION: 'Gezichtsanalyse bepaling',
    FILLING_DECAY_1_OLDER_10: '1 vulling +10j',
    FILLING_DECAY_1_YOUNGER_10: '1 vulling -10j',
    FILLING_DECAY_1: '1 vulling',
    FILLING_DECAY_2_OLDER_10: '2 vullingen +10j',
    FILLING_DECAY_2_YOUNGER_10: '2 vullingen -10j',
    FILLING_DECAY_2: '2 vullingen',
    FILLING_DECAY_3_OLDER_10: '3 of meer vullingen +10j',
    FILLING_DECAY_3_YOUNGER_10: '3 of meer vullingen -10j',
    FILLING_DECAY_3: '3 of meer vullingen',
    FILLING_DECAY: 'Vulling',
    FOLLOW_UP_INTERNAL_BLEACHING: 'Opvolgen intern bleken',
    FORTUNA_ANNUAL_CHECK_UP_AND_CLEANING: 'Controle en gebitsreiniging',
    FORTUNA_CROWN_OR_BRIDGEWORK: 'KB',
    FORTUNA_EMERGENCY_BROKEN_TEETH_OR_FILLING: 'Afgebroken tand/vulling',
    FORTUNA_EMERGENCY_PAIN: 'Pijnklacht',
    FORTUNA_ENDODONTIC_TREATMENT_LONG: 'Endo 90',
    FORTUNA_ENDODONTIC_TREATMENT_SHORT: 'Endo 60',
    FORTUNA_ESTHETIC_FILLING: 'Vulling 50',
    FORTUNA_FILLING_DECAY: 'Vulling - cariës',
    FORTUNA_NEW_PATIENT: 'Nieuwe patiënt',
    FORTUNA_OTHER: 'Overige',
    FORTUNA_PERIODONTITIS_CLEANING: 'Paro Nazorg',
    FORTUNA_PERIODONTITIS_NEW_PATIENT: 'Paro Intake',
    FORTUNA_PERIODONTITIS_TREATMENT: 'Paro Initieel',
    FORTUNA_PROSTHESIS_LONG: 'Prothetiek lang',
    FORTUNA_PROSTHESIS_SHORT: 'Prothetiek kort',
    FORTUNA_TREATMENT_PLAN_DISCUSSION: 'Bespreking behandelplan',
    GENERAL_CONSULTATION: 'Algemeen advies',
    GENERAL_PARO_CONSULTATION: 'Parondontologische: Advies',
    GINGIVAL_GRAFT: 'Parondontologische: gingivagreffe',
    GINGIVECTOMIE_AND_ROOTPLANNING: 'Parondontologische: Gingivectomie',
    GRINDING_OR_CLENCHING: 'Pijn kaakgewricht',
    GUM_TREATMENT_AFTERCARE: 'Tandleevs behandeling nazorg',
    GUM_TREATMENT_INITIALLY: 'Tandleevs behandeling initieel',
    GUM_TREATMENT_INTAKE: 'Tandleevs behandeling intake',
    HOMEWHITENING: 'Home whitening',
    HYGIENIST_CHECKUP: 'Controle door mondhygiënist',
    HYGIENIST: 'Mondhygiënist',
    IMPLANT_CHECKUP: 'Implantaat - controleafspraak',
    IMPLANT_CONSULTATION: 'Consult Implantoloog',
    IMPLANT_HOME_CARE_CONTROL_VISIT: 'Implantaat - vervolgafspraak thuiszorg',
    IMPLANT_PLACEMENT: 'Parondontologische: Plaatsen implantaten',
    IMPLANT_PROSTETIC_ATTACHMENT:
      'Implantaat - afspraak passen prothetieke reproductie',
    IMPLANT_PROSTETIC_CONTROL_VISIT: 'Implantaat - vervolgafspraak',
    IMPLANT_PROSTETIC_IMPRESSION:
      'Implantaat - afspraak prothetieke replicatie',
    IMPLANT_SURGICAL: 'Implantaat - afspraak chirurgische behandeling',
    IMPRESSION_FOR_CUSTOMISED_WHITENING_TRAYS: 'Afdruk bleekmal',
    INMAN_APPLICATION: 'Afspraak passen Inman Aligner',
    INMAN_CHECKUP: 'Controleafspraak Inman Aligner',
    INMAN_CONSULTATION: 'Afspraak consult Inman Aligner',
    INMAN_FINAL: 'Afsluitende afspraak Inman Aligner',
    INTERPRETER: 'Tolk',
    INVISALIGN_CHECK_UP: 'Invisalign periodieke check',
    NEW_PATIENT: 'Nieuwe patiënt',
    OCCLUSAL_SPLINT: 'Occlusale spalk',
    OPG: 'OPG',
    OREBRO_PATIENT_LIFT_NEEDED: 'Patiëntenlift vereist',
    ORTHO_LOOSE_BRACKET_DENTIST: 'Orthodontie - Bracket los (tandarts)',
    ORTHODONTICS_APPLICATION: 'Orthodontie - Plaatsen beugel',
    ORTHODONTICS_ASSESSMENT: 'Orthodontie - Beoordelen door tandarts',
    ORTHODONTICS_BITE_REGISTRATION: 'Orthodontie: Beetregistratie',
    ORTHODONTICS_CHECK_INVISALIGN: 'Orthodontie: Controle Invisalign',
    ORTHODONTICS_CHECK_SPLINT: 'Orthodontie: Controle opbeetplaat',
    ORTHODONTICS_CHECKUP: 'Orthodontie - Controle',
    ORTHODONTICS_COLLECTING_DATA: 'Orthodontie: Uitwerken',
    ORTHODONTICS_CONSULTATION: 'Orthodontie - Intake',
    ORTHODONTICS_DOCUMENTATION: 'Orthodontie - Dokumenteren',
    ORTHODONTICS_FINAL: 'Orthodontie - Beugel verwijdere',
    ORTHODONTICS_FIRST_VISIT: 'Orthodontie: 1e maal ortho',
    ORTHODONTICS_GENERAL_CHECK_AND_ADVICE:
      'Orthodontie: Controle + poetscontrole',
    ORTHODONTICS_GENERAL_CHECK: 'Orthodontie: Controle',
    ORTHODONTICS_LOOSE_BRACKET: 'Orthodontie - Bracket los',
    ORTHODONTICS_LOOSE_THREAD: 'Orthodontie - Draad los',
    ORTHODONTICS_PLACEMENT_FIXED_APPLIANCE:
      'Orthodontie: Plaatsen apparatuur vast',
    ORTHODONTICS_PLACEMENT_REMOVABLE_APPLIANCE:
      'Orthodontie: Plaatsen apparatuur uitneembaar',
    ORTHODONTICS_PLACEMENT_SPLINT:
      'Orthodontie: Plaatsen Opbeetplaat/Herbst/1 kaak brackets/minischroeven',
    ORTHODONTICS_REMOVE_BRACKETS_IMPRESSION_FULL_MOUTH:
      'Orthodontie: Afname brackets + afdrukken BK en OK',
    ORTHODONTICS_REMOVE_BRACKETS_RETENTION_AND_IMPRESSION:
      'Orthodontie: Afname brackets + plaatsen retentiedraad OK + afdruk BK',
    ORTHODONTICS_REMOVE_BRACKETS_RETENTION_FULL_MOUTH:
      'Orthodontie: Afname Brackets + Plaatsen retentiedraad BK en OK',
    ORTHODONTICS_REMOVE_HERBST: 'Orthodontie: Afname Herbst',
    ORTHODONTICS_REMOVE_HYRAX: 'Orthodontie: Afname Hyrax',
    ORTHODONTICS_SEPARATION: 'Orthodontie: Separeren',
    OTHER: 'Overige',
    PARO: 'Parodontologische behandeling',
    PERIODONTAL_CHECK: 'Parondontologische: controle',
    PLACEMENT_INTERRADICULAR_SCREW:
      'Parondontologische: interradiculaire schroef',
    POSTCONTROL: 'Efterkontroll',
    PREPARE_CROWN: 'Prepare crown',
    RECALL: 'Herinnering',
    RELEASE_IMPLANTS: 'Parondontologische: Vrijleggen implantaten',
    REMOVABLE_DENTURE_BITE_REGISTRATION: 'Prothese stap 3: beetname',
    REMOVABLE_DENTURE_CHECK_UP: 'Prothese stap 6: controle',
    REMOVABLE_DENTURE_DEFINITIVE_IMPRESSION:
      'Prothese stap 2: individuele afdruk',
    REMOVABLE_DENTURE_FIRST_IMPRESSION: 'Prothese stap 1: Alginaat',
    REMOVABLE_DENTURE_PLACEMENT: 'Prothese stap 5: plaatsen',
    REMOVABLE_DENTURE_WAX_FIT: 'Prothese stap 4: pas in was',
    REMOVAL: 'Tand-/kiesextractie',
    RESERVATIONS_SLOT: 'Reserveringsslot',
    RESTORATION: 'Vulling',
    RETURNING_DENTURE: 'Terugplaatsen prothese',
    ROOM_BREAK: 'pauze',
    ROOT_CANAL_MOLAR: 'Wortelkanaalbehandeling-molaar',
    ROOT_TREATMENT_CONTINUATION: 'Wortelkanaalbehandeling voortgezet',
    ROOT_TREATMENT: 'Wortelkanaalbehandeling',
    SCALING_AND_ROOTPLANNING: 'Parondontologische: Scaling en rootplaning',
    SENSITIVITY: 'Gevoeligheid',
    SINUS_LIFT: 'Parondontologische: sinuslift',
    SNORING_PROBLEMS: 'Snurkproblemen/mra beugel',
    SURGICAL_CONSULTATION: 'Consult voor ingreep',
    SURGICAL_EXTRACTION: 'Parondontologische: Chirurgische extractie',
    SURGICAL_FLAP: 'Parondontologische: lap',
    SURGICAL_OPERATION: 'Afspraak voor ingreep',
    SURGICAL_SUTURE_REMOVAL: 'Verwijderen hechtingen',
    TEETH_CLEANING: 'Teeth cleaning',
    TREATMENT_PLAN_DISCUSSION: 'Bespreken behandelplan',
    TTN_AIRSMILE: 'Airsmile',
    TTN_ALLIGNERS: 'Alligners',
    TTN_ANNUAL_CHECK_UP_AND_CLEANING: 'Controle en gebitsreiniging',
    TTN_CROWN_OR_BRIDGEWORK: 'KB',
    TTN_EMERGENCY_BROKEN_TEETH_OR_FILLING: 'Spoed: Pijn/Afgebroken kies',
    TTN_EMERGENCY_PAIN: 'Pijnklacht',
    TTN_ENDODONTIC_TREATMENT_LONG: 'Endo 90',
    TTN_ENDONDONTIC_TREATMENT_SHORT: 'Endo',
    TTN_ESTHETIC_FILLING: 'Restauratie: Esthetiek',
    TTN_EXTRACTION: 'Extractie',
    TTN_FILLING_DECAY_PRIMARY: 'Restauratie: Primaire cariës',
    TTN_FILLING_DECAY_SMALL: 'Restauratie klein',
    TTN_FILLING_DECAY: 'Restauratie',
    TTN_NEW_PATIENT: 'Nieuwe patiënt',
    TTN_PERIODONTITIS_CLEANING: 'Parodontale Nazorg',
    TTN_PERIODONTITIS_NEW_PATIENT: 'Paro Intake',
    TTN_PERIODONTITIS_TREATMENT: 'Parodontitis',
    TTN_PREVENTIVE_CARE: 'Preventieve zorg',
    TTN_PROSTHESIS_LONG: 'Prothetiek',
    TTN_PROSTHESIS_SHORT: 'Prothetiek kort',
    TTN_SHORT_CONSULTATION_ASSIST: 'Kort consult MH/PA',
    TTN_SHORT_CONSULTATION_DENTIST: 'Kort consult TA',
    TTN_TREATMENT_PLAN_DISCUSSION: 'Bespreking behandelplan',
    UNKNOWN: 'Onbekend',
    WHITENING: 'Tandenbleken',
  },
};
