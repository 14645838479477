import React from 'react';

const SnellCalendarCloseButton = (props) => {
  const { handleClick } = props;

  return (
    <div className="closeButtonWrapper">
      <button onClick={handleClick} className="closeButton" />
    </div>
  );
};

export default SnellCalendarCloseButton;
