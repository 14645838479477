export const loadIntroStatus = () => loadFromLocalStorage('introStatus');
export const saveIntroStatus = (state) =>
  saveToLocalStorage('introStatus', state);

export const loadCustomer = () => loadFromLocalStorage('customer');
export const saveCustomer = (state) => saveToLocalStorage('customer', state);

const loadFromLocalStorage = (fieldName) => {
  try {
    const serialized = localStorage.getItem(fieldName);
    if (serialized === null) {
      return undefined;
    }
    return JSON.parse(serialized);
  } catch (err) {
    return undefined;
  }
};

const saveToLocalStorage = (fieldName, value) => {
  try {
    const serialized = JSON.stringify(value);
    localStorage.setItem(fieldName, serialized);
  } catch (e) {
    // ignore write errors
  }
};
