import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changeProgressAction, hideTabsAction } from '../../actions';
import SnellInput from '../../components/SnellInput/SnellInput.js';
import './SnellCheckin.css';
import inputCheck from '../../assets/images/icon_input_check.svg';
import inputCross from '../../assets/images/icon_input_cross.svg';
import warning from '../../assets/images/icon_warning.svg';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import SnellTimeFrame from '../../components/SnellTimeFrame/SnellTimeFrame.js';
import {
  reservationApi,
  translate as tl,
  formatMomentDate,
} from '../../utils/helpers.js';

class SnellCheckin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      reservation: {},
      hasAnamnesis: null,
      checkinSuccess: null,
      error: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(hideTabsAction(true));
    this.props.dispatch(changeProgressAction(6, tl('Checkin')));

    if (this.props.match.params.code) {
      reservationApi('GET', 'reservation', 'getReservationByCode', {
        code: this.props.match.params.code,
      })
        .then((response) => {
          if (response.data && response.data.id) {
            this.setState({ reservation: response.data });
            if (this.props.customization.anamnesisEnabled) {
              if (
                response.data.customer === null ||
                response.data.customer === undefined
              ) {
                this.setState({ isLoading: false });
              } else {
                reservationApi('GET', 'reservation', 'getLatestAnamnesis', {
                  customer: response.data.customer,
                })
                  .then((response) => {
                    this.setState({
                      hasAnamnesis: response.data !== null,
                      isLoading: false,
                    });
                  })
                  .catch((error) => {
                    this.setState({
                      isLoading: false,
                      error: tl('General checkin error'),
                    });
                  });
              }
            } else {
              this.setState({ isLoading: false });
            }
          } else {
            this.setState({
              isLoading: false,
              reservation: null,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            reservation: null,
            error: tl('General checkin error'),
          });
        });
    } else {
      console.log('No reservation code');
      this.setState({
        reservation: null,
        isLoading: false,
      });
    }
  }

  doCheckIn = () => {
    // Clear error before next attempt
    if (this.state.error) {
      this.setState({ error: null });
    }

    reservationApi('POST', 'visit', 'markPatientArrived', {
      reservationId: this.state.reservation.id,
      arrived: true,
      kelaCardPresent: 'null',
    })
      .then((response) => {
        if (response.data && response.data.error) {
          throw new Error('Error checking in');
        } else {
          this.setState({ checkinSuccess: true });
        }
      })
      .catch((error) => {
        console.log('Error', error);
        this.setState({ error: tl('Checkin error') });
      });
  };

  goToPreinformationForm = () => {
    this.props.history.push(
      `/${tl('preinformation')}/${
        this.props.match.params.code
      }?forwardToCheckin=true`,
    );
  };

  render() {
    const startTime =
      this.state.reservation && this.state.reservation.id
        ? moment(this.state.reservation.reservationSlotStartTime.date)
        : null;

    const getContent = () => {
      // View after successful checkin
      if (this.state.checkinSuccess) {
        return (
          <React.Fragment>
            <div className="SnellCheckinSuccessContainer">
              <img
                className="SnellCheckin__icon--check"
                src={inputCheck}
                alt=""
              />
              <span>{tl('Checkin successful')}</span>
            </div>
            <span>{tl('Checkin successful info')}</span>
          </React.Fragment>
        );
      }

      // View for when reservation code in url is invalid
      if (!this.state.reservation) {
        return (
          <React.Fragment>
            <div className="SnellCheckinSuccessContainer">
              <img
                className="SnellCheckin__icon--cross"
                src={inputCross}
                alt=""
              />
              <span>{tl('Unknown reservation code')}</span>
            </div>
            <span>{tl('Unknown reservation code info')}</span>
          </React.Fragment>
        );
      }

      // View for when checkin has already been done
      if (this.state.reservation.enteredClinique) {
        return (
          <React.Fragment>
            <div className="SnellCheckinSuccessContainer">
              <img
                className="SnellCheckin__icon--check"
                src={inputCheck}
                alt=""
              />
              <span>{tl('Checkin done')}</span>
            </div>
            <span>{tl('Checkin successful info')}</span>
          </React.Fragment>
        );
      }

      // View for when patient has not completed anamnesis
      if (
        this.props.customization.anamnesisEnabled &&
        !this.state.hasAnamnesis
      ) {
        return (
          <React.Fragment>
            <span>{tl('Missing anamnesis info')}</span>
            <div className="SnellCheckinButtonContainer">
              <SnellInput
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.goToPreinformationForm();
                }}
                value={tl('Fill out preinformation')}
              />
            </div>
          </React.Fragment>
        );
      }

      // View for checking in
      return (
        <React.Fragment>
          <span>{tl('Checkin info')}</span>
          <div className="SnellCheckinButtonContainer">
            <SnellInput
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.doCheckIn();
              }}
              value={tl('Checkin button text')}
            />
          </div>
        </React.Fragment>
      );
    };

    if (this.state.isLoading === true) {
      return (
        <div className="SnellCheckinContainer">
          <div className="SnellReservationView__loader">Loading...</div>
        </div>
      );
    } else {
      return (
        <div className="SnellCheckinContainer">
          <div className="SnellCheckinContentContainer">
            {this.state.error && (
              <div className={'SnellCheckinError'}>
                <img
                  className="SnellCheckinError__icon--warning"
                  src={warning}
                  alt=""
                />
                <span>{this.state.error}</span>
              </div>
            )}
            {startTime && (
              <React.Fragment>
                <div className="SnellCheckinDateContainer">
                  <span>{tl('You have a reservation')}:</span>
                  <span>{formatMomentDate(startTime, true)}</span>
                  <span>
                    {tl('Klo')} <SnellTimeFrame date={startTime} />
                  </span>
                </div>
              </React.Fragment>
            )}
            {getContent()}
          </div>
        </div>
      );
    }
  }
}

// Redux Connected Component
const CtdSnellCheckin = connect(mapStateToProps(['customization']))(
  SnellCheckin,
);

export default CtdSnellCheckin;
