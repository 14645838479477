import moment from 'moment';
import React from 'react';

class IdleTimeoutHandler extends React.Component {
  constructor(props) {
    super(props);
    this.events = ['click', 'scroll', 'load', 'keydown'];
    this.timer = undefined;
    this.eventHandler = this.eventHandler.bind(this);
  }

  componentDidMount() {
    localStorage.setItem('lastInteractionTime', moment());
    this.addEvents();
  }

  componentWillUnmount() {
    localStorage.removeItem('lastInteractionTime');
    this.removeEvents();
  }

  startTimer() {
    this.timer = setTimeout(
      () => {
        const lastInteractionTime = localStorage.getItem('lastInteractionTime');

        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime)),
        );

        // Default is 600000 ms = 10 minutes
        const timeOutInterval = this.props.timeOutInterval
          ? this.props.timeOutInterval
          : 600000;

        if (diff._milliseconds < timeOutInterval) {
          this.startTimer();
          this.props.onActive();
        } else {
          this.props.onIdle();
        }
      },

      // Default is 600000 ms = 10 minutes
      this.props.timeOutInterval ? this.props.timeOutInterval : 600000,
    );
  }

  addEvents() {
    this.events.forEach((eventName) => {
      window.addEventListener(eventName, this.eventHandler);
    });

    this.startTimer();
  }

  removeEvents() {
    this.events.forEach((eventName) => {
      window.removeEventListener(eventName, this.eventHandler);
    });

    clearTimeout(this.timer);
  }

  eventHandler(eventType) {
    localStorage.setItem('lastInteractionTime', moment());
    if (this.timer) {
      this.props.onActive();
      this.startTimer();
    }
  }

  render() {
    return <div></div>;
  }
}

export default IdleTimeoutHandler;
