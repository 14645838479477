import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';

import { customerLogin } from '../../actions/index';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import { ManagedCustomerForm } from '../../components/CustomerForm/CustomerForm.js';
import {
  translate as tl,
  reservationApi,
  dateOfBirthFormat,
} from '../../utils/helpers.js';

import './CustomerLogin.css';

class CustomerLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoTextContent: null,
    };
  }

  handleLoginAttempt = (params) => {
    return reservationApi('GET', 'customer', 'searchCustomer', params, {})
      .then((response) => {
        if (!response.data || response.data.status === false) {
          return {
            ok: false,
            errorMsg: this.props.customization.noCustomerFoundMsg,
            canRecover: true,
          };
        }
        // normalize
        let { dateOfBirth, ...data } = response.data;
        if (dateOfBirth) {
          const format = dateOfBirthFormat(this.props.customization);
          if (format !== 'YYYY-MM-DD') {
            dateOfBirth = moment(dateOfBirth, 'YYYY-MM-DD').format(format);
          }
          data.dateOfBirth = dateOfBirth;
        }
        this.props.dispatch(customerLogin(data));
        return { ok: true };
      })
      .catch((error) => ({
        ok: false,
        errorMsg: tl(
          'Something went wrong. Please contact the customer service.',
        ),
        canRecover: false,
      }));
  };

  componentDidMount() {
    const { login_info } = this.props.customization;
    if (login_info) {
      const infoBlurb = login_info.join('');
      this.setState({ infoTextContent: infoBlurb });
    }
  }

  render() {
    return (
      <div className="SnellReservation">
        <div className="SnellReservationView intro">
          <div className="SnellConfirm row">
            <div className="col"></div>

            <div className="col">
              <div className="login-text">
                {this.state.infoTextContent &&
                  renderHTML(this.state.infoTextContent)}
                <p>
                  {tl(
                    'In order to proceed to the online time reservation you need to login by providing your personal details.',
                  )}
                </p>
              </div>
              <ManagedCustomerForm
                key="login"
                fields={this.props.customization.loginInformation}
                submit={this.handleLoginAttempt}
                submitText={tl('Login')}
              />
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

const CtdCustomerLogin = connect(mapStateToProps(['customization']))(
  CustomerLogin,
);
export default CtdCustomerLogin;
