import 'raf/polyfill';
import 'core-js/stable';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import WebFont from 'webfontloader';

import App from './App';
import {
  loadIntroStatus,
  saveIntroStatus,
  loadCustomer,
  saveCustomer,
} from './assets/scripts/localStorage.js';
import {
  reservationReducer as reducer,
  initialState,
} from './assets/scripts/redux.js';
import './index.css';
import './overrides.css';

// Load fonts
WebFont.load({
  custom: {
    families: ['Nunito:n4,n7'],
  },
});

var Promise = require('promise'); // eslint-disable-line

const objectsSame = (o1, o2) => {
  const o1Present = !!o1;
  const o2Present = !!o2;
  if (o1Present !== o2Present) {
    return false;
  }
  if (!o1Present && !o2Present) {
    return true;
  }
  const k1 = Object.keys(o1);
  const k2 = Object.keys(o2);
  if (k1.length !== k2.length) {
    return false;
  }
  for (const k of k1) {
    if (!k2.includes(k)) {
      return false;
    } else if (o1[k] !== o2[k]) {
      return false;
    }
  }
  return true;
};

// Redux Store initialization
const introStatus = loadIntroStatus();
let completed = false;
if (introStatus) {
  const diff = moment().diff(moment(introStatus.completed));
  completed = moment.duration(diff).as('days') < 1;
}

let customer = undefined;
const savedCustomer = loadCustomer();
if (savedCustomer && savedCustomer.ts) {
  const diff = moment().diff(moment(savedCustomer.ts));
  if (moment.duration(diff).as('hours') < 2) {
    customer = savedCustomer.customer;
  }
}

let introPassed = completed;
const store = createStore(reducer, {
  ...initialState,
  introStatus: introPassed,
  customer,
});

store.subscribe(() => {
  const state = store.getState();
  if (!introPassed && state.introStatus && state.customization) {
    introPassed = true;
    if (state.customization.introEnabled) {
      saveIntroStatus({ completed: moment() });
    }
  } else if (
    state.customization &&
    state.customization.requiresLogin &&
    !objectsSame(customer, state.customer)
  ) {
    customer = state.customer;
    saveCustomer({ customer, ts: moment() });
  }
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
