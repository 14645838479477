import jsSHA from 'jssha/src/sha256.js';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changeProgressAction } from '../../actions/index';
import banks from '../../assets/scripts/banks.js';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import PaytrailNotification from '../../components/PaytrailNotification/PaytrailNotification';
import SnellInput from '../../components/SnellInput/SnellInput';
import {
  translate as tl,
  options,
  getBaseUrl as gbu,
  reservationApi,
} from '../../utils/helpers.js';

import './SnellPayment.css';

class SnellPayment extends Component {
  constructor(props) {
    super(props);

    // Set component level state. Get reservation fee and reservation code from
    // Confirm -page.
    this.state = {
      authcode: '',
      paymentMethod: 0,
      reservationFee: '',
      reservationId: '',
      reservationCode: '',
      reservationStartTime: '',
      reservationEndTime: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(changeProgressAction(3, tl('Choose payment method')));
    let code;

    if (this.props.slot.reservationCode) {
      code = this.props.slot.reservationCode;
    } else {
      code = this.props.match.params.code;
    }

    if (code) {
      reservationApi('GET', 'reservation', 'getReservationByCode', {
        code,
      })
        .then((response) => {
          this.setState({
            reservationFee: response.data.reservationFee,
            reservationCode: response.data.code,
            reservationId: response.data.id,
            reservationStartTime: response.data.reservationSlotStartTime,
            reservationEndTime: response.data.reservationSlotEndTime,
          });
          this.logAndFireGTMevent(response.data.id);
        })
        .catch((error) => {});
    }
  }

  logAndFireGTMevent(reservationId) {
    /**
     * We set reservation id to session storage. This value is used in 'Complete' page
     * to fire conversion event. When we send the conversion event after succesfull payment,
     * we use this value to see if conversion is already logged or not.
     */
    sessionStorage.setItem('reservationInProgress', reservationId);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'bookingPaymentSelection',
    });
  }

  changePaymentMethod = (bankID) => {
    this.setState(
      {
        paymentMethod: bankID,
      },
      this.generateAuthcode,
    );
  };

  generateAuthcode() {
    const code = this.getSHA();
    this.setState({ authcode: code });
  }

  getSHA() {
    // @todo @alvar Should this be done server side,
    // as it uses merchant's secret key?
    const form = this.form || { elements: [] };

    const serializedForm = [].reduce.call(
      form.elements,
      (acc, input) => {
        let value = input.value;

        if (
          input.type === 'submit' ||
          ~['AUTHCODE', 'MODE'].indexOf(input.name)
        ) {
          value = '';
        }

        if (!acc || !value) {
          return acc || value;
        } else if (acc && value) {
          return `${acc}|${value}`;
        } else if (acc || value) {
          return acc || value;
        }
      },
      options.paytrailMerchantHash,
    );

    if (serializedForm === options.paytrailMerchantHash) {
      return '';
    }

    const SHA = new jsSHA('SHA-256', 'TEXT');
    SHA.update(serializedForm);

    return SHA.getHash('HEX', { outputUpper: true });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.generateAuthcode();
    this.form.submit();
  }

  render() {
    return (
      <div className="SnellPayment">
        <div className="SnellPayment__paymentInfo">
          <div className="SnellPayment__paymentInfoCol SnellPayment__paymentInfoCol--label">
            {tl('Price')}:
          </div>
          <div className="SnellPayment__paymentInfoCol">
            {this.state.reservationFee}0 €
          </div>
          <div className="SnellPayment__paymentInfoCol SnellPayment__paymentInfoCol--label">
            {tl('Timeframe')}:
          </div>
          <div className="SnellPayment__paymentInfoCol">
            {moment(this.state.reservationStartTime).format('D.M. HH:mm')} -{' '}
            {moment(this.state.reservationEndTime).format('HH:mm')}
          </div>
        </div>
        <ul className="SnellPayment__banks">
          {Object.keys(banks).map((bankID) => {
            return (
              <SnellPaymentBank
                key={bankID}
                bank={{ ...banks[bankID], id: bankID }}
                paymentMethod={this.state.paymentMethod}
                handleChangePaymentMethod={this.changePaymentMethod}
              />
            );
          })}
        </ul>

        <form
          action="https://payment.paytrail.com/e2"
          method="post"
          onSubmit={(event) => this.handleFormSubmit(event)}
          ref={(form) => (this.form = form)}
        >
          <input
            name="MERCHANT_ID"
            type="hidden"
            value={options.paytrailMerchantID}
          />
          <input
            name="URL_SUCCESS"
            type="hidden"
            value={this.getSuccessUrl()}
          />
          <input name="URL_CANCEL" type="hidden" value={this.getCancelUrl()} />
          <input name="URL_NOTIFY" type="hidden" value={this.getNotifyUrl()} />

          <input
            name="ORDER_NUMBER"
            type="hidden"
            value={this.state.reservationId}
          />
          <input
            name="PARAMS_IN"
            type="hidden"
            value="MERCHANT_ID,URL_SUCCESS,URL_CANCEL,URL_NOTIFY,ORDER_NUMBER,PARAMS_IN,PARAMS_OUT,AMOUNT,PAYMENT_METHODS,ALG"
          />
          <input
            name="PARAMS_OUT"
            type="hidden"
            value="PAYMENT_ID,TIMESTAMP,STATUS"
          />
          <input
            name="AMOUNT"
            type="hidden"
            value={this.state.reservationFee}
          />
          <input
            name="PAYMENT_METHODS"
            type="hidden"
            value={this.state.paymentMethod}
          />
          <input name="MODE" type="hidden" value="2" />
          <input name="ALG" type="hidden" value="1" />
          <input name="AUTHCODE" type="hidden" value={this.state.authcode} />
          <SnellInput
            className="SnellInput--limit"
            type="submit"
            value={tl('Proceed with payment')}
            disabled={!this.state.paymentMethod}
          />
        </form>
        <div className="SnellPayment__paymentNotes">
          <PaytrailNotification />
        </div>
      </div>
    );
  }

  getSuccessUrl() {
    const baseUrl = this.getBaseUrl();
    return `${baseUrl}/${tl('confirmed')}/${this.state.reservationCode}`;
  }

  getCancelUrl() {
    const baseUrl = this.getBaseUrl();
    return `${baseUrl}/${tl('payment')}/${this.state.reservationCode}`;
  }

  getNotifyUrl() {
    return `${options.apiEntryPoint}/billing/addReservationOnlinePayment?reservationId=${this.state.reservationId}`;
  }

  getBaseUrl() {
    const url = window.location.href;
    const urlParts = url.split('/');
    let returnUrl;

    if (`${urlParts[0]}//${urlParts[2]}` === 'http://localhost:5843') {
      returnUrl = 'http://127.0.0.1:5843';
    } else {
      returnUrl = `${urlParts[0]}//${urlParts[2]}${gbu()}`;
    }

    return returnUrl;
  }
}

class SnellPaymentBank extends Component {
  render() {
    const bank = this.props.bank;

    return (
      <li className="SnellPaymentBank">
        <button
          className={`SnellPaymentBank__button ${
            bank.id === this.props.paymentMethod
              ? 'SnellPaymentBank__button--isSelected'
              : ''
          }`}
          onClick={() => this.props.handleChangePaymentMethod(bank.id)}
        >
          <img src={bank.src} alt={bank.alt} />
        </button>
      </li>
    );
  }
}

// Redux Connected Components
const CtdSnellPayment = connect(mapStateToProps(['slot']))(SnellPayment);

export default CtdSnellPayment;
