import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { setCustomization, setSettingsAction } from './actions/index.js';
import { mapStateToProps } from './assets/scripts/redux.js';
import SnellReservation from './containers/SnellReservation/SnellReservation';
import {
  getAuthSessionTimeout,
  getBaseUrl,
  isAuthInUse,
  translate as tl,
  reservationApi,
} from './utils/helpers';
import IdleTimeoutHandler from './utils/IdleTimeoutHandler.js';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    this.state = {
      today,
      isActive: true,
    };
  }

  componentDidMount() {
    reservationApi('GET', 'setting', 'listSettings')
      .then((response) => {
        this.props.dispatch(setSettingsAction(response.data));
      })
      .catch((e) => console.log('Failed to fetch settings:', e));

    import('./customizations.blob').then((m) => {
      fetch(m.default)
        .then((d) => d.json())
        .then((d) => {
          this.props.dispatch(setCustomization(d));
        })
        .catch((e) => console.log('Failed to read customizations:', e));
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isActive !== this.state.isActive && !this.state.isActive) {
      // Log out when idle period reaches length defined in REACT_APP_AUTH_SESSION_TIMEOUT
      if (this.props.authenticatedUser !== null) {
        window.location.replace(`${getBaseUrl()}/logout?status=logging-out`);
      }
    }
  }

  render() {
    const baseUrl = getBaseUrl();
    if (!this.props.customization) {
      return <p>Loading</p>;
    }
    return (
      <Router basename={baseUrl}>
        <div>
          {isAuthInUse() && (
            <IdleTimeoutHandler
              timeOutInterval={getAuthSessionTimeout()}
              onActive={() => this.setState({ isActive: true })}
              onIdle={() => this.setState({ isActive: false })}
            />
          )}
          <a id="skip-to-main-content" href="#main-content">
            {tl('skip-to-main-content')}
          </a>
          <div className="SnellApp">
            <Route
              path={`/`}
              render={({ match, location }) => (
                <div>
                  <SnellReservation
                    today={this.state.today}
                    match={match}
                    location={location}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </Router>
    );
  }
}

// Redux Connected Component
const CtdSnellApp = connect(
  mapStateToProps([
    'weeklyData',
    'dailyData',
    'customization',
    'authenticatedUser',
  ]),
)(App);

export default CtdSnellApp;
