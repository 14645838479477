import { getISOWeek } from 'date-fns';
import moment from 'moment';
import React from 'react';

import { translate as tl } from '../../utils/helpers.js';

/* eslint-disable */
import SnellIconArrowLeft from '-!svg-react-loader?name=SnellIconArrowLeft!../../assets/images/icon_ball_arrow_left.svg';
import SnellIconArrowRight from '-!svg-react-loader?name=SnellIconArrowRight!../../assets/images/icon_ball_arrow_right.svg';
/* eslint-enable */

const SnellReservationWeekNavigation = (props) => {
  const {
    currentDay,
    currentWeek,
    contract,
    firstWeekWithData,
    handleFetchData,
    isMobile,
    today,
  } = props;

  const previousStart = isMobile
    ? moment(currentDay).subtract(3, 'days')
    : currentWeek - 1;
  const nextStart = isMobile
    ? moment(currentDay).add(3, 'days')
    : currentWeek + 1;

  const disableBackButton = isMobile
    ? currentDay <= today
    : currentWeek <= getISOWeek(today) || currentWeek <= firstWeekWithData;

  return (
    <React.Fragment>
      <button
        className="Snell__button SnellReservationWeekNavigation__button left"
        id="previous-week-button"
        aria-label={tl('show-previous-week')}
        onClick={() => {
          handleFetchData(previousStart, true, contract);
        }}
        disabled={disableBackButton}
      >
        <SnellIconArrowLeft className="Snell__icon" />
      </button>
      <button
        className="Snell__button SnellReservationWeekNavigation__button right"
        id="next-week-button"
        aria-label={tl('show-next-week')}
        onClick={() => {
          handleFetchData(nextStart, true, contract);
        }}
      >
        <SnellIconArrowRight className="Snell__icon" />
      </button>
    </React.Fragment>
  );
};

export default SnellReservationWeekNavigation;
