import React from 'react';

import { translate as tl } from '../../utils/helpers.js';

function SlotInfoPanel(props) {
  let slotInfo;

  const slotRestrictionsAreRelevant =
    props.customization.slotRestrictionsOfInterest.length !== 0;

  slotInfo = (
    <div className="SlotInfoPanel">
      <table>
        <tbody>
          <tr>
            <td className="slotColorIcon">
              <div className="slotColorIcon-free"></div>
            </td>
            <td className="slotInfoText">= {tl('Available times')}</td>
          </tr>
          <tr>
            <td className="slotColorIcon slotColorIcon-full">
              <div className="slotColorIcon-full"></div>
            </td>
            <td className="slotInfoText">= {tl('No available times')}</td>
          </tr>
          {slotRestrictionsAreRelevant && (
            <tr>
              <td className="slotColorIcon slotColorIcon-limited">
                <div className="slotColorIcon-limited"></div>
              </td>
              <td className="slotInfoText">
                = {tl('Limited availability of treatments')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  return slotInfo;
}

export default SlotInfoPanel;
