import React, { Component } from 'react';

import './SnellInput.css';
import arrowRight from '../../assets/images/arrow-thick-right.svg';
import inputCheck from '../../assets/images/icon_input_check.svg';
import inputCross from '../../assets/images/icon_input_cross.svg';
import inputOk from '../../assets/images/icon_input_ok.svg';
import inputWarning from '../../assets/images/icon_input_warning.svg';

const icons = {
  arrowRight,
  inputCheck,
  inputCross,
  inputOk,
  inputWarning,
};

class SnellInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: null,
      focus: false,
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.handleClick();
  };

  handleFocusBlur = (e) => {
    const focus = e.type === 'focus';
    this.props.onFocusChange && this.props.onFocusChange(focus);
    this.setState({
      focus,
    });
  };

  render() {
    const additionalClasses = this.props.className ? this.props.className : '';
    const isSubmit = this.props.type === 'submit';
    const isButton = this.props.type === 'button';
    const hasFocus = this.state.focus ? 'SnellInput--focus' : '';

    let icon;
    let isValid = '';

    if (isSubmit || isButton) {
      icon = 'arrowRight';
    } else {
      switch (this.props.valid) {
        case true:
          icon = 'inputCheck';
          isValid = 'SnellInput--valid';
          break;
        case false:
          icon = 'inputCross';
          isValid = 'SnellInput--invalid';
          break;
        default:
        // Nothing, John Snow.
      }
    }

    return (
      <div
        className={`SnellInput ${
          isSubmit ? 'SnellInput--submit' : 'SnellInput--input'
        } ${hasFocus} ${isValid} ${additionalClasses}`}
      >
        {!isSubmit && (
          // eslint-disable-next-line jsx-a11y/label-has-for
          <label
            className="visuallyhidden"
            htmlFor={this.props.name}
            id={this.props.name}
          >
            `${this.props.placeholder}`
          </label>
        )}
        <input
          ref={(input) => (this.input = input)}
          id={this.props.name}
          name={this.props.name}
          type={this.props.type}
          onChange={(e) => {
            this.props.onChange(e);
          }}
          onBlur={this.handleFocusBlur}
          onClick={this.props.onClick}
          onFocus={this.handleFocusBlur}
          value={this.props.value}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          tabIndex={this.props.htmlTabIndex}
          maxLength={this.props.maxLength}
          disabled={this.props.disabled}
          aria-label={this.props.ariaLabel}
          title={this.props.title}
        />
        {icon ? (
          <img
            className={`SnellInput__icon SnellInput__icon--${icon}`}
            src={icons[icon]}
            alt=""
          />
        ) : null}
      </div>
    );
  }
}

export default SnellInput;
