// Actions
function addWeekAction(week, weekNumber) {
  return {
    type: 'ADD_WEEK',
    week,
    weekNumber,
  };
}

function setDailyDataAction(data, startDay) {
  return {
    type: 'SET_DAILY_DATA',
    data,
    startDay,
  };
}

function setFirstDayAction(startDays, contract) {
  return {
    type: 'SET_FIRST_DAY',
    startDays,
    contract,
  };
}

function resetWeeksAction() {
  return {
    type: 'RESET_WEEKS',
  };
}

function changeProgressAction(step, headerTitle) {
  return {
    type: 'CHANGE_PROGRESS',
    step,
    headerTitle,
  };
}

function selectContractAction(contract) {
  return {
    type: 'SELECT_CONTRACT',
    contract,
  };
}

function selectSlotAction(slot) {
  return {
    type: 'SELECT_SLOT',
    slot,
  };
}

function updateSlotAction(slot) {
  return {
    type: 'UPDATE_SLOT',
    slot,
  };
}

function toggleModalAction(modalToggled) {
  return {
    type: 'TOGGLE_MODAL',
    modalToggled,
  };
}

function hideTabsAction(tabsHidden) {
  return {
    type: 'HIDE_TABS',
    tabsHidden,
  };
}

function updateFreeStatus(status) {
  return {
    type: 'UPDATE_FREE_STATUS',
    freeStatus: status,
  };
}

function setFirstWeekAction(firstWeekWithData) {
  return {
    type: 'SET_FIRST_WEEK_WITH_DATA',
    firstWeekWithData,
  };
}

function setTemporaryReservationAction(reservation) {
  return {
    type: 'SET_TEMPORARY_RESERVATION',
    temporaryReservation: reservation,
  };
}

function setIntroStatus(status) {
  return {
    type: 'SET_INTRO_STATUS',
    status: status,
  };
}

function customerLogin(customer) {
  return {
    type: 'SET_CUSTOMER',
    customer: customer,
  };
}

function customerLogout() {
  return {
    type: 'SET_CUSTOMER',
    customer: null,
  };
}

function setAuthenticatedUser(user) {
  return {
    type: 'SET_AUTHENTICATED_USER',
    user: user,
  };
}

function logoutAuthenticatedUser() {
  return {
    type: 'SET_AUTHENTICATED_USER',
    user: null,
  };
}

function setCustomization(customization) {
  return {
    type: 'SET_CUSTOMIZATION',
    customization,
  };
}

function setSettingsAction(settings) {
  return {
    type: 'SET_SETTINGS',
    settings: settings,
  };
}

export {
  addWeekAction,
  setDailyDataAction,
  setFirstDayAction,
  changeProgressAction,
  resetWeeksAction,
  selectContractAction,
  selectSlotAction,
  toggleModalAction,
  hideTabsAction,
  updateSlotAction,
  updateFreeStatus,
  setFirstWeekAction,
  setTemporaryReservationAction,
  setIntroStatus,
  customerLogin,
  customerLogout,
  setAuthenticatedUser,
  logoutAuthenticatedUser,
  setCustomization,
  setSettingsAction,
};
