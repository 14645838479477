import _ from 'lodash';
import React from 'react';

import './SnellConfirm.css';
import inputCheck from '../../assets/images/icon_input_check.svg';
import inputCross from '../../assets/images/icon_input_cross.svg';
import { translate as tl } from '../../utils/helpers.js';

const AddAnother = (props) => {
  const text = tl('Add another patient..');

  return (
    <div className="PatientSelectorItem add">
      <div className="label">{text}</div>
      <div onClick={props.click} className="icon add">
        +
      </div>
    </div>
  );
};

const Patient = (props) => {
  const icon = props.valid ? 'inputCheck' : 'inputCross';
  return (
    <div className="PatientSelectorItem remove">
      <div
        onClick={() => props.select(props.index)}
        className={`label ${props.selected && 'selected'}`}
      >
        <div className="text">{props.label}</div>
        <img
          className={`is-valid-icon is-valid-${icon}`}
          src={props.valid ? inputCheck : inputCross}
          alt={props.valid ? 'Entry is valid' : 'Entry is invalid'}
        />
      </div>
      {props.enableIcon && (
        <div onClick={() => props.remove(props.index)} className="icon remove">
          -
        </div>
      )}
    </div>
  );
};

const PatientSelector = (props) => {
  const patients = props.labels.length;
  const existing = patients > 1 ? patients : 0;
  const elements = _.range(existing).map((i) => (
    <Patient
      key={i}
      index={i}
      label={props.labels[i]}
      remove={props.removePatient}
      select={props.selectPatient}
      selected={i === props.selected}
      enableIcon={i !== 0 || props.canRemoveFirst}
      valid={props.valids[i]}
    />
  ));

  return (
    <div className="PatientSelector">
      {elements}
      {props.maxItems > patients && <AddAnother click={props.addPatient} />}
    </div>
  );
};

export default PatientSelector;
