import React from 'react';

const SnellCalendarLoader = () => {
  return (
    <div className="SnellCalendar__loaderWrapper">
      <div className="SnellCalendar__loader" />
    </div>
  );
};

export default SnellCalendarLoader;
