import React, { Component } from 'react';
import { connect } from 'react-redux';

import { mapStateToProps } from '../../assets/scripts/redux.js';
import { redirectToLogout } from '../../utils/authApi.js';
import { getBaseUrl, translate as tl } from '../../utils/helpers.js';

import './AuthenticationScreen.css';

class AuthenticationScreen extends Component {
  constructor(props) {
    super(props);

    let status;
    if (props.status) {
      status = props.status;
    }

    this.state = {
      status: status ? status : 'login',
    };
  }

  componentDidMount() {
    this.props.location && this.setStatus(this.props.location);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setStatus(this.props.location);
    }
  }

  setStatus = (location) => {
    const searchParams = new URLSearchParams(location.search);
    const statusFromParams = searchParams.get('status');
    if (statusFromParams) {
      this.setState({ status: statusFromParams });
    } else {
      // If component rendered from /logout without params, use
      // status 'logging-out'by default
      const pathname = location.pathname;
      if (pathname && pathname === '/logout') {
        this.setState({ status: 'logging-out' });
      }
    }
  };

  handleLogout = () => {
    redirectToLogout();
  };

  render() {
    if (
      this.state.status === 'logged-out' &&
      this.props.authenticatedUser !== null
    ) {
      window.location.replace(`${getBaseUrl()}/logout?status=logout-error`);
    }

    if (this.state.status === 'logging-out') {
      if (this.props.authenticatedUser !== null) {
        // Session still active at Auth API => redirect to Auth API logout
        this.handleLogout();
      } else {
        // Session now active at Auth API => just show logout successful
        window.location.replace(`${getBaseUrl()}/logout?status=logged-out`);
      }
    }

    const getText = () => {
      switch (this.state.status) {
        case 'user-mismatch':
          return tl('user_mismatch');
        case 'logging-out':
          return tl('logging_out');
        case 'logged-out':
          return tl('logged_out');
        case 'logout-error':
          return tl('logout_error');
        case 'login':
        default:
          return tl('authenticating');
      }
    };

    return (
      <div className="SnellReservation">
        <div className="SnellReservationView intro">
          <div className="AuthenticationScreen__container">
            {['logging-out', 'login'].includes(this.state.status) && (
              <div className="AuthenticationScreen__loader"></div>
            )}
            <div className="AuthenticationScreen__text">{getText()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const CtdAuthenticationScreen = connect(mapStateToProps(['authenticatedUser']))(
  AuthenticationScreen,
);

export default CtdAuthenticationScreen;
