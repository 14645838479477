import moment from 'moment';
import fiLocale from 'moment/locale/fi';
import svLocale from 'moment/locale/sv';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import {
  getCurrentLanguage,
  translate as tl,
  translateAmnesisData as tla,
} from '../../utils/helpers.js';

import './SnellHealthQuestion.css';
import inputWarning from '../../assets/images/icon_input_warning.svg';

/* eslint-disable */
import SnellIconInputCross from '-!svg-react-loader?name=SnellIconTimetable!../../assets/images/icon_input_cross.svg';
import SnellIconInputCheck from '-!svg-react-loader?name=SnellIconInfo!../../assets/images/icon_input_check.svg';
/* eslint-enable */

const icons = {
  inputWarning,
};

class SnellHealthQuestion extends Component {
  constructor(props) {
    super(props);

    const lang = getCurrentLanguage();

    if (lang === 'fi') {
      moment.updateLocale('fi', fiLocale);
    } else if (lang === 'sv') {
      moment.updateLocale('sv', svLocale);
    }

    this.state = {
      valid: null,
      focus: false,
      toggleValue: null,
      selected: null,
      lang,
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.handleClick();
  };

  handleFocusBlur = (e) => {
    this.setState({
      focus: e.type === 'focus',
    });
  };

  toggleAnswer(questionKey, value) {
    this.setState({ toggleValue: value });
    if (typeof this.props.toggleAnswer === 'function') {
      this.props.toggleAnswer(questionKey, value);
    }
  }

  handleDateChange(value) {
    this.setState({ selected: value });
    this.props.handleInfoChange(value);
  }

  render() {
    const additionalClasses = this.props.className ? this.props.className : '';
    const hasFocus = this.state.focus ? 'SnellInput--focus' : '';

    const trueLabel =
      this.props.options !== undefined &&
      this.props.options.true !== undefined &&
      this.props.options.true !== null
        ? tla(this.props.options.true)
        : tl('Yes');

    const falseLabel =
      this.props.options !== undefined &&
      this.props.options.false !== undefined &&
      this.props.options.false !== null
        ? tla(this.props.options.false)
        : tl('No');

    const detailsRequiredFor =
      this.props.options !== undefined &&
      this.props.options.detailsRequiredFor !== undefined
        ? this.props.options.detailsRequiredFor
        : 'disabled';

    return (
      <div
        className={`SnellHealthQuestion ${
          this.props.hasError ? 'error' : null
        } ${hasFocus} ${additionalClasses}`}
      >
        <p className="SnellHealthQuestion__question">
          {tla(this.props.questionKey)}
          <img
            src={icons.inputWarning}
            alt=""
            className="SnellHealthQuestion__error-icon"
          />
        </p>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            this.toggleAnswer(this.props.questionKey, true);
          }}
          className={`SnellHealthQuestion__toggle ${
            this.state.toggleValue === true ? 'selected' : null
          }`}
        >
          <div className="SnellHealthQuestion__toggle-graph">
            <SnellIconInputCheck className="SnellHealthQuestion__toggle-icon" />
          </div>
          <div className="SnellHealthQuestion__toggle-text">{trueLabel}</div>
        </a>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            this.toggleAnswer(this.props.questionKey, false);
          }}
          className={`SnellHealthQuestion__toggle ${
            this.state.toggleValue === false ? 'selected' : null
          }`}
        >
          <div className="SnellHealthQuestion__toggle-graph">
            <SnellIconInputCross className="SnellHealthQuestion__toggle-icon" />
          </div>
          <div className="SnellHealthQuestion__toggle-text">{falseLabel}</div>
        </a>
        {this.state.toggleValue === detailsRequiredFor ? (
          <div className="SnellHealthQuestion__info-label">
            <p>{tla(this.props.infoKey)}</p>
            {this.props.options.isDate !== undefined &&
            this.props.options.isDate === true ? (
              <DatePicker
                selected={this.state.selected}
                onSelect={(date) => this.handleDateChange(date)}
                locale={this.state.lang}
              />
            ) : (
              <textarea
                name={this.props.infoKey}
                onChange={(e) => this.props.handleInfoChange(e.target.value)}
                className="SnellHealthQuestion__info-box"
              />
            )}
          </div>
        ) : (
          <div className="SnellHealthQuestion__info-label"></div>
        )}
      </div>
    );
  }
}

export default SnellHealthQuestion;
