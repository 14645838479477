import moment from 'moment';
import React from 'react';

function SnellTimeFrame(props) {
  const date = props.date;

  const hour = date.format('H');
  const endHour = moment(date)
    .add(1, 'hour')
    .format('H');
  let timeFrame;

  if (props.strongStart) {
    timeFrame = (
      <span className="SnellTimeFrame">
        <strong>{`${hour}.00`}</strong>
        {` - ${endHour}.00`}
      </span>
    );
  } else {
    timeFrame = (
      <span className="SnellTimeFrame">{`${hour}.00 - ${endHour}.00`}</span>
    );
  }

  return timeFrame;
}

export default SnellTimeFrame;
