import axios from 'axios';
import { endOfDay } from 'date-fns';
import { enGB, fi, sv, nl } from 'date-fns/locale';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import _ from 'lodash';
import moment from 'moment';

import amnesisLocalizationData from '../localization/amnesisForm';
import localizationData from '../localization/application';

const options = {
  language: "REACT_APP_LANGUAGE_PLACEHOLDER"
    ? "REACT_APP_LANGUAGE_PLACEHOLDER"
    : 'fi',
  apiEntryPoint: "REACT_APP_API_ENTRYPOINT_PLACEHOLDER",
  paytrailMerchantID: 16533,
  paytrailMerchantHash: '4pqc4kC4rrmULLdN5nAExAqidLHqZn',
};

function getCurrentLanguage() {
  let lang = document.documentElement.lang;
  lang = lang ? lang : options.language;
  return lang;
}

function getISOlanguage(language) {
  if (language === 'sv') {
    return 'sv_SE';
  } else if (language === 'en') {
    return 'en_GB';
  } else if (language === 'nl') {
    return 'nl_NL';
  } else {
    return 'fi';
  }
}

function getDateFnsLocale(language) {
  if (language === 'sv') {
    return sv;
  } else if (language === 'en') {
    return enGB;
  } else if (language === 'nl') {
    return nl;
  } else if (language === 'fi') {
    return fi;
  } else {
    return enGB;
  }
}

function getBaseUrl() {
  return "PUBLIC_URL_PLACEHOLDER";
}

function getAuthApiUrl() {
  return "REACT_APP_AUTH_API_URL_PLACEHOLDER";
}

function getAuthApiId() {
  return "REACT_APP_AUTH_API_ID_PLACEHOLDER";
}

function getAuthSessionTimeout() {
  return "REACT_APP_AUTH_SESSION_TIMEOUT_PLACEHOLDER";
}

function isAuthInUse() {
  return getAuthApiUrl() && getAuthApiUrl().length !== 0;
}

const daysOfTheWeek = {
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  fi: ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
  sv: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
  nl: ['Zon', 'Maa', 'Din', 'Woe', 'Don', 'Vri', 'Zat'],
};

/**
 * Formats a given date into a locale-specific string.
 *
 * @param {Date} dateObject - The date to be formatted.
 * @param {boolean} showYear - Indicates whether the year should be included in the formatted string.
 * @returns {string} The formatted date string in locale-specific formatting.
 *
 */
function getLocaleDate(dateObject, showYear) {
  if (!dateObject || typeof dateObject.getMonth !== 'function') {
    return dateObject;
  }

  const lang = getCurrentLanguage();
  let date = dateObject.toLocaleDateString(lang);

  if (!showYear) {
    // Apart from other locales, sv separator varies between dates with and without year. Requested by Swe Account manager.
    const separator =
      lang === 'sv'
        ? '/'
        : date.split('').find((char) => isNaN(Number(char))) || '.';

    const isMonthFirst = lang === 'en';

    let dateFormatParts = ['D', 'M'];
    if (isMonthFirst) {
      dateFormatParts = dateFormatParts.reverse();
    }
    const format = `${dateFormatParts[0]}${separator}${dateFormatParts[1]}${
      separator === '.' ? '.' : ''
    }`;

    date = moment(dateObject).format(format);
  }

  return date;
}

function formatMomentDate(dateObject, showYear) {
  if (typeof dateObject.format !== 'function') {
    dateObject = moment(dateObject);
  }

  const lang = getCurrentLanguage();
  const date = getLocaleDate(dateObject.toDate(), showYear);

  return `${daysOfTheWeek[lang][dateObject.format('d')]} ${date}`;
}

function formatDate(dateObject, year) {
  if (!dateObject || typeof dateObject.getMonth !== 'function') {
    return dateObject;
  }

  const lang = getCurrentLanguage();
  const date = getLocaleDate(dateObject, year);

  return `${daysOfTheWeek[lang][dateObject.getDay()]} ${date}`;
}

function formatDateAndTime(dateObject, year) {
  if (!dateObject || typeof dateObject.getMonth !== 'function') {
    return dateObject;
  }

  const date = getLocaleDate(dateObject, year);
  const time = moment(dateObject).format('HH:mm');

  return `${date} ${time}`;
}

function getDatesBetween(start, end) {
  const arr = [];
  const lastDay = endOfDay(new Date(end));
  for (
    const dt = new Date(start);
    dt <= lastDay;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
}

function translate(string) {
  const lang = getCurrentLanguage();

  if (localizationData[lang] && localizationData[lang][string]) {
    return localizationData[lang][string];
  }

  return string;
}

function translateAmnesisData(string) {
  const lang = getCurrentLanguage();

  if (amnesisLocalizationData[lang] && amnesisLocalizationData[lang][string]) {
    return amnesisLocalizationData[lang][string];
  }

  return string;
}

function reservationApi(httpVerb, endpoint, method, params = {}, data = null) {
  if (!httpVerb || !~['GET', 'POST'].indexOf(httpVerb)) {
    console.error('SnellApp, reservationApi: httpVerb must be GET OR POST.');
    return {};
  }
  if (!endpoint || typeof endpoint !== 'string') {
    console.error('SnellApp, reservationApi: endpoint must be a string.');
    return {};
  }

  if (!method || typeof method !== 'string') {
    console.error('SnellApp, reservationApi: method must be a string.');
    return {};
  }

  if (!params || typeof params !== 'object') {
    console.error('SnellApp, reservationApi: params must be an object.');
    return {};
  }

  if (data && typeof data !== 'object') {
    console.error('SnellApp, reservationApi: data must be an object.');
    return {};
  }

  const apiUrl = `${options.apiEntryPoint}/${endpoint}/${method}`;

  if (httpVerb === 'GET') {
    return axios.get(apiUrl, { params });
  } else {
    return axios.post(apiUrl, data, { params });
  }
}

function unixTime(dateObject) {
  let time;

  if (dateObject && typeof dateObject.getMonth === 'function') {
    time = dateObject;
  } else {
    time = new Date();
  }

  return (+time / 1000) | 0;
}

// Calculating age uses a year of 365.25 days (because of leap years)

const calculateAge = (birthDate, givenDate) =>
  Math.floor((givenDate - new Date(birthDate).getTime()) / 3.15576e10);

function getAgeFromFinnishSSN(ssn, today) {
  let dateString = '',
    year = '',
    month = '',
    day = '';

  const separator = ssn.substring(6, 7);

  if (separator === '+') {
    year = year.concat('18');
  } else if (separator === '-') {
    year = year.concat('19');
  } else if (separator === 'A') {
    year = year.concat('20');
  }

  year = year.concat(ssn.substring(4, 6));
  month = ssn.substring(2, 4);
  day = ssn.substring(0, 2);

  dateString = year
    .concat('-')
    .concat(month)
    .concat('-')
    .concat(day);
  return calculateAge(dateString, today);
}

/**
 * Calculates the age based on a Swedish Social Security Number (SSN).
 * The age can be calculated using either the exact birthdate or the "birth year age,"
 * which considers the age someone turns within the year of interest.
 *
 * @param {string} ssn - The Swedish Social Security Number (format: YYYYMMDD-XXXX or YYMMDD-XXXX).
 * @param {Date} dateOfInterest - The date at which the age should be calculated. In production, this
 *                                is always the current date. In the unit tests, this parameter allows testing
 *                                various edge cases.
 * @param {boolean} useBirthYearAge - If `true`, calculates the age based on the birth year
 *                                    (the age the person will turn within the year of interest).
 *                                    If `false`, calculates the age based on the exact birthdate.
 * @returns {number} The calculated age.
 */

function getAgeFromSwedishSSN(ssn, dateOfInterest, useBirthYearAge) {
  const shortForm = ssn.length === 11;
  let dateString = '',
    year = '',
    month = '',
    day = '';

  if (shortForm) {
    year = ssn.substring(0, 2);
    if (year <= 20) {
      year = `20${year}`;
    } else {
      year = `19${year}`;
    }
    month = ssn.substring(2, 4);
    day = ssn.substring(4, 6);
  } else {
    year = ssn.substring(0, 4);
    month = ssn.substring(4, 6);
    day = ssn.substring(6, 8);
  }

  if (day > 31) {
    day = day - 60;
    if (day < 10) {
      day = `0${day}`;
    }
  }

  dateString = year
    .concat('-')
    .concat(month)
    .concat('-')
    .concat(day);

  if (
    useBirthYearAge &&
    (useBirthYearAge === true || useBirthYearAge === 'true')
  ) {
    const yearOfInterest = dateOfInterest.getFullYear();
    const thirtyFirstDecember = new Date(yearOfInterest, 11, 31); // Month is 0-indexed, so 11 is December.
    return calculateAge(dateString, thirtyFirstDecember);
  }

  return calculateAge(dateString, dateOfInterest);
}

function isAllowedToBook(minimumAge, ssn, useBirthYearAge) {
  // TODO: Instead of options.language this should be read from the server
  const age =
    options.language === 'sv'
      ? getAgeFromSwedishSSN(ssn, new Date(), useBirthYearAge)
      : getAgeFromFinnishSSN(ssn, new Date());
  return ageOK(minimumAge, age);
}

function ageOK(minimumAge, patientAge) {
  if (typeof minimumAge !== 'number' || typeof patientAge !== 'number') {
    return false;
  }
  return patientAge >= minimumAge;
}

function validateFinnishSSN(ssn) {
  if (
    typeof ssn !== 'string' ||
    ssn.length !== 11 ||
    !~['+', '-', 'A'].indexOf(ssn[6])
  ) {
    return false;
  }

  const endings = '0123456789ABCDEFHJKLMNPRSTUVWXY';
  const index = +(ssn.substr(0, 6) + ssn.substr(7, 3)) % 31;

  return !isNaN(index) && endings[index] === ssn[10];
}

function validateSwedishSSN(ssn) {
  if (
    typeof ssn !== 'string' ||
    (ssn.length === 11 && !~['+', '-'].indexOf(ssn[6])) ||
    (ssn.length === 13 && !~['+', '-'].indexOf(ssn[8])) ||
    (ssn.length !== 11 && ssn.length !== 13)
  ) {
    return false;
  }

  const shortForm = ssn.length === 13 ? ssn.substring(2) : ssn;
  const sum = shortForm
    .split('')
    .filter((v, i) => i !== 6 && i !== 10)
    .map((v, i) => {
      if (i % 2 === 1) {
        return Number(v);
      } else {
        return String(2 * v)
          .split('')
          .reduce((a, b) => a + Number(b), 0);
      }
    })
    .reduce((a, b) => a + b, 0);
  const check = (10 - (sum % 10)) % 10;
  return String(check) === shortForm[10];
}

// TODO: Instead of options.language this should be read from the server
function ssnValidator() {
  if (options.language === 'sv') {
    return validateSwedishSSN;
  } else {
    return validateFinnishSSN;
  }
}

function validatePhoneNumber(phone, region) {
  // Validates number based on area code.
  const validNumber = isValidPhoneNumber(phone);

  // This validation works with numbers missing the area code & it's presumed
  // that the region is the same as the language of the app.
  let validRegionalNumber;

  if (region === 'SE') {
    validRegionalNumber = isValidPhoneNumber(phone, 'SE');
  } else if (region === 'NL') {
    validRegionalNumber = isValidPhoneNumber(phone, 'NL');
  } else if (region === 'BE') {
    validRegionalNumber = isValidPhoneNumber(phone, 'BE');
  } else {
    validRegionalNumber = isValidPhoneNumber(phone, 'FI');
  }

  // Number is valid if either validation passes.
  const isValidNumber = validNumber || validRegionalNumber;

  // Number should only contain numbers or +.
  const invalidChars = phone.split('').some((c) => !' +0123456789'.includes(c));

  return isValidNumber && !invalidChars;
}

function dateOfBirthFormat(customizations) {
  if (customizations && customizations.dateOfBirthFormat) {
    return customizations.dateOfBirthFormat;
  } else {
    return 'YYYY-MM-DD';
  }
}

function getPageTitle(customizations) {
  if (customizations && customizations.title) {
    return customizations.title;
  } else {
    return 'Hygga Reservation';
  }
}

function isCustomizedContract(customization, contract) {
  const contractSpecificInfoKeys =
    customization && 'contractSpecificInfo' in customization
      ? Object.keys(customization.contractSpecificInfo)
      : [];
  return contractSpecificInfoKeys.includes(contract);
}

const sortedReasons = (reasons) =>
  [...reasons].sort((a, b) => (a.label <= b.label ? -1 : 1));

function generateReasons(reasons, customization, contract) {
  let reasonOptions = sortedReasons(
    _.map(reasons, (r) => ({
      value: r.reason,
      label: translate(r.reason),
      online: r.online,
    })),
  );
  let selectedReason = null;

  if (isCustomizedContract(customization, contract)) {
    const contractSpecificReasons =
      customization.contractSpecificInfo[contract].reasons;
    reasonOptions = reasonOptions.filter(({ value }) =>
      contractSpecificReasons.includes(value),
    );
    if (reasonOptions.length === 1) {
      selectedReason = {
        canEdit: true,
        errorMsg: null,
        valid: true,
        value: reasonOptions,
      };
    }
  } else {
    reasonOptions = reasonOptions.filter(({ online }) => online);
  }

  return {
    reasonOptions,
    ...(selectedReason !== null ? { reasons: selectedReason } : {}),
  };
}

function getHeaderTitle(
  isEditing,
  customization,
  contract,
  advanceOnly,
  progress,
) {
  if (isEditing === true) {
    return translate('Choose new time');
  }

  // Return title for check-in
  if (progress && progress.step === 6) {
    return progress.headerTitle;
  }

  if (isCustomizedContract(customization, contract)) {
    const lang = getCurrentLanguage();
    const contractSpecificTitles =
      customization.contractSpecificInfo[contract].title;
    if (contractSpecificTitles[lang]) {
      return contractSpecificTitles[lang];
    }
  }

  if (customization && customization.customHeaderTitle) {
    return customization.customHeaderTitle;
  }

  if (advanceOnly === true) {
    translate('Advance reservation times');
  }

  if (progress && progress.headerTitle) {
    return progress.headerTitle;
  }

  return translate('Reservation');
}

/* Exports */
export {
  formatMomentDate,
  formatDate,
  formatDateAndTime,
  translate,
  translateAmnesisData,
  reservationApi,
  options,
  unixTime,
  getBaseUrl,
  getAuthApiUrl,
  getAuthApiId,
  getAuthSessionTimeout,
  getHeaderTitle,
  isAuthInUse,
  isCustomizedContract,
  getCurrentLanguage,
  getISOlanguage,
  getDateFnsLocale,
  getDatesBetween,
  ssnValidator,
  validatePhoneNumber,
  dateOfBirthFormat,
  calculateAge,
  getAgeFromFinnishSSN,
  getAgeFromSwedishSSN,
  ageOK,
  isAllowedToBook,
  getPageTitle,
  generateReasons,
  sortedReasons,
};
