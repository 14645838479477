import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import noSlots from '../../assets/images/icon_no_slots.svg';
import sunday from '../../assets/images/icon_sunday.svg';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import SlotInfoPanel from '../../components/SlotInfoPanel/SlotInfoPanel';
import { formatMomentDate, translate as tl } from '../../utils/helpers.js';

import logo from './logo.svg';
import CtdSnellReservationSlot from './SnellReservationSlot';

import './SnellReservation.css';
import '../../components/SlotInfoPanel/SlotInfoPanel.css';

const icons = {
  sunday,
  noSlots,
  logo,
};

const queryString = require('query-string');

class SnellReservationDay extends Component {
  render() {
    const today = this.props.today;
    let advanceSlotNotificationShown = false;

    let titleDateString = '',
      isToday = '',
      isClosed = true,
      isPast = '',
      emptyTag = '',
      showLogoOnClosedDays = false;
    const hourStep = 44.5;

    const queryParams = queryString.parse(window.location.search);

    const daysClosed = this.props.customization.daysClosed
      ? this.props.customization.daysClosed
      : [];

    if (this.props.day.length) {
      const thisDay = moment(this.props.day[0].startTime.dateObject);
      titleDateString = formatMomentDate(thisDay);

      isToday =
        moment(today).format('YYYY-MM-DD') ===
          moment(thisDay).format('YYYY-MM-DD') &&
        'SnellReservationDay--isToday';

      isClosed =
        daysClosed.includes(Number(moment(thisDay).format('E'))) &&
        this.props.day.map((d) => d.fake).reduce((a, b) => a && b, true);

      isPast =
        moment(today).format('YYYY-MM-DD') >
          moment(thisDay).format('YYYY-MM-DD') && 'SnellReservationDay--isPast';

      showLogoOnClosedDays = this.props.customization.showLogoOnClosedDays;
    }
    const isEmpty =
      this.props.day.length === 1 && this.props.day[0].fake === true;
    emptyTag = isEmpty ? 'SnellReservationDay--noTimesAvailable' : '';

    let onlyAdvanceSlots;
    if (queryParams.type && queryParams.type === 'advance') {
      onlyAdvanceSlots = true;
    } else {
      onlyAdvanceSlots = false;
    }

    const daysAdvanceSlots = _.filter(this.props.day, (row) => {
      return row.fake !== true && row.priceList.pricing === 'ADVANCE';
    });

    const touchScreenUserWarning = '';

    const slotInfoPanel = () => {
      if (!isMobile) {
        return (
          <SlotInfoPanel
            className="SlotInfoPanel"
            customization={this.props.customization}
          />
        );
      } else if (isMobile) {
        return null;
      } else {
        return (
          <SlotInfoPanel
            className="SlotInfoPanel"
            customization={this.props.customization}
          />
        );
      }
    };

    return (
      <div className={`SnellReservationDay ${isToday} ${emptyTag} ${isPast}`}>
        <h2 className="SnellReservationDay__title">{titleDateString}</h2>
        <ul className="SnellReservationDay__slots">
          {this.props.day.map((slot, i, { length }) => {
            const steps = slot.startHour - this.props.firstHour;
            let thisIsTheLastSlot = false;
            if (i + 1 === length) {
              thisIsTheLastSlot = true;
            }

            let message;
            // if there are customized message contents, we'll use those. Otherwise the default values are used
            if (
              this.props.customization.customNoSlotsText &&
              this.props.customization.customClosedText
            ) {
              message =
                isClosed && slot.id
                  ? this.props.customization.customClosedText
                  : this.props.customization.customNoSlotsText;
            } else {
              message =
                isClosed && slot.id
                  ? 'The clinic is closed'
                  : 'No times available';
            }

            if (
              showLogoOnClosedDays &&
              this.props.day.length === 1 &&
              this.props.day[0].fake === true
            ) {
              return (
                <li className="SnellReservationDay__sunday" key={slot.id}>
                  <img src={icons.logo} alt="" />
                  <p>{tl(message)}</p>
                  {slotInfoPanel()}
                </li>
              );
            } else if (
              this.props.day.length === 1 &&
              this.props.day[0].fake === true
            ) {
              return (
                <li className="SnellReservationDay__sunday" key={slot.id}>
                  <img src={icons.sunday} alt="" />
                  <p>{tl(message)}</p>
                  {slotInfoPanel()}
                </li>
              );
            } else if (
              slot.id &&
              slot.fake !== true &&
              onlyAdvanceSlots === false
            ) {
              const className = `SnellReservationSlot--hour${slot.startHour}`;
              return (
                <CtdSnellReservationSlot
                  currentReservation={this.props.currentReservation}
                  key={slot.id}
                  slot={slot}
                  match={this.props.match}
                  advanceSlot={slot.priceList.pricing === 'ADVANCE'}
                  className={className}
                  thisIsTheLastSlot={thisIsTheLastSlot}
                  touchUser={this.props.touchUser}
                  topMargin={i === 0 ? steps * hourStep : 0}
                />
              );
            } else if (
              slot.id &&
              slot.fake !== true &&
              onlyAdvanceSlots === true &&
              slot.priceList.pricing === 'ADVANCE'
            ) {
              const className = `SnellReservationSlot--hour${slot.startHour}`;
              return (
                <CtdSnellReservationSlot
                  currentReservation={this.props.currentReservation}
                  key={slot.id}
                  slot={slot}
                  advanceSlot={slot.priceList.pricing === 'ADVANCE'}
                  match={this.props.match}
                  className={className}
                  thisIsTheLastSlot={thisIsTheLastSlot}
                  topMargin={i === 0 ? steps * hourStep : 0}
                />
              );
            } else if (
              this.props.day.length > 0 &&
              daysAdvanceSlots.length === 0 &&
              onlyAdvanceSlots === true &&
              !advanceSlotNotificationShown
            ) {
              advanceSlotNotificationShown = true;
              return (
                <li className="SnellReservationDay__sunday" key={slot.id}>
                  <img src={icons.noSlots} alt="" />
                  <p>{tl('No advance pricing slots available')}</p>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
        <div>{touchScreenUserWarning}</div>
      </div>
    );
  }
}

const CtdSnellReservationDay = connect(mapStateToProps(['customization']))(
  SnellReservationDay,
);

export default CtdSnellReservationDay;
