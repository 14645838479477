import axios from 'axios';

import { setAuthenticatedUser } from '../actions/index';

import { getAuthApiId, getAuthApiUrl } from './helpers';

export function authenticateUser(props, redirectToAuth, location) {
  axios({
    method: 'GET',
    url: `${getAuthApiUrl()}/whoami`,
    withCredentials: true,
  })
    .then((response) => {
      if (response.data.user) {
        props.dispatch(setAuthenticatedUser(response.data.user));
      } else if (redirectToAuth) {
        redirectToAuthentication(location);
      }
    })
    .catch((error) => {
      if (error.response.data.message === 'Unauthorized' && redirectToAuth) {
        redirectToAuthentication(location);
      } else if (error.response.data.message === 'Session timeout') {
        redirectToLogout();
      }
    });
}

function redirectToAuthentication(location) {
  let redirectUrl = `${getAuthApiUrl()}/login?clientId=${getAuthApiId()}`;

  if (location) {
    redirectUrl = redirectUrl.concat(`&location=${location}`);
  }

  window.location.replace(redirectUrl);
}

export function redirectToLogout() {
  const redirectUrl = `${getAuthApiUrl()}/logout?clientId=${getAuthApiId()}`;
  window.location.replace(redirectUrl);
}
