var React = require('react');

function SnellIconTimetable (props) {
    return React.createElement("svg",props,[React.createElement("defs",{"key":0},React.createElement("style",null,".a{fill:#f00025;fill-rule:evenodd;}")),React.createElement("path",{"className":"a","d":"M7.576,0V7.576H0V10.1H7.576v7.576H10.1V10.1h7.576V7.576H10.1V0Z","transform":"translate(-14499.399 -1592.9) rotate(45)","key":1})]);
}

SnellIconTimetable.defaultProps = {"viewBox":"-14511.899 -1592.9 25 25"};

module.exports = SnellIconTimetable;

SnellIconTimetable.default = SnellIconTimetable;
