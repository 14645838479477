import getISOWeek from 'date-fns/getISOWeek';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Route } from 'react-router-dom';

import {
  addWeekAction,
  setFirstWeekAction,
  setDailyDataAction,
} from '../../actions/index';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import { authenticateUser } from '../../utils/authApi.js';
import {
  isAuthInUse,
  getPageTitle,
  translate as tl,
  reservationApi,
} from '../../utils/helpers.js';
import AuthenticationScreen from '../AuthenticationScreen/AuthenticationScreen';
import CustomerLogin from '../CustomerLogin/CustomerLogin.js';
import Intro from '../Intro/Intro.js';
import SnellAmnesis from '../SnellAmnesis/SnellAmnesis';
import SnellCheckin from '../SnellCheckin/SnellCheckin.js';
import SnellComplete from '../SnellComplete/SnellComplete';
import SnellConfirm from '../SnellConfirm/SnellConfirm';
import SnellConfirmChange from '../SnellConfirmChange/SnellConfirmChange';
import SnellHeader from '../SnellHeader/SnellHeader';
import CtdSnellMoveReservation from '../SnellMoveReservation/SnellMoveReservation.js';
import SnellPayment from '../SnellPayment/SnellPayment';

import CtdSnellReservationWeek from './SnellReservationWeek';

import './SnellReservation.css';

const queryString = require('query-string');

/**
 * This is main component which handles app routing.
 * This component is also responsible for loading the data for
 * calendar view.
 */

class SnellReservation extends Component {
  constructor(props) {
    super(props);

    let contract;

    this.state = {
      fakeID: 0,
      currentWeekNumber: 0,
      currentDay: moment().utcOffset(0),
      weekPart: null,
      contract,
      startDays: null,
    };
  }

  // Load data for 3 days (used in mobile)

  fetchDays = (startDay, changeCurrentDay, contract, weekPart) => {
    if (changeCurrentDay && this.state.currentDay !== startDay) {
      this.setState({ currentDay: startDay });
    }

    if (this.props.dailyData[startDay.format('X')]) {
      return;
    }

    // Fake ID used if we need to buffer day beginning with extra slots
    const fakeID = this.state.fakeID;

    const rawStartTime = moment(startDay);
    rawStartTime.subtract(1, 'days');
    const startTime = rawStartTime.format('X');

    const rawEndTime = moment(rawStartTime).add(3, 'days');
    rawEndTime.add(1, 'days');
    const endTime = rawEndTime.format('X');

    const params = this.generateParams(startTime, endTime, contract);

    reservationApi(
      'GET',
      'reservationslot',
      'getReservationSlotsBetween',
      params,
    )
      .then((response) => {
        const days = this.transformSlotData(
          'days',
          response.data,
          rawStartTime,
          fakeID,
        );

        if (days.length) {
          this.props.dispatch(setDailyDataAction(days, startDay.format('X')));
        }

        // We can set the latest fakeID to state, as we've stopped incrementing it.
        if (this.state.fakeID !== fakeID) {
          this.setState({ fakeID });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Load data for full week

  fetchWeek = (weekNumber, changeCurrentWeek, contract, weekPart) => {
    if (changeCurrentWeek && this.state.currentWeekNumber !== weekNumber) {
      this.setState({ currentWeekNumber: weekNumber });
    }

    if (this.props.weeklyData[weekNumber]) {
      return;
    }

    // Monday, 00:00, this week
    const rawStartTime = moment()
      .isoWeek(weekNumber)
      .startOf('isoWeek');
    rawStartTime.subtract(1, 'days');
    const startTime = rawStartTime.format('X');
    // Sunday 23:59, this week
    const rawEndTime = moment()
      .isoWeek(weekNumber)
      .endOf('isoWeek');
    rawEndTime.add(1, 'days');
    const endTime = rawEndTime.format('X');
    // Fake ID used if we need to buffer day beginning with extra slots
    const fakeID = this.state.fakeID;

    const params = this.generateParams(startTime, endTime, contract);

    reservationApi(
      'GET',
      'reservationslot',
      'getReservationSlotsBetween',
      params,
    )
      .then((response) => {
        const week = this.transformSlotData(
          'week',
          response.data,
          rawStartTime,
          fakeID,
        );

        // this.switchWeekIfNoSlots(slotData, weekNumber, contract);

        if (week.length) {
          this.props.dispatch(addWeekAction(week, weekNumber));
        }

        // We can set the latest fakeID to state, as we've stopped incrementing it.
        if (this.state.fakeID !== fakeID) {
          this.setState({ fakeID });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Gets raw slot data and generates array filled with slots and grouped by days

  transformSlotData(type, rawData, rawStartTime, fakeID) {
    let days;

    // For mobile, we show 3 days and for desktop 7 days
    if (type === 'days') {
      days = Array.from({ length: 3 }, (days) => []);
    } else {
      days = Array.from({ length: 7 }, (days) => []);
    }

    // Add unix timestamp to make it easier to sort slot by date
    rawData = _.map(rawData, (slot) => {
      return Object.assign(slot, {
        startTimestamp: moment(slot.startTime.date).format('X') * 1,
      });
    });

    // We need to sort the slots by time as the API does not do it for us.
    const slotData = _.sortBy(rawData, 'startTimestamp');

    _.each(days, (value, key) => {
      let daysSlots = [];
      // +1 because our search is wider to ensure functionality on different timezones
      const loopDay = rawStartTime.clone().add(key + 1, 'days');

      // Get current day's slots.
      daysSlots = _.filter(slotData, (slot) => {
        const slotStart = moment(slot.startTime.date);
        return slotStart.isSame(loopDay, 'day');
      });

      if (!daysSlots.length || daysSlots.length === 0) {
        // If day has no slots, create one "fake" slot to make it render well
        const startOfDay = loopDay.startOf('day');
        days[key].push({
          id: `fakeID${fakeID++}`,
          startTime: { dateObject: startOfDay },
          fake: true,
        });
      } else {
        // Day has slots, place them to data collection
        days[key] = _.map(daysSlots, (slot) => {
          slot.startTime.dateObject = moment(slot.startTime.date);
          slot.startHour = moment(slot.startTime.date).format('H'); // Hour, 1-23
          slot.fake = false;
          return slot;
        });
      }
    });

    return days;
  }

  generateParams(startTime, endTime, contract) {
    const queryParams = queryString.parse(window.location.search);

    const reservationParams = {
      startTime,
      endTime,
    };

    // Use contract parameter if it's stored in state or in the URL param
    if (contract) {
      reservationParams.contract = contract;
    } else if (queryParams.contract) {
      reservationParams.contract = queryParams.contract;
    }
    return reservationParams;
  }

  switchWeekIfNoSlots(slotData, currentWeek, currentContract) {
    const queryParams = queryString.parse(window.location.search);
    let slotsAvailableThisWeek = 0;

    if (queryParams.type && queryParams.type === 'advance') {
      slotsAvailableThisWeek = _.filter(slotData, (slot) => {
        return slot.priceList.pricing === 'ADVANCE';
      });
    } else {
      slotsAvailableThisWeek = _.filter(slotData, (slot) => {
        return slot.reservationPossible === true;
      });
    }

    if (slotsAvailableThisWeek.length === 0) {
      reservationApi(
        'GET',
        'reservationslot',
        'getFirstAvailableSlot',
        queryParams.type && queryParams.type === 'advance'
          ? { onlyAdvanceSlots: true }
          : {},
      )
        .then((response) => {
          if (!response.data.noSlots) {
            const firstWeekWithSlots = moment(
              response.data.startTime.date,
            ).isoWeek();
            if (firstWeekWithSlots > 0 && firstWeekWithSlots !== currentWeek) {
              this.fetchWeek(firstWeekWithSlots, true, currentContract);
              this.props.dispatch(setFirstWeekAction(firstWeekWithSlots));
            }
          }
        })
        .catch((error) => {});
    }
  }

  getCurrentContract() {
    let contract;

    const queryParams = queryString.parse(window.location.search);

    // Contracts
    if (queryParams.contract) {
      contract = queryParams.contract;
    } else {
      contract = null;
    }

    return contract;
  }

  getAdvanceOnlyStatus() {
    let isAdvanceOnly;
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.type && queryParams.type === 'advance') {
      isAdvanceOnly = true;
    } else {
      isAdvanceOnly = false;
    }
    return isAdvanceOnly;
  }

  renderHeader() {
    return (
      <header>
        <MediaQuery maxWidth={799}>
          <SnellHeader
            handleFetchData={this.fetchDays}
            isMobile={true}
            contract={this.getCurrentContract()}
            match={this.props.match}
            firstWeekNumber={getISOWeek(this.props.today)}
            currentWeekNumber={this.state.currentWeekNumber}
          />
        </MediaQuery>
        <MediaQuery minWidth={800}>
          <SnellHeader
            handleFetchData={this.fetchWeek}
            isMobile={false}
            contract={this.getCurrentContract()}
            match={this.props.match}
            firstWeekNumber={getISOWeek(this.props.today)}
            currentWeekNumber={this.state.currentWeekNumber}
          />
        </MediaQuery>
      </header>
    );
  }

  render() {
    window.top.document.title = getPageTitle(this.props.customization);
    if (
      this.props.customer === null &&
      // Do not show login when customer is editing an existing reservation
      !window.location.pathname.includes(tl('change-reservation')) &&
      !window.location.pathname.includes(tl('confirm-change')) &&
      !window.location.pathname.includes(tl('confirmed'))
    ) {
      return <CustomerLogin />;
    }

    return (
      <div className="SnellReservation">
        {this.props.modalToggled === false ? this.renderHeader() : null}
        <CtdSnellReservationView
          today={this.props.today}
          match={this.props.match}
          location={this.props.location}
          handleMobileFetchData={this.fetchDays}
          currentDay={this.state.currentDay}
          currentWeekNumber={this.state.currentWeekNumber}
          contract={this.getCurrentContract()}
          advanceOnly={this.getAdvanceOnlyStatus()}
          fetchWeek={this.fetchWeek}
          fetchDays={this.fetchDays}
          handleFetchData={this.fetchWeek}
          weekNumber={this.state.currentWeekNumber}
          firstWeekNumber={getISOWeek(this.props.today)}
        />
      </div>
    );
  }
}

class SnellReservationView extends Component {
  render() {
    let dailyData, currentDays, weeklyData, currentWeek;

    if (this.props.currentWeekNumber) {
      currentWeek = this.props.weeklyData[this.props.currentWeekNumber]; // from redux
      weeklyData = this.props.weeklyData && currentWeek;
    } else {
      currentDays = this.props.weeklyData[this.props.currentWeekNumber]; // from redux
      dailyData = this.props.weeklyData && currentDays;
    }

    if (isAuthInUse() && this.props.authenticatedUser === null) {
      // Authentication for moving a reservation is handled under the change-reservation route.
      if (
        (!window.location.pathname.includes(tl('change-reservation')) &&
          this.props.customization.introEnabled &&
          this.props.introStatus) ||
        !this.props.customization.introEnabled
      ) {
        // If accessing a logout-related page, only check if session is open
        // but do not redirect to authentication if session is missing
        if (window.location.pathname.includes('logout')) {
          authenticateUser(this.props, false);
        } else {
          authenticateUser(this.props, true);
          return <AuthenticationScreen location={this.props.location} />;
        }
      }
    }

    return (
      <main
        className={`SnellReservationView ${
          this.props.introStatus ? '' : 'intro'
        }`}
        id="main-content"
      >
        <Route
          exact
          path={`/`}
          render={(props) => {
            if (this.props.introStatus) {
              return (
                <div>
                  <MediaQuery maxWidth={799}>
                    <CtdSnellReservationWeek
                      handleFetchData={this.props.fetchDays}
                      data={dailyData}
                      currentDay={this.props.currentDay}
                      fetchDays={this.props.fetchDays}
                      currentWeekNumber={this.props.currentWeekNumber}
                      today={this.props.today}
                      match={this.props.match}
                      contract={this.props.contract}
                      advanceOnly={this.props.advanceOnly}
                      weekPart={this.props.weekPart}
                      numDays={3}
                      isMobile={true}
                      currentContract={props.match.params.contract}
                    />
                  </MediaQuery>

                  <MediaQuery minWidth={800}>
                    <CtdSnellReservationWeek
                      handleFetchData={this.props.fetchWeek}
                      data={weeklyData}
                      currentDay={this.props.currentDay}
                      fetchWeek={this.props.fetchWeek}
                      currentWeekNumber={this.props.currentWeekNumber}
                      today={this.props.today}
                      match={this.props.match}
                      contract={this.props.contract}
                      advanceOnly={this.props.advanceOnly}
                      weekPart={this.props.weekPart}
                      numDays={7}
                      isMobile={false}
                      currentContract={props.match.params.contract}
                      weekNumber={this.props.currentWeekNumber}
                      firstWeekNumber={getISOWeek(this.props.today)}
                    />
                  </MediaQuery>
                </div>
              );
            } else {
              return <Intro />;
            }
          }}
        />
        <Route
          path={`${this.props.match.url}${tl('confirm')}`}
          component={SnellConfirm}
        />
        <Route
          path={`${this.props.match.url}${tl('payment')}/:code?`}
          component={SnellPayment}
        />
        <Route
          path={`${this.props.match.url}${tl('confirmed')}/:code?`}
          component={SnellComplete}
        />
        <Route
          path={`${this.props.match.url}${tl('preinformation')}/:code?`}
          component={SnellAmnesis}
        />
        <Route
          path={`${this.props.match.url}checkin/:code?`}
          component={SnellCheckin}
        />
        <Route
          path={`${this.props.match.url}${tl('change-reservation')}/:code?`}
          render={(props) => {
            if (isAuthInUse() && this.props.authenticatedUser === null) {
              // Authenticate when moving a reservation
              authenticateUser(this.props, true, props.match.url);
              return <AuthenticationScreen location={this.props.location} />;
            } else {
              return (
                <div>
                  <MediaQuery maxWidth={799}>
                    <CtdSnellMoveReservation
                      fetchDays={this.props.fetchDays}
                      fetchWeek={this.props.fetchWeek}
                      handleMobileFetchData={this.props.fetchDays}
                      week={this.props.week}
                      today={this.props.today}
                      match={this.props.match}
                      currentWeekNumber={this.props.currentWeekNumber}
                      currentCode={props.match.params.code}
                      contract={this.props.contract}
                      weekNumber={this.props.weekNumber}
                      firstWeekNumber={getISOWeek(this.props.today)}
                      isMobile={true}
                      advanceOnly={this.props.advanceOnly}
                      currentDay={this.props.currentDay}
                      handleFetchData={this.props.fetchDays}
                    />
                  </MediaQuery>
                  <MediaQuery minWidth={800}>
                    <CtdSnellMoveReservation
                      handleMobileFetchData={this.props.fetchDays}
                      fetchDays={this.props.fetchDays}
                      fetchWeek={this.props.fetchWeek}
                      week={this.props.week}
                      today={this.props.today}
                      match={this.props.match}
                      currentWeekNumber={this.props.currentWeekNumber}
                      currentCode={props.match.params.code}
                      contract={this.props.contract}
                      weekNumber={this.props.weekNumber}
                      firstWeekNumber={getISOWeek(this.props.today)}
                      numDays={7}
                      offset={0}
                      isMobile={false}
                      advanceOnly={this.props.advanceOnly}
                      currentDay={this.props.currentDay}
                      handleFetchData={this.props.fetchWeek}
                    />
                  </MediaQuery>
                </div>
              );
            }
          }}
        />
        <Route
          path={`${this.props.match.url}${tl('confirm-change')}/:code?`}
          component={SnellConfirmChange}
        />
        <Route
          exact
          path={`${this.props.match.url}logout`}
          render={() => {
            return <AuthenticationScreen location={this.props.location} />;
          }}
        />
      </main>
    );
  }
}

// Redux Connected Components
const CtdSnellReservation = connect(
  mapStateToProps([
    'contract',
    'modalToggled',
    'weeklyData',
    'dailyData',
    'customer',
    'authenticatedUser',
    'customization',
  ]),
)(SnellReservation);

const CtdSnellReservationView = connect(
  mapStateToProps([
    'contract',
    'weeklyData',
    'dailyData',
    'introStatus',
    'customization',
    'authenticatedUser',
  ]),
)(SnellReservationView);

export default CtdSnellReservation;
