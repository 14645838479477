import React from 'react';

import './Disclaimer.css';
import infoIcon from '../../assets/images/icon_information.svg';

const Disclaimer = (props) => {
  const rawHtml = props.content.content.join('');
  return (
    <section className="Disclaimer">
      <img className="Disclaimer-icon" src={infoIcon} alt="" />
      <span
        className="Disclaimer-content"
        dangerouslySetInnerHTML={{ __html: rawHtml }}
      ></span>
    </section>
  );
};

export default Disclaimer;
