import moment from 'moment';

function isLimitedSlot(slot, slotRestrictionsOfInterest) {
  if (slotRestrictionsOfInterest === undefined) {
    // If customization isn't defined, default to old logic

    return (
      (slot.noNurse || slot.noHygienist || slot.noDoctor) &&
      (!slot.noNurse || !slot.noHygienist || !slot.noDoctor)
    );
  } else if (slotRestrictionsOfInterest.length === 0) {
    // If the customization is defined, but empty, there are no slot restrictions of interest
    // - this means that no slot is limited
    return false;
  } else {
    const restrictionCountOfSlot = getSlotRestrictionOfInterestCount(
      slot,
      slotRestrictionsOfInterest,
    );

    if (restrictionCountOfSlot > 0) {
      // Slot has at least one restriction of interest, so availability is shown as limited
      return true;
    }
  }
  return false;
}

function isAvailableSlot(slot, slotRestrictionsOfInterest) {
  if (
    slotRestrictionsOfInterest === undefined ||
    slotRestrictionsOfInterest.length === 0
  ) {
    // If customization isn't defined, default to old logic
    return !(slot.noNurse && slot.noHygienist && slot.noDoctor);
  } else {
    const restrictionCountOfSlot = getSlotRestrictionOfInterestCount(
      slot,
      slotRestrictionsOfInterest,
    );

    if (restrictionCountOfSlot === slotRestrictionsOfInterest.length) {
      // Slot has all the restrictions of interest, so no treatments are available
      return false;
    }
  }
  return true;
}

function getSlotRestrictionOfInterestCount(slot, slotRestrictionsOfInterest) {
  let restrictionCountOfSlot = 0;

  for (const restriction of slotRestrictionsOfInterest) {
    if (slot[`${restriction}`] === true) {
      // If a restriction of interest if found on the slot, increase counter
      restrictionCountOfSlot++;
    }
  }
  return restrictionCountOfSlot;
}

/**
 * Processes an array of ReservationSlots into
 * an object with days in 'yyyy-MM-DD' format as keys. Days that have unlimited slots
 * will have 'unlimited' field as true.
 * @param {ReservationSlot[]} slots
 * @param {string[]} slotRestrictionsOfInterest
 * @returns {['yyyy-MM-DD']: {unlimited?: true}}
 */
function divideSlotLimitationsByDate(slots, slotRestrictionsOfInterest) {
  const daysWithSlotLimitations = slots.reduce((allDays, slot) => {
    if (!slot.reservationPossible || !slot?.startTime?.date) {
      return allDays;
    }

    const availableSlot = isAvailableSlot(slot, slotRestrictionsOfInterest);

    if (!availableSlot) {
      return allDays;
    }

    const limitedSlot = isLimitedSlot(slot, slotRestrictionsOfInterest);
    const key = moment(slot.startTime.date).format('yyyy-MM-DD');
    return {
      ...allDays,
      [key]: {
        ...(allDays[key] ? allDays[key] : {}),
        ...(!limitedSlot ? { unlimited: true } : {}),
      },
    };
  }, {});

  return daysWithSlotLimitations;
}

/**
 * Divides days with slot limitations into two groups: limited and unlimited. Dates which don't occur
 * in daysWithLimitations are put under disabled key.
 * @param {Date[]} dayPeriod Date period which is assessed
 * @param {['yyyy-MM-DD']: {unlimited?: true}} daysWithLimitations Days with available slots
 * @returns {disabledDays: Date[], unlimitedDays: Date[], limitedDays: Date[]}
 */
function divideDaysWithLimitations(dayPeriod, daysWithLimitations) {
  const unlimitedDays = Object.entries(daysWithLimitations)
    .filter(([_, limitations]) => limitations.unlimited)
    .map(([unlimited]) => unlimited);

  const daysWithLimitationsKeys = Object.keys(daysWithLimitations);

  const limitedDays = daysWithLimitationsKeys.filter(
    (day) => !unlimitedDays.includes(day),
  );

  const disabledDays = dayPeriod
    .map((day) => moment(day).format('yyyy-MM-DD'))
    .filter((day) => !daysWithLimitationsKeys.includes(day));

  return { unlimitedDays, limitedDays, disabledDays };
}

/* Exports */
export {
  isLimitedSlot,
  isAvailableSlot,
  divideSlotLimitationsByDate,
  divideDaysWithLimitations,
};
