import React, { Component } from 'react';

import { getCurrentLanguage } from '../../utils/helpers.js';

class SnellCheckbox extends Component {
  render() {
    const lang = getCurrentLanguage();

    if (lang === 'fi') {
      return (
        <div>
          <p>Maksupalvelutarjoaja</p>

          <p>
            Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii
            Paytrail Oyj (2122839-7) yhteistyössä suomalaisten pankkien ja
            luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana
            tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle.
            Paytrail Oyj:llä on maksulaitoksen toimilupa. Reklamaatiotapauksissa
            pyydämme ottamaan ensisijaisesti yhteyttä tuotteen toimittajaan.
          </p>

          <p>
            Paytrail Oyj, y-tunnus: 2122839-7
            <br />
            Innova 2<br />
            Lutakonaukio 7<br />
            40100 Jyväskylä
            <br />
            Puhelin: 0207 181830
          </p>
        </div>
      );
    } else if (lang === 'sv') {
      return (
        <div>
          <p>Betalningsförmedlare</p>

          <p>
            Betalningsförmedlingstjänsten utförs och som leverantör står
            Paytrail Oyj(2122839-7) i samarbete med banker och kreditinstitut.
            Betalningar med Visa Electron eller MasterCard-kort syns Paytrail
            Oyj som mottagare i korträkningen och även förmedlar betalningen
            till köpmannen. Paytrail Oyj har verksamhetstillstånd för
            betalningsinstitut beviljat av Finansinspektionen. Vid klagomål ber
            vi er i första hand vara i kontakt med nätbutiken.
          </p>

          <p>
            Paytrail Oyj
            <br />
            FO-nummer: 2122839-7
            <br />
            Innova 2<br />
            Lutakonaukio 7 40100 Jyväskylä
            <br />
            Telefon: 0207 181830
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p>Payment service provider</p>
          <p></p>
          Paytrail Oyj (2122839-7) acts as an implementer of the payment
          handling service and as a Payment Service Provider. Paytrail Oyj will
          be shown as the recipient in the invoice and Paytrail Oyj will forward
          the payment to the merchant. Paytrail Oyj is an authorized Payment
          Institution. For reclamations, please contact the website you made
          your payment to.
          <p>
            Paytrail Oyj, business ID: 2122839-7
            <br />
            Innova 2<br />
            Lutakonaukio 7<br />
            40100 Jyväskylä
            <br />
            Phone: +358 207 181830
          </p>
        </div>
      );
    }
  }
}

export default SnellCheckbox;
