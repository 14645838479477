import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setIntroStatus } from '../../actions/index';
import { mapStateToProps } from '../../assets/scripts/redux.js';

import InfoPage from './InfoPage.js';
import Questionnaire from './Questionnaire.js';

import './Intro.css';
import logo from '../../assets/images/logo_intro.svg';

class Intro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: undefined,
    };
  }

  finish() {
    this.props.dispatch(setIntroStatus(true));
    this.setState({
      page: undefined,
    });
  }

  nextFrom(page) {
    const { intro } = this.props.customization;
    if (page.next) {
      this.setState({
        page: page.next,
      });
    } else if (intro.last === page.id) {
      this.finish();
    }
  }

  nextFromQuestionnaire(page, success) {
    const { intro } = this.props.customization;
    if (intro.last === page.id && success) {
      this.finish();
    } else {
      const target = success ? page.onSuccess : page.onFail;
      this.setState({
        page: target,
      });
    }
  }

  renderPage(page, isFirst, isLast) {
    if (page.type === 'info') {
      return (
        <InfoPage
          key={page.id}
          content={page.content}
          onClick={(e) => {
            e.preventDefault();
            this.nextFrom(page);
          }}
          allowNext={isLast || page.next}
          customization={this.props.customization}
        />
      );
    } else if (page.type === 'questionnaire') {
      return (
        <Questionnaire
          key={page.id}
          content={page.content}
          onSuccess={() => this.nextFromQuestionnaire(page, true)}
          onFail={() => this.nextFromQuestionnaire(page, false)}
        />
      );
    } else {
      return <p>Error</p>;
    }
  }

  currentPage() {
    if (this.state.page !== undefined) {
      return this.state.page;
    } else {
      return this.props.customization.intro.first;
    }
  }

  render() {
    const { intro } = this.props.customization;
    const page = this.currentPage();
    const currentPage = intro.pages.filter((p) => p.id === page)[0];

    return (
      <div id="intro">
        {this.renderPage(
          currentPage,
          page === intro.first,
          page === intro.last,
        )}
        {this.props.customization.intro.logo && (
          <div className="logo-container">
            <img
              src={logo}
              width={this.props.customization.intro.logo.width}
              height={this.props.customization.intro.logo.height}
              alt="Logo"
            />
          </div>
        )}
      </div>
    );
  }
}

const CtdIntro = connect(mapStateToProps(['customization']))(Intro);

export default CtdIntro;
