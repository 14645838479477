import React from 'react';

import SnellInput from '../../components/SnellInput/SnellInput';

const InfoPage = (props) => {
  const rawHtml = props.content.join('');
  const { introButtonLabel } = props.customization ? props.customization : '';
  return (
    <div className="info-page intro-page">
      <div className="content" dangerouslySetInnerHTML={{ __html: rawHtml }} />
      {props.allowNext && (
        <SnellInput
          type="button"
          onClick={props.onClick}
          ariaLabel={introButtonLabel}
          title={introButtonLabel}
        />
      )}
    </div>
  );
};

export default InfoPage;
