/* View container for 'Confirm reservation' step. */

import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  changeProgressAction,
  setTemporaryReservationAction,
  updateSlotAction,
  updateFreeStatus,
} from '../../actions/index';
import timetable from '../../assets/images/calendar-check.svg';
import inputOk from '../../assets/images/icon_input_ok.svg';
import inputWarning from '../../assets/images/icon_input_warning.svg';
import warning from '../../assets/images/icon_warning.svg';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import {
  initializeFormData,
  extractValues,
  CustomerForm,
} from '../../components/CustomerForm/CustomerForm.js';
import SnellTimeFrame from '../../components/SnellTimeFrame/SnellTimeFrame';
import {
  reservationApi,
  translate as tl,
  formatMomentDate,
  generateReasons,
  sortedReasons,
} from '../../utils/helpers.js';
import { isLimitedSlot } from '../../utils/slotHelpers.js';

import PatientSelector from './PatientSelector';
import './SnellConfirm.css';

const icons = {
  timetable,
  warning,
  inputOk,
  inputWarning,
};

const queryString = require('query-string');

const initFormData = (initValues, reasonOptions) => ({
  ...initializeFormData(initValues),
  reasonOptions: sortedReasons(reasonOptions),
});

class SnellConfirm extends Component {
  constructor(props) {
    super(props);

    const prefilledValues = {};
    if (this.props.customer) {
      Object.keys(this.props.customer).forEach((k) => {
        if (this.props.customer[k] !== '') {
          prefilledValues[k] = this.props.customer[k];
        }
      });
    }

    if (this.props.authenticatedUser) {
      prefilledValues['ssn'] = this.props.authenticatedUser.ssn;
      prefilledValues['firstName'] = this.props.authenticatedUser.firstName;
      prefilledValues['lastName'] = this.props.authenticatedUser.lastName;
      prefilledValues['dateOfBirth'] = this.props.authenticatedUser.dateOfBirth;
      prefilledValues['email'] = this.props.authenticatedUser.email;
      prefilledValues['phone'] = this.props.authenticatedUser.phone;
    }

    this.state = {
      reservation: {},
      errorCreatingReservation: false,
      reservationCreated: false,
      selectedPatient: 0,
      patients: [initFormData(prefilledValues, [])],
      valid: false,
    };

    this.onUnload = this.onUnload.bind(this);
  }

  onUnload(event) {
    /* This is bit tricky here. We create a temporary reservation once the user lands on this view.
       We set the temporary reservation object to Redux state which we can then remove once user
       hits "back button" in the UI. However, if user navigates to other URL or hits back button
       of the browser, the temporary reservation does not get removed. This function here prompts
       user with a prompt to ask whether he wants to cancel the reservation or not. This atleast should
       prevent user from cancelling the reservation by mistake. There's no really good way to catch
       the unLoad event and asynchronous AJAX request would most likely fail. Beacon API would one
       good choice in the future, but the browser support needs to get better to fully trust it.
    */

    // This messages gets replaced by browsers' default message in many browsers
    const confirmationMessage = tl(
      'Are you sure you wish to cancel the reservation?',
    );
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);

    this.props.dispatch(changeProgressAction(2, tl('Verify reservation')));

    let slotId;
    let lastSlot = false;

    const queryParams = queryString.parse(window.location.search);
    if (!this.props.slot.id && queryParams.slot) {
      slotId = queryParams.slot;
    } else {
      slotId = this.props.slot.id;
    }

    if (queryParams.ls) {
      lastSlot = queryParams.ls === 'true';
    }

    const params = {
      reservationSlotId: slotId,
    };

    if (this.props.contract) {
      params.contract = this.props.contract;
    }

    if (slotId) {
      const createProm = reservationApi(
        'POST',
        'reservation',
        'createReservation',
        params,
      );
      const reasonsProm = reservationApi(
        'GET',
        'reservationreason',
        'availableReservationReasons',
        params,
      );
      Promise.all([createProm, reasonsProm])
        .then((responses) => {
          const reasonOptions = generateReasons(
            responses[1].data,
            this.props.customization,
            this.props.contract,
          );

          const reservationResponse = responses[0];
          this.setState({
            reservation: reservationResponse,
            reservationCreated: true,
            lastSlot: lastSlot,
            reasons: reasonOptions,
            patients: this.state.patients.map((p) => ({
              ...p,
              ...reasonOptions,
            })),
          });
          this.fireGTMevent();
          this.props.dispatch(
            setTemporaryReservationAction(reservationResponse.data),
          );
        })
        .catch((error) => {
          this.setState({
            errorCreatingReservation: true,
            lastSlot: lastSlot,
          });
        });
    } else {
      this.setState({
        errorCreatingReservation: true,
        lastSlot: lastSlot,
      });
    }
  }

  fireGTMevent() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'bookingProcessStarted',
    });
  }

  addPatientHandler = () => {
    this.setState((prevState) => {
      const patients = prevState.patients.concat({
        ...initializeFormData([]),
        reasonOptions: prevState.patients[0].reasonOptions,
      });
      return {
        patients,
        selectedPatient: prevState.patients.length,
        valid: patients.every((p) => p.valid),
      };
    });
  };

  removePatientHandler = (index) => {
    this.setState((prevState) => {
      const patients = prevState.patients.filter((p, i) => i !== index);
      const selectedPatient =
        prevState.selectedPatient >= index
          ? Math.max(prevState.selectedPatient - 1, 0)
          : prevState.selectedPatient;
      return {
        patients,
        selectedPatient,
        valid: patients.every((p) => p.valid),
      };
    });
  };

  selectPatientHandler = (index) => {
    this.setState({
      selectedPatient: index,
    });
  };

  confirmFreeReservation = (code) => {
    const params = { code };

    // Set app-wide state with information about free reservation
    this.props.dispatch(updateFreeStatus(true));

    return reservationApi(
      'POST',
      'reservation',
      'confirmReservation',
      params,
      {},
    ).then((response) => {
      if (response.data.state === 'ACTIVE') {
        this.props.dispatch(
          updateSlotAction({
            reservationId: response.data.id,
            reservationCode: response.data.code,
            reservationFee: response.data.reservationFee,
          }),
        );

        /**
         * We set reservation id to session storage. This value is used in 'Complete' page
         * to fire conversion event. When we send the conversion event after succesfull payment,
         * we use this value to see if conversion is already logged or not.
         */
        sessionStorage.setItem('reservationInProgress', response.data.id);
        window.scrollTo(0, 0);
        this.props.history.push(`/${tl('confirmed')}/${response.data.code}`);
        return { ok: true };
      } else {
        return {
          ok: false,
          errorMsg: tl('Reservation confirmation failed'),
          canRecover: true,
        };
      }
    });
  };

  submitHandler = (params) => {
    if (this.state.patients.length === 1) {
      return this.handleSingleReservation(params);
    } else {
      return this.handleMultipleReservations();
    }
  };

  handleSingleReservation = (params) => {
    const ps = { ...params, id: this.state.reservation.data.id };

    return reservationApi(
      'POST',
      'reservation',
      'completePublicReservation',
      {},
      ps,
    ).then((response) => {
      if (!response.data || !response.data.id) {
        return {
          ok: false,
          errorMsg: tl(
            'Reservation cannot be confirmed. Please contact the customer service.',
          ),
          canRecover: false,
        };
      }

      if (response.data.state === 'AWAITING_PAYMENT') {
        // AWAITING_PAYMENT means user needs to make a payment to confirm the reservation
        this.props.dispatch(
          updateSlotAction({
            reservationId: response.data.id,
            reservationCode: response.data.code,
            reservationFee: response.data.reservationFee,
          }),
        );
        this.props.history.push(`/${tl('payment')}/${response.data.code}`);
        return { ok: true };
      } else if (response.data.state === 'UNCONFIRMED') {
        // UNCONFIRMED means user does not need to pay reservation fee in order to complete
        // the reservation. The reservation should be confirmed and user should be redirected
        // to /confirmed view.
        return this.confirmFreeReservation(response.data.code).then(() => ({
          ok: true,
        }));
      } else {
        // Response EXPIRED
        return {
          ok: false,
          errorMsg: tl('Temporary reservation expired. Please start over.'),
          canRecover: false,
        };
      }
    });
  };

  handleMultipleReservations = () => {
    const dobFormat = this.props.customization.dateOfBirthFormat;
    const ps = {
      patients: this.state.patients.map((p) => extractValues(p, dobFormat)),
      id: this.state.reservation.data.id,
    };

    return reservationApi(
      'POST',
      'reservation',
      'completeGroupReservation',
      {},
      ps,
    ).then((response) => {
      const data = response.data;
      if (!data || !(data instanceof Array)) {
        return {
          ok: false,
          errorMsg: tl(
            'Reservation cannot be confirmed. Please contact the customer service.',
          ),
          canRecover: false,
        };
      }
      // TODO: does not work when payment is needed
      const unknownState = data.some((r) => r.state !== 'UNCONFIRMED');
      if (unknownState) {
        // Do not confirm
        return {
          ok: false,
          errorMsg: tl(
            'Reservation cannot be confirmed. Please contact the customer service.',
          ),
          canRecover: false,
        };
      }

      this.props.dispatch(updateFreeStatus(true));
      return Promise.all(
        data.map((reservation) => {
          const params = { code: reservation.code };
          return reservationApi(
            'POST',
            'reservation',
            'confirmReservation',
            params,
            {},
          ).then((r) => r.data);
        }),
      ).then((reservations) => {
        for (const res of reservations) {
          if (res.state === 'ACTIVE') {
            this.props.dispatch(
              updateSlotAction({
                reservationId: res.id,
                reservationCode: res.code,
                reservationFee: res.reservationFee,
              }),
            );
            sessionStorage.setItem('reservationInProgress', res.id);
            window.scrollTo(0, 0);
            this.props.history.push(`/${tl('confirmed')}/${res.code}`);
            return { ok: true };
          }
        }
        return {
          ok: false,
          errorMsg: tl('Reservation confirmation failed'),
          canRecover: true,
        };
      });
    });
  };

  onFormDataChange = (formData) => {
    this.setState((prevState) => {
      const patients = prevState.patients.map((p, i) =>
        i === prevState.selectedPatient ? formData : p,
      );
      const valid = patients.every((p) => p.valid);
      return {
        patients,
        valid,
      };
    });
  };

  hasField = (data, fieldName) => data[fieldName] && data[fieldName].value;

  createLabels = () => {
    const field = 'lastName';
    return this.state.patients.map((p, i) => {
      if (!p[field] || !p[field].value) {
        return '';
      }
      const multiple =
        this.state.patients.filter(
          (pt) => pt[field] && pt[field].value === p[field].value,
        ).length > 1;
      const order = multiple
        ? this.state.patients.filter(
            (pt, ind) =>
              ind <= i && pt[field] && pt[field].value === p[field].value,
          ).length
        : 0;
      return p[field].value + (order ? ` (${order})` : '');
    });
  };

  render() {
    const slot = this.state.reservation.data
      ? this.state.reservation.data.reservationSlot
      : {};
    const prefilledValues = {};
    if (this.props.customer) {
      Object.keys(this.props.customer).forEach((k) => {
        if (this.props.customer[k] !== '') {
          prefilledValues[k] = this.props.customer[k];
        }
      });
    }

    if (this.state.errorCreatingReservation === true) {
      // Creating a reservation failed. Most likely the slot got fully booked.
      return (
        <div className="SnellConfirm row">
          <div className="col" />
          <div className="col">
            <SnellConfirmWarning
              top={true}
              message={`${tl('Something went wrong')}, ${tl(
                'please go back and reselect a slot',
              )}`}
            />
          </div>
          <div className="col" />
        </div>
      );
    } else if (
      this.state.reservationCreated === true &&
      slot.startTime &&
      slot.id
    ) {
      // Reservation is created, show form.
      return (
        <div className="SnellConfirm row">
          <div className="col">
            <img
              className="SnellConfirm__icon SnellConfirm__icon--timetable"
              src={icons.timetable}
              alt=""
            />
          </div>
          <div className="col">
            <SnellConfirmContent
              slot={slot}
              reservation={this.state.reservation}
              history={this.props.history}
              contract={this.props.contract}
              lastSlot={this.state.lastSlot}
              reasons={this.state.reasons}
              submitHandler={this.submitHandler}
              onChange={this.onFormDataChange}
              formData={this.state.patients[this.state.selectedPatient]}
              fields={this.props.customization.confirmationFields}
              customization={this.props.customization}
              valid={this.state.valid}
            />
          </div>
          <div className="col">
            {this.props.customization.maxReservationGroup > 1 && (
              <PatientSelector
                labels={this.createLabels()}
                valids={this.state.patients.map((p) => p.valid)}
                addPatient={this.addPatientHandler}
                removePatient={this.removePatientHandler}
                selectPatient={this.selectPatientHandler}
                selected={this.state.selectedPatient}
                canRemoveFirst={!this.props.customization.requiresLogin}
                maxItems={this.props.customization.maxReservationGroup}
              />
            )}
          </div>
        </div>
      );
    } else {
      // Loading
      return (
        <div className="SnellReservationView__loaderWrapper">
          <div className="SnellReservationView__loader">Loading...</div>
        </div>
      );
    }
  }
}

const SnellConfirmContent = (props) => {
  const slot = props.slot;
  const date = moment(slot.startTime.date);

  const noRootCanalTreatments = !props.customization.noRootCanalTreatments
    ? tl('noRootCanalTreatments')
    : props.customization.noRootCanalTreatments.join('');

  const slotRestrictionsOfInterest =
    props.customization.slotRestrictionsOfInterest;
  const isLimited = isLimitedSlot(slot, slotRestrictionsOfInterest);

  let lastSlot = false;

  if (props.lastSlot !== null) {
    lastSlot = props.lastSlot;
  }

  return (
    <div className="SnellConfirmContent">
      <div className="SnellConfirmContent__dateTimeContainer">
        <span className="SnellConfirmContent__dateTime">
          <span className="SnellConfirmContent__date">
            {formatMomentDate(date, true)}
          </span>
        </span>{' '}
        <span className="SnellConfirmContent__dateTime">
          <span>{tl('Klo')} </span> <SnellTimeFrame date={date} />
        </span>
      </div>
      {lastSlot ? (
        <SnellConfirmWarning message={noRootCanalTreatments} />
      ) : null}
      <CustomerForm
        reservation={props.reservation}
        history={props.history}
        contract={props.contract}
        formData={props.formData}
        onChange={props.onChange}
        fields={props.fields}
        submit={props.submitHandler}
        valid={props.valid}
        limited={isLimited}
      />
    </div>
  );
};

class SnellConfirmWarning extends Component {
  render() {
    const message = this.props.message;

    return message ? (
      <div
        className={`SnellConfirmWarning ${
          this.props.top ? 'SnellConfirmWarning--top' : ''
        }`}
      >
        <img
          className="SnellConfirm__icon SnellConfirm__icon--warning"
          src={icons.warning}
          alt=""
        />
        <span>{message}</span>
      </div>
    ) : null;
  }
}

// Redux Connected Component
const CtdSnellConfirm = connect(
  mapStateToProps([
    'slot',
    'customer',
    'authenticatedUser',
    'contract',
    'customization',
  ]),
)(SnellConfirm);

export default CtdSnellConfirm;
