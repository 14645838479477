export default {
  fi: {
    generalInformation: 'Perustiedot',
    moreInformation: 'Lisätiedot',
    introText:
      'Kerro meille lisää itsestäsi ja terveydentilastasi, jotta voimme palvella sinua paremmin.',
    emailInfo:
      'Olet saanut sähköpostiisi varausvahvistuksen ja linkin, josta pääset takaisin lomakkeeseen.',
    lastModified: 'Olet päivittänyt terveystietosi viimeksi',
    ifChanged:
      'Jos tietosi ovat muuttuneet tämän jälkeen, siirry päivittämään tietosi.',
    requiredFillInfo: 'Lomake tulee täyttää ennen hoidon alkamista.',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    address: 'Katuosoite',
    postalCode: 'Postinumero',
    city: 'Postitoimipaikka',
    occupation: 'Ammatti',
    health: 'Yleisterveys',
    generalHealth: 'Yleinen terveydentilani on',
    generalHealthGood: 'Hyvä',
    generalHealthBad: 'Heikko',
    generalHealthInfo: 'Jos heikko, mikä ongelmana?',
    drugs: 'Minulla on säännöllistä lääkitystä',
    drugsList: 'Mitä?',
    allergies: 'Minulla on allergioita tai yliherkkyyksiä',
    allergiesList: 'Mitä?',
    pregnant: 'Olen raskaana',
    pregnantDueDate: 'Laskettu aika',
    smokeOrSnuff: 'Tupakoin tai nuuskaan',
    smokeOrSnuffInfo: 'Miten kauan, paljonko?',
    localAnestheticProblems: 'Ongelmia puudutuksen kanssa?',
    localAnestheticProblemsInfo: 'Mitä?',
    radiation: 'Olen saanut sädehoitoa pään / kaulan alueelle',
    radiationInfo: 'Milloin?',
    needForAntibiotics: 'Antibioottilääkityksen tarve',
    carditisOrEndocarditis: 'Kardiitti / endokardiitti',
    carditisOrEndocarditisInfo: 'Milloin',
    valveOrProsthesis: 'Läppäproteesi, stenttejä, pallolaajennus',
    valveOrProsthesisInfo: 'Milloin?',
    congenitalHeartMalformation: 'Synnynnäinen sydänvika',
    congenitalHeartMalformationInfo: 'Mikä?',
    artificialJoints: 'Keinonivel',
    artificialJointsInfo: 'Milloin?',
    generalIllnesses: 'Yleissairaudet',
    asthmaOrLungDisease: 'Astma tai muu hengitysteiden sairaus',
    epilepsy: 'Epilepsia, MS-tauti',
    hiv: 'HIV',
    hepatitis: 'Hepatiitti, muu maksasairaus',
    diabetes: 'Diabetes',
    coldsores: 'Huuliherpes (aktiivinen)',
    hypertension: 'Kohonnut verenpaine',
    mentalIllness: 'Psyykkinen sairaus',
    rheumatics: 'Reuma tai vastaava',
    thyroidDisease: 'Kilpirauhasen sairaus',
    thyroidDiseaseInfo: 'Mikä?',
    cardioVascularDisease: 'Sydän tai verisuonisairaus',
    cardioVascularDiseaseInfo: 'Mikä?',
    otherChronicDisease: 'Muu pitkäaikaissauraus',
    otherChronicDiseaseInfo: 'Mikä?',
    otherFactorsAffectingTreatment:
      'Muu hoitoon vaikuttava asia (esim. MRSA, ESBL, VRE)',
    otherFactorsAffectingTreatmentInfo: 'Mikä?',
    good: 'Hyvä',
    notGood: 'Huono',
    otherQuestions: 'Haluan vastaanottaa...',
    recallMailAllowed: 'Uusintakäyntikutsuja sähköpostilla',
    recallSMSAllowed: 'Uusintakäyntikutsuja tekstiviestillä',
    offerMailAllowed: 'Ajankohtaisia tiedotteita ja tarjouksia',
    Reasons: 'Hoitoon hakeutumisen syy',
    CHECK_UP_AND_ASSESSMENT: 'Tarkastus ja hoidon tarpeen arviointi',
    ACHE: 'Särky',
    SENSITIVITY: 'Vihlonta',
    RESTORATION: 'Paikkaus',
    CRACK: 'Lohkeama',
    REMOVAL: 'Hampaan poisto',
    HYGIENIST: 'Suuhygienisti (hammaskiven poisto)',
    OCCLUSAL_SPLINT: 'Purentakisko',
    ROOT_TREATMENT_CONTINUATION: 'Juurihoidon jatko',
    WHITENING: 'Valkaisu',
    TEETH_CLEANING: 'Soodapuhdistus',
    IMPLANT_CONSULTATION: 'Implanttikonsultaatio',
    IMPLANT_HOME_CARE_CONTROL_VISIT:
      'Implantin kotihoidon kontrolli (suuhygienisti)',
  },
  sv: {
    generalInformation: 'Grundinformation',
    moreInformation: 'Tilläggsuppgifter',
    introText:
      'Berätta noggrannare om dej själv och ditt hälsotillstånd så vi kan betjäna dej bättre.',
    emailInfo:
      'Du har fått en bekräftelse till din e-postadress med en länk via vilken du kan komma tillbaka till formuläret.',
    lastModified: 'Du har uppdaterat dina hälsouppgifter senast',
    ifChanged:
      'Om dina uppgifter ändrats sedan dess, vänligen uppdatera uppgifterna.',
    requiredFillInfo:
      'Formuläret måste vara ifyllt innan behandlingen kan påbörjas.',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    address: 'Gatuadress',
    postalCode: 'Postnummer',
    city: 'Postort',
    occupation: 'Yrke',
    health: 'Hälsotillstånd',
    generalHealth: 'Allmänt hälsotillstånd',
    generalHealthGood: 'Bra',
    generalHealthBad: 'Inte bra',
    generalHealthInfo: 'Om inte bra, vad är problemet?',
    drugs: 'Läkemedel',
    drugsList: 'Läkemedel som tas:',
    allergies: 'Överkänslighet/allergi',
    allergiesList: 'Vilken',
    pregnant: 'Graviditet',
    pregnantDueDate: 'Beräknad tid:',
    smokeOrSnuff: 'Rökning/snusning',
    smokeOrSnuffInfo: 'Hur länge/hur mycket?',
    localAnestheticProblems: 'Problem med bedövning?',
    localAnestheticProblemsInfo: 'Vad?',
    radiation: 'Strålbehandling i området kring huvud/hals?',
    radiationInfo: 'När?',
    needForAntibiotics: 'Behov av antibiotisk premedicinering',
    carditisOrEndocarditis: 'Kardit/endokardit',
    carditisOrEndocarditisInfo: 'När?',
    valveOrProsthesis: 'Klaffprotes, stent, ballongdilatation',
    valveOrProsthesisInfo: 'När',
    congenitalHeartMalformation: 'Medfött hjärtfel',
    congenitalHeartMalformationInfo: 'Vilken?',
    artificialJoints: 'Endoprotes',
    artificialJointsInfo: 'När?',
    generalIllnesses: 'Systemisk sjukdom',
    asthmaOrLungDisease: 'Astma eller annan sjukdom i luftvägarna',
    epilepsy: 'Epilepsi, MS',
    hiv: 'HIV-positiv',
    hepatitis: 'Hepatitis',
    diabetes: 'Diabetes',
    coldsores: 'Munherpes (aktiv)',
    hypertension: 'Förhöjt blodtryck',
    mentalIllness: 'Psykisk sjukdom',
    rheumatics: 'Reuma eller motsvarande',
    thyroidDisease: 'Sköldkörtelsjukdom',
    thyroidDiseaseInfo: 'Vilken?',
    cardioVascularDisease: 'Hjärt- eller blodkärlssjukdom',
    cardioVascularDiseaseInfo: 'Vilken?',
    otherChronicDisease: 'Annan långvarig sjukdom',
    otherChronicDiseaseInfo: 'Vilken?',
    otherFactorsAffectingTreatment:
      'Annat som inverkar på vården (t.ex. MRSA, ESBL, VRE)',
    otherFactorsAffectingTreatmentInfo: 'Vilken?',
    good: 'Bra',
    notGood: 'Inte bra',
    otherQuestions: 'Jag vill få...',
    recallMailAllowed: 'Återkallelsel via email',
    recallSMSAllowed: 'Återkallelser via SMS',
    offerMailAllowed: 'Nyhetsbrev och erbjudanden',
    Reasons: 'Orsak till behov av behandling',
    CHECK_UP_AND_ASSESSMENT: 'Undersökning',
    ACHE: 'Värk',
    SENSITIVITY: 'Ilning',
    RESTORATION: 'Fyllning',
    CRACK: 'Spricka',
    REMOVAL: 'Tandborttagning',
    HYGIENIST: 'Tandhygienist',
    OCCLUSAL_SPLINT: 'Bettskena',
    ROOT_TREATMENT_CONTINUATION: 'Fortsatt rotbehandling',
    WHITENING: 'Blekning',
    TEETH_CLEANING: 'Natriumkarbonatrengöring',
    IMPLANT_CONSULTATION: 'Implantatkonsultation',
    IMPLANT_HOME_CARE_CONTROL_VISIT:
      'Kontroll av implantatets hemvård (tandhygienist)',
  },
  en: {
    generalInformation: 'General information',
    moreInformation: 'Additional information',
    lastModified: 'You have modified your information last time',
    ifChanged:
      'If your information have changed, please proceed to update them.',
    requiredFillInfo: 'This form needs to be filled in before the treatment.',
    firstName: 'First name',
    lastName: 'Last name',
    address: 'Street address',
    postalCode: 'Postal code',
    city: 'City',
    occupation: 'Occupation',
    health: 'Health',
    generalHealth: 'My general health is',
    generalHealthGood: 'Good',
    generalHealthBad: 'Bad',
    generalHealthInfo: 'Issues if bad',
    drugs: 'I have regular medication',
    drugsList: 'What?',
    allergies: 'I have allergies or hypersensitivities',
    allergiesList: 'What?',
    pregnant: 'I am pregnant',
    pregnantInfo: 'Due date',
    smokeOrSnuff: 'I smoke or use snuff',
    smokeOrSnuffInfo: 'How long and how often?',
    localAnestheticProblems: 'I have had issues with local anesthesia',
    localAnestheticProblemsInfo: 'What?',
    radiation: 'I have had radiation therapy in the head / neck area',
    radiationInfo: 'When?',
    needForAntibiotics: 'Need for antibiotics',
    carditisOrEndocarditis: 'Carditis or endocarditis',
    carditisOrEndocarditisInfo: 'When?',
    valveOrProsthesis: 'Hearth valve, stents, angioplasty',
    valveOrProsthesisInfo: 'When?',
    congenitalHeartMalformation: 'Congenital heart malformation',
    congenitalHeartMalformationInfo: 'What?',
    artificialJoints: 'Artificial joints',
    artificialJointsInfo: 'When?',
    generalIllnesses: 'General diseases',
    asthmaOrLungDisease: 'Asthma or other respiratory illness',
    epilepsy: 'Epilepsy',
    hiv: 'HIV',
    hepatitis: 'Hepatitis',
    diabetes: 'Diabetes',
    coldsores: 'Cold sore',
    hypertension: 'Hypertension',
    mentalIllness: 'Mental illness',
    rheumatics: 'Rheumatics',
    thyroidDisease: 'Thyroid disease',
    thyroidDiseaseInfo: 'What?',
    cardioVascularDisease: 'Cardiovascular disease',
    cardioVascularDiseaseInfo: 'What?',
    otherChronicDisease: 'Other chronic disease',
    otherChronicDiseaseInfo: 'What?',
    otherFactorsAffectingTreatment:
      'Other factors or diseases that may effect treatment (ex. MRSA, ESBL, VRE)',
    otherFactorsAffectingTreatmentInfo: 'What?',
    good: 'Good',
    notGood: 'Not good',
    otherQuestions: 'I want to receive...',
    recallMailAllowed: 'Recalls by email',
    recallSMSAllowed: 'Recalls by text message',
    offerMailAllowed: 'Newsletters and offers',
    Reasons: 'Reasons for visit',
    CHECK_UP_AND_ASSESSMENT: 'Check-up',
    ACHE: 'Tooth ache',
    SENSITIVITY: 'Sensitivity',
    RESTORATION: 'Filling',
    CRACK: 'Chip',
    REMOVAL: 'Tooth extraction',
    HYGIENIST: 'Dental hygienist (Tartar removal)',
    OCCLUSAL_SPLINT: 'An occlusal splint',
    ROOT_TREATMENT_CONTINUATION: 'Root canal treatment follow-up visit',
    WHITENING: 'Whitening',
    TEETH_CLEANING: 'Soda cleaning',
    IMPLANT_CONSULTATION: 'Implant consultation',
    IMPLANT_HOME_CARE_CONTROL_VISIT:
      'Implant home care control visit (dental hygienist)',
  },
  nl: {
    generalInformation: 'General information',
    moreInformation: 'Additional information',
    lastModified: 'You have modified your information last time',
    ifChanged:
      'If your information have changed, please proceed to update them.',
    requiredFillInfo: 'This form needs to be filled in before the treatment.',
    firstName: 'First name',
    lastName: 'Last name',
    address: 'Street address',
    postalCode: 'Postal code',
    city: 'City',
    occupation: 'Occupation',
    health: 'Health',
    generalHealth: 'My general health is',
    generalHealthGood: 'Good',
    generalHealthBad: 'Bad',
    generalHealthInfo: 'Issues if bad',
    drugs: 'I have regular medication',
    drugsList: 'What?',
    allergies: 'I have allergies or hypersensitivities',
    allergiesList: 'What?',
    pregnant: 'I am pregnant',
    pregnantInfo: 'Due date',
    smokeOrSnuff: 'I smoke or use snuff',
    smokeOrSnuffInfo: 'How long and how often?',
    localAnestheticProblems: 'I have had issues with local anesthesia',
    localAnestheticProblemsInfo: 'What?',
    radiation: 'I have had radiation therapy in the head / neck area',
    radiationInfo: 'When?',
    needForAntibiotics: 'Need for antibiotics',
    carditisOrEndocarditis: 'Carditis or endocarditis',
    carditisOrEndocarditisInfo: 'When?',
    valveOrProsthesis: 'Hearth valve, stents, angioplasty',
    valveOrProsthesisInfo: 'When?',
    congenitalHeartMalformation: 'Congenital heart malformation',
    congenitalHeartMalformationInfo: 'What?',
    artificialJoints: 'Artificial joints',
    artificialJointsInfo: 'When?',
    generalIllnesses: 'General diseases',
    asthmaOrLungDisease: 'Asthma or other respiratory illness',
    epilepsy: 'Epilepsy',
    hiv: 'HIV',
    hepatitis: 'Hepatitis',
    diabetes: 'Diabetes',
    coldsores: 'Cold sore',
    hypertension: 'Hypertension',
    mentalIllness: 'Mental illness',
    rheumatics: 'Rheumatics',
    thyroidDisease: 'Thyroid disease',
    thyroidDiseaseInfo: 'What?',
    cardioVascularDisease: 'Cardiovascular disease',
    cardioVascularDiseaseInfo: 'What?',
    otherChronicDisease: 'Other chronic disease',
    otherChronicDiseaseInfo: 'What?',
    otherFactorsAffectingTreatment:
      'Other factors or diseases that may effect treatment (ex. MRSA, ESBL, VRE)',
    otherFactorsAffectingTreatmentInfo: 'What?',
    good: 'Good',
    notGood: 'Not good',
    otherQuestions: 'I want to receive...',
    recallMailAllowed: 'Recalls by email',
    recallSMSAllowed: 'Recalls by text message',
    offerMailAllowed: 'Newsletters and offers',
    Reasons: 'Reasons for visit',
    CHECK_UP_AND_ASSESSMENT: 'Check-up',
    ACHE: 'Tooth ache',
    SENSITIVITY: 'Sensitivity',
    RESTORATION: 'Filling',
    CRACK: 'Chip',
    REMOVAL: 'Tooth extraction',
    HYGIENIST: 'Dental hygienist (Tartar removal)',
    OCCLUSAL_SPLINT: 'An occlusal splint',
    ROOT_TREATMENT_CONTINUATION: 'Root canal treatment follow-up visit',
    WHITENING: 'Whitening',
    TEETH_CLEANING: 'Soda cleaning',
    IMPLANT_CONSULTATION: 'Implant consultation',
    IMPLANT_HOME_CARE_CONTROL_VISIT:
      'Implant home care control visit (dental hygienist)',
  },
};
