/* View container for 'Reservation complete' step. */

import moment from 'moment-timezone';
import React, { Component } from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import { changeProgressAction } from '../../actions/index';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import SnellTimeFrame from '../../components/SnellTimeFrame/SnellTimeFrame';
import {
  formatMomentDate,
  reservationApi,
  translate as tl,
} from '../../utils/helpers.js';

/* eslint-disable */
import SnellIconTimetable from '-!svg-react-loader?name=SnellIconTimetable!../../assets/images/calendar-check.svg';
import SnellIconPreinfo from '-!svg-react-loader?name=SnellIconPreinfo!../../assets/images/icon_preinfo.svg';
import SnellIconInfo from '-!svg-react-loader?name=SnellIconInfo!../../assets/images/icon_info.svg';
/* eslint-enable */

import './SnellComplete.css';

class SnellComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reservation: {},
      isLoading: true,
      event: {},
      infoLink: null,
      canBeMoved: false,
      reservationStartTime: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.code) {
      reservationApi('GET', 'reservation', 'getReservationByCode', {
        code: this.props.match.params.code,
      })
        .then((response) => {
          const timezone = response.data.reservationSlotStartTime.timezone;
          const startWithoutZone = moment(
            response.data.reservationSlotStartTime.date,
          );
          const startTime = moment.tz(
            response.data.reservationSlotStartTime.date,
            timezone,
          );
          const endTime = moment.tz(
            response.data.reservationSlotEndTime.date,
            timezone,
          );
          const { calendarInfo } = this.props.customization;
          this.setState({
            reservation: response.data,
            isLoading: false,
            reservationStartTime: startWithoutZone,
            event: {
              title: calendarInfo.title,
              description: calendarInfo.description,
              location: calendarInfo.location,
              startTime: startTime.format('YYYY-MM-DDTHH:mm:ssZZ'),
              endTime: endTime.format('YYYY-MM-DDTHH:mm:ssZZ'),
            },
          });

          // Reservation can be moved if there's more than 12 hours until the appointment
          const now = moment();
          const reservationTime = moment(startTime);

          if (
            reservationTime
              .subtract(12, 'hours')
              .format('YYYY-MM-DD HH:mm:ss') > now.format('YYYY-MM-DD HH:mm:ss')
          ) {
            this.setState({ canBeMoved: true });
          } else {
            this.setState({ canBeMoved: false });
          }

          this.trackConversion(response.data);

          this.props.dispatch(
            changeProgressAction(4, tl('Your reservation is confirmed')),
          );
        })
        .catch((error) => {
          this.setState({
            // eslint-disable-next-line no-mixed-operators
            reservation: {
              status: (error.response && error.response.status) || 418,
            }, // Just a general error, asking to go back.
          });
          console.error(`SnellApp, SnellComplete: ${error}`);
        });
    } else {
      // The user probably accessed the page directly.
      this.setState({
        reservation: { status: 418 }, // Just a general error, asking to go back.
      });
    }
  }

  trackConversion(reservation) {
    /** If reservation id is saved to session storage and reservation id is the current one,
     * we'll save the conversion and send it to GTM.
     */
    const reservationInSession = sessionStorage.getItem(
      'reservationInProgress',
    );
    if (
      reservationInSession &&
      Number(reservationInSession) === Number(reservation.id)
    ) {
      sessionStorage.setItem('reservationInProgress', ''); // Null the session storage value so it's conversion is not tracked anymore

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'bookingConfirmed',
        price: reservation.reservationFee,
      });
    }
  }

  goToInformation(e) {
    e.preventDefault();
    this.props.dispatch(changeProgressAction(5, tl('Preliminary Information')));
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="SnellComplete row">
          <div className="col">Loading...</div>
        </div>
      );
    } else if (
      this.props.match.params.code === undefined &&
      this.props.slot.reservationCode === undefined
    ) {
      return <Redirect to={`/`} />;
    } else {
      return (
        <div className="SnellComplete row">
          <div className="col col-flex">
            {this.props.customization.reservationCompleteDisclaimerEnabled && (
              <Disclaimer
                content={this.props.customization.reservationCompleteDisclaimer}
              ></Disclaimer>
            )}
            <SnellIconTimetable className="SnellComplete__icon SnellComplete__icon--timetable" />
            <div className="SnellComplete__reservation-info">
              <div className="SnellComplete__reservation-info-row">
                <strong>{tl('Date')}:</strong>{' '}
                {formatMomentDate(this.state.reservationStartTime, true)}
              </div>
              <div className="SnellComplete__reservation-info-row">
                <strong>{tl('Timeframe')}:</strong>{' '}
                <SnellTimeFrame date={this.state.reservationStartTime} />
              </div>
              <AddToCalendar
                // TODO: Fix keyboard navigation in this component cause it's not working at the moment
                tabindex="0"
                event={this.state.event}
                buttonWrapperClass="SnellComplete__add-to-calendar"
                buttonClassClosed="SnellComplete__button"
                buttonLabel={tl('Add to calendar')}
              />
              {this.state.canBeMoved ? (
                <Link
                  to={`/${tl('change-reservation')}/${
                    this.state.reservation.code
                  }`}
                  className="SnellComplete__button"
                >
                  {tl('Change reservation')}
                </Link>
              ) : null}
              <br />
              <p>
                {this.state.canBeMoved === false
                  ? tl("This reservation can't be moved anymore.")
                  : null}
              </p>
            </div>
          </div>
          {this.props.customization.anamnesisEnabled && (
            <div className="col col-flex">
              <SnellIconPreinfo className="SnellComplete__icon SnellComplete__icon--preinfo" />
              <br />
              <p>
                {tl(
                  'Fill in your medical information and make your visit smoother.',
                )}
              </p>
              <br />
              <Link
                className="SnellComplete__button"
                to={`/${tl('preinformation')}/${this.state.reservation.code}`}
              >
                {tl('Fill in information')}
              </Link>
            </div>
          )}
          {this.state.infoLink !== null ? (
            <div className="col col-flex">
              <SnellIconInfo className="SnellComplete__icon SnellComplete__icon--info" />
              <br />
              <a
                href={this.state.infoLink.url}
                className="SnellComplete__button"
                target="_blank"
                rel="noopener noreferrer"
              >
                {tl('Information about the visit')}
              </a>
            </div>
          ) : null}
        </div>
      );
    }
  }
}

// Redux Connected Component
const CtdSnellComplete = connect(mapStateToProps(['slot', 'customization']))(
  SnellComplete,
);

export default CtdSnellComplete;
