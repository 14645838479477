var React = require('react');

function SnellIconInfo (props) {
    return React.createElement("svg",props,[React.createElement("defs",{"key":0},React.createElement("style",null,".a{fill:#6ab947;}")),React.createElement("path",{"className":"a","d":"M7.9,13.857,1,6.961,2.636,5.325,7.9,10.468,17.364,1,19,2.636Z","transform":"translate(-14509 -1677)","key":1})]);
}

SnellIconInfo.defaultProps = {"viewBox":"-14508 -1676 18 12.857"};

module.exports = SnellIconInfo;

SnellIconInfo.default = SnellIconInfo;
