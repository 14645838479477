import b1 from '../images/icon_bank_1.jpg';
// import b9 from '../images/icon_bank_9.jpg';
import b10 from '../images/icon_bank_10.jpg';
import b2 from '../images/icon_bank_2.jpg';
import b3 from '../images/icon_bank_3.jpg';
import b30 from '../images/icon_bank_30.jpg';
import b31 from '../images/icon_bank_31.jpg';
import b5 from '../images/icon_bank_5.jpg';
// import b34 from '../images/icon_bank_34.jpg';
import b50 from '../images/icon_bank_50.jpg';
import b51 from '../images/icon_bank_51.jpg';
import b52 from '../images/icon_bank_52.jpg';
import b56 from '../images/icon_bank_56.jpg';
import b6 from '../images/icon_bank_6.jpg';
import b61 from '../images/icon_bank_61.jpg';
// import b58 from '../images/icon_bank_58.jpg';

export default {
  50: { src: b50, alt: 'Aktia' },
  5: { src: b5, alt: 'Ålandsbanken' },
  10: { src: b10, alt: 'S-Pankki' },
  3: { src: b3, alt: 'Danske Bank' },
  52: { src: b52, alt: 'Säästöpankki' },
  6: { src: b6, alt: 'Handelsbanken' },
  51: { src: b51, alt: 'POP Pankki' },
  61: { src: b61, alt: 'Oma Säästöpankki' },
  2: { src: b2, alt: 'Osuuspankki' },
  1: { src: b1, alt: 'Nordea' },
  // 9: { src: b9, alt: 'PayPal' },
  // 55: { src: b34, alt: 'Diners Club' },
  56: { src: b56, alt: 'American Express (Nets)' },
  53: { src: b30, alt: 'Visa (Nets)' },
  54: { src: b31, alt: 'MasterCard (Nets)' },
  // 58: { src: b58, alt: 'Mobile Pay' },
};
