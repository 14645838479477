/**
 * Store defined in ../../index.js
 */

import {
  addWeekAction,
  changeProgressAction,
  toggleModalAction,
  hideTabsAction,
} from '../../actions/index.js';

export const initialState = {
  weeklyData: {},
  dailyData: {},
  progress: { step: 0, headerTitle: '' },
  contract: 0,
  slot: {},
  modalToggled: false,
  tabsHidden: false,
  freeStatus: true,
  firstWeekWithData: null,
  temporaryReservation: {},
  startDays: null,
  customization: null,
  introStatus: false,
  customer: undefined, // for customers logged in, null = logged out, undefined = disabled
  authenticatedUser: null,
  settings: null,
};

// Reducer
function reservationReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_WEEK':
      if (
        action.week instanceof Array &&
        typeof action.weekNumber === 'number' &&
        action.weekNumber % 1 === 0
      ) {
        const weeklyData = { ...state.weeklyData };
        weeklyData[action.weekNumber] = action.week;
        return {
          ...state,
          weeklyData,
        };
      }
      console.error(
        'SnellApp: A week must be an array and weekNumber an integer.',
      );
      return state;

    case 'SET_DAILY_DATA':
      if (action.data instanceof Array && action.startDay) {
        const dailyData = { ...state.dailyData };
        dailyData[action.startDay] = action.data;
        return {
          ...state,
          dailyData,
        };
      }
      console.error(
        'SnellApp: A week must be an array and weekNumber an integer.',
      );
      return state;

    case 'SET_FIRST_DAY':
      if (action.startDays) {
        return {
          ...state,
          startDays: action.startDays,
          contract: action.contract,
        };
      }
      return state;

    case 'RESET_WEEKS':
      return {
        ...state,
        weeks: {},
      };

    case 'CHANGE_PROGRESS':
      if (
        typeof action.headerTitle === 'string' &&
        typeof action.step === 'number' &&
        action.step % 1 === 0
      ) {
        return {
          ...state,
          progress: { step: action.step, headerTitle: action.headerTitle },
        };
      }
      console.error(
        'SnellApp: step must be an integer and headerTitle a string.',
      );
      return state;

    case 'SELECT_CONTRACT':
      if (action.contract !== null) {
        return {
          ...state,
          contract: action.contract,
        };
      }
      console.error('SnellApp: contract must be an integer.');
      return state;

    case 'SELECT_SLOT':
      if (typeof action.slot === 'object') {
        return {
          ...state,
          slot: action.slot,
        };
      }
      console.error('SnellApp: slot must be an object.');
      return state;

    case 'UPDATE_SLOT':
      if (typeof action.slot === 'object') {
        return {
          ...state,
          slot: {
            ...state.slot,
            ...action.slot,
          },
        };
      }
      console.error('SnellApp: slot must be an object.');
      return state;

    case 'TOGGLE_MODAL':
      if (typeof action.modalToggled === 'boolean') {
        return {
          ...state,
          modalToggled: action.modalToggled,
        };
      }
      console.error('SnellApp: modal must be an boolean.');
      return state;

    case 'HIDE_TABS':
      if (typeof action.tabsHidden === 'boolean') {
        return {
          ...state,
          tabsHidden: action.tabsHidden,
        };
      }
      console.error('SnellApp: tabsHidden must be an boolean.');
      return state;

    case 'UPDATE_FREE_STATUS':
      if (typeof action.freeStatus === 'boolean') {
        return {
          ...state,
          freeStatus: action.freeStatus,
        };
      }
      return state;

    case 'SET_FIRST_WEEK_WITH_DATA':
      if (typeof action.firstWeekWithData === 'number') {
        return {
          ...state,
          firstWeekWithData: action.firstWeekWithData,
        };
      }
      return state;

    case 'SET_TEMPORARY_RESERVATION':
      if (typeof action.temporaryReservation === 'object') {
        return {
          ...state,
          temporaryReservation: action.temporaryReservation,
        };
      }
      return state;

    case 'SET_INTRO_STATUS':
      return {
        ...state,
        introStatus: action.status,
      };

    case 'SET_CUSTOMER':
      return {
        ...state,
        customer: action.customer,
      };

    case 'SET_SETTINGS':
      return {
        ...state,
        settings: action.settings,
      };

    case 'SET_CUSTOMIZATION':
      const requiresLogin = action.customization.requiresLogin;
      const customer = state.customer;
      const noCustomer = requiresLogin ? null : undefined;
      return {
        ...state,
        introStatus: state.introStatus
          ? true
          : !action.customization.introEnabled,
        customer: customer && requiresLogin ? customer : noCustomer,
        customization: action.customization,
      };

    case 'SET_AUTHENTICATED_USER':
      return {
        ...state,
        authenticatedUser: action.user,
      };

    default:
      return state;
  }
}

// Map state to component props (by prop names)
function mapStateToProps(propNames = []) {
  return (state) => {
    if (
      propNames.length &&
      // eslint-disable-next-line no-prototype-builtins
      propNames.every((prop) => state.hasOwnProperty(prop))
    ) {
      return propNames.reduce(
        (props, prop) => ({ ...props, [prop]: state[prop] }),
        {},
      );
    }

    if (propNames.length) {
      console.error(
        "SnellApp, mapStateToProps: state doesn't include all provided propNames; mapping all props from state.",
      );
    }

    return { ...state };
  };
}

// Map actions to component props (by prop name)
function mapDispatchToProps(propName) {
  switch (propName) {
    case 'handleAddWeek':
      return (dispatch) => ({
        handleAddWeek(week, weekNumber) {
          dispatch(addWeekAction(week, weekNumber));
        },
      });
    case 'handleChangeProgress':
      return (dispatch) => ({
        handleChangeProgress(step, headerTitle) {
          dispatch(changeProgressAction(step, headerTitle));
        },
      });
    case 'toggleModal':
      return (dispatch) => ({
        toggleModal(modalToggled) {
          dispatch(toggleModalAction(modalToggled));
        },
        dispatch,
      });
    case 'hideTabs':
      return (dispatch) => ({
        hideTabs(tabsHidden) {
          dispatch(hideTabsAction(tabsHidden));
        },
      });
    default:
      console.error(
        'SnellApp, mapDispatchToProps: propName must exist in the switch statement.',
      );
  }
}

/* Exports */
export { reservationReducer, mapStateToProps, mapDispatchToProps };
