import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectSlotAction, toggleModalAction } from '../../actions/index';
import SnellAdvanceIcon from '../../assets/images/icon_advance.svg';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import SnellTimeFrame from '../../components/SnellTimeFrame/SnellTimeFrame';
import { translate as tl } from '../../utils/helpers.js';
import { isLimitedSlot, isAvailableSlot } from '../../utils/slotHelpers.js';
import './SnellReservation.css';

const styles = {
  tooltip: {
    fontSize: '1.3em',
    color: '#ae0f0f',
    backgroundColor: '#ffffff',
    border: '1.5px solid #ae0f0f',
    padding: '20px',
    maxWidth: '240px',
  },
  title: {
    fontSize: '1.3em',
    color: '#ae0f0f',
    fontFamily: 'Nunito, sans-serif',
  },
  subheading: {
    fontSize: '1.05em',
    color: '#ae0f0f',
    fontFamily: 'Nunito, sans-serif',
  },
};

class SnellReservationSlot extends Component {
  render() {
    const noRootCanalTreatments = !this.props.customization
      .noRootCanalTreatments
      ? tl('noRootCanalTreatments')
      : this.props.customization.noRootCanalTreatments.join('');

    const pleaseNote = !this.props.customization.pleaseNote
      ? tl('Please note')
      : this.props.customization.pleaseNote;

    const slot = this.props.slot;
    const advanceInUse =
      this.props.customization.reservationFees['NORMAL'] !==
      this.props.customization.reservationFees['ADVANCE'];

    const tooltipTitle = (classes) => {
      const { tooltip, ...klasses } = classes;
      return (
        <div>
          <Typography classes={klasses} variant="title" align="center">
            {pleaseNote}{' '}
          </Typography>
          <Typography classes={klasses} variant="subheading" align="left">
            {noRootCanalTreatments}
          </Typography>
        </div>
      );
    };
    const addTooltip = (link) => {
      if (this.props.thisIsTheLastSlot === true) {
        const { title, subheading, ...klasses } = this.props.classes;
        return (
          <Tooltip classes={klasses} title={tooltipTitle(this.props.classes)}>
            {link}
          </Tooltip>
        );
      } else {
        return link;
      }
    };

    const style = this.props.topMargin
      ? { marginTop: this.props.topMargin }
      : {};

    const slotRestrictionsOfInterest = this.props.customization
      .slotRestrictionsOfInterest
      ? this.props.customization.slotRestrictionsOfInterest
      : [];

    const available = isAvailableSlot(slot, slotRestrictionsOfInterest);
    const limited = isLimitedSlot(slot, slotRestrictionsOfInterest);

    if (slot.endTime === undefined) {
      return null;
    } else if (!slot.reservationPossible || !available) {
      return (
        <li
          className={`SnellReservationSlot ${this.props.className}`}
          style={style}
        >
          <span className="SnellReservationSlot__link SnellReservationSlot__link--reserved">
            <SnellTimeFrame
              date={slot.startTime.dateObject}
              strongStart={true}
            />
          </span>
        </li>
      );
    } else if (!this.props.currentReservation) {
      // Current reservation is not set, we're making a new one
      const link = (
        <Link
          className={`SnellReservationSlot__link ${
            limited ? ' SnellReservationSlot__link--limited' : null
          }`}
          to={`${this.props.match.url}${tl('confirm')}?slot=${slot.id}&ls=${
            this.props.thisIsTheLastSlot
          }`}
          onClick={() => {
            this.props.dispatch(selectSlotAction(slot));
          }}
        >
          {advanceInUse && this.props.advanceSlot === true ? (
            <img
              src={SnellAdvanceIcon}
              className="SnellReservationSlot__icon"
              alt="Advance"
            />
          ) : null}
          <SnellTimeFrame date={slot.startTime.dateObject} strongStart={true} />
        </Link>
      );

      return (
        <li
          className={`SnellReservationSlot ${this.props.className}`}
          style={style}
        >
          {addTooltip(link)}
        </li>
      );
    } else {
      // Move current reservation
      const currentReservation = this.props.currentReservation;
      const isSelectedSlot = currentReservation.reservationSlotId === slot.id;
      const link = (
        <Link
          className={`SnellReservationSlot__link ${
            !limited ? null : ' SnellReservationSlot__link--limited'
          }`}
          to={`${this.props.match.url}${tl('confirm-change')}/${
            currentReservation.code
          }`}
          onClick={() => {
            this.props.dispatch(toggleModalAction(false));
            this.props.dispatch(selectSlotAction(slot));
          }}
        >
          {advanceInUse && this.props.advanceSlot === true ? (
            <img
              src={SnellAdvanceIcon}
              className="SnellReservationSlot__icon"
              alt="Advance"
            />
          ) : null}
          <SnellTimeFrame date={slot.startTime.dateObject} strongStart={true} />
        </Link>
      );

      return (
        <li
          className={`SnellReservationSlot ${this.props.className} ${
            isSelectedSlot ? ' SnellReservationSlot--current' : null
          }`}
          style={style}
        >
          {addTooltip(link)}
        </li>
      );
    }
  }
}

const CtdSnellReservationSlot = connect(mapStateToProps(['customization']))(
  SnellReservationSlot,
);

export default withStyles(styles)(CtdSnellReservationSlot);
