import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { withRouter } from 'react-router-dom';

import {
  setTemporaryReservationAction,
  customerLogout,
} from '../../actions/index';

import './SnellHeader.css';
import homeLogo from '../../assets/images/icon_home.svg';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '../../assets/scripts/redux.js';
import SnellCalendar from '../../components/SnellCalendar/SnellCalendar.js';
import {
  translate as tl,
  reservationApi,
  getHeaderTitle,
} from '../../utils/helpers.js';

/* eslint-disable */
import SnellIconInfo from '-!svg-react-loader?name=SnellIconInfo!../../assets/images/icon_information.svg';
import SnellIconArrowLeft from '-!svg-react-loader?name=SnellIconArrowLeft!../../assets/images/icon_ball_arrow_left.svg';
/* eslint-enable */

const handleLogout = (props) => {
  if (props.customer) {
    props.dispatch(customerLogout());
  } else if (props.authenticatedUser) {
    props.history.push(`/logout?status=logging-out`);
  }
};

const Logout = (props) => {
  return (
    <li
      className="logoutButton SnellHeader__step"
      role="button"
      aria-label={tl('Logout')}
      onClick={() => handleLogout(props)}
    >
      {tl('Logout')}
    </li>
  );
};

class SnellHeader extends Component {
  renderTabs(step) {
    return (
      <nav className="SnellHeader__top">
        {this.props.customization.clinicUrl && (
          <div className="SnellHeader__clinic-link">
            <a
              href={this.props.customization.clinicUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={homeLogo} height="25px" alt={tl('go_to_clinic_page')} />
            </a>
          </div>
        )}
        <ul className="SnellHeader__progress">
          <li className={`SnellHeader__step ${step === 1 ? 'is-current' : ''}`}>
            {this.props.customization.firstStep
              ? tl(this.props.customization.firstStep)
              : tl('Select Timeslot')}
          </li>
          <li className={`SnellHeader__step ${step === 2 ? 'is-current' : ''}`}>
            {tl('Confirm Reservation')}
          </li>
          {this.props.freeStatus === true ? null : (
            <li
              className={`SnellHeader__step ${step === 3 ? 'is-current' : ''}`}
            >
              {tl('Reservation Fee')}
            </li>
          )}
          <li className={`SnellHeader__step ${step === 4 ? 'is-current' : ''}`}>
            {tl('Confirmation')}
          </li>
          {this.props.customization.anamnesisEnabled && (
            <li
              className={`SnellHeader__step ${step === 5 ? 'is-current' : ''}`}
            >
              {tl('Preliminary Information')}
            </li>
          )}
          {(this.props.customer !== null ||
            this.props.authenticatedUser !== null) && <CtdLogout />}
        </ul>
      </nav>
    );
  }

  render() {
    const step = this.props.progress.step;
    const {
      isEditing,
      customization,
      contract,
      advanceOnly,
      progress,
    } = this.props;
    const headerTitle = getHeaderTitle(
      isEditing,
      customization,
      contract,
      advanceOnly,
      progress,
    );

    if (
      !this.props.introStatus &&
      !window.location.href.includes(`${tl('change-reservation')}`) &&
      !window.location.href.includes(`${tl('confirm-change')}`) &&
      !window.location.href.includes(`${tl('confirmed')}`)
    ) {
      return null;
    }

    return (
      <div className="SnellHeader">
        {!this.props.tabsHidden && this.props.isEditing !== true && step > -1
          ? this.renderTabs(step)
          : null}
        <div className="SnellHeader__bottom">
          <CtdSnellHeaderLeft
            step={step}
            isEditing={this.props.isEditing}
            reservationCode={this.props.reservationCode}
          />

          <h1 className="SnellHeader__title">{headerTitle}</h1>

          <SnellHeaderRight
            step={step}
            isMobile={this.props.isMobile}
            handleFetchData={this.props.handleFetchData}
            contract={this.props.contract}
            slotRestrictionsOfInterest={
              this.props?.customization?.slotRestrictionsOfInterest
            }
            currentWeekNumber={this.props.currentWeekNumber}
          />
        </div>
      </div>
    );
  }
}

class SnellHeaderLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalContent: null,
    };

    this.customStyles = {
      overlay: {
        background: 'rgba(0,0,0,0.7)',
      },
      content: {
        position: 'absolute',
        top: '110px',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translateX(-50%)',
        zIndex: 99,
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cancelMovingReservation = this.cancelMovingReservation.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const infoBlurb = this.props.customization.bookingInfo.join('');
    this.setState({ modalContent: infoBlurb });
  }

  cancelMovingReservation() {
    this.props.toggleModal(false);
    this.props.history.push(
      `/${tl('confirmed')}/${this.props.reservationCode}`,
    );
  }

  cancelCreatingReservation() {
    const temporaryReservation = this.props.temporaryReservation;

    if (temporaryReservation.id) {
      // Remove temporary reservation on back arrow link
      reservationApi(
        'POST',
        'reservation',
        'removeTemporaryReservation',
        { reservationId: temporaryReservation.id },
        {},
      )
        .then((response) => {
          this.props.dispatch(setTemporaryReservationAction({}));
        })
        .catch((error) => {
          this.setState({
            errorCreatingReservation: true,
          });
        });
    }

    if (this.props.contract) {
      this.props.history.push(`/?contract=${this.props.contract}`);
    } else {
      this.props.history.push(`/`);
    }
  }

  render() {
    let content = '';
    let headerLink;

    Modal.setAppElement('#root');

    if (this.props.isEditing && this.props.reservationCode !== undefined) {
      content = (
        <div className="SnellHeaderLeft">
          <a
            href="/"
            className="SnellHeader__cancel-link"
            onClick={(evt) => {
              evt.preventDefault();
              this.cancelMovingReservation();
            }}
          >
            {tl('Cancel')}
          </a>
        </div>
      );
    } else {
      switch (this.props.step) {
        case 1:
          content = (
            <div className="SnellHeaderLeft">
              {this.props.modalToggled === false ? (
                <button
                  className="Snell__button Snell__button--info"
                  id="info-button"
                  aria-label={tl('More information')}
                  onClick={this.openModal}
                >
                  <SnellIconInfo className="Snell__icon " />
                  <span className="Snell__button-label">Info</span>
                </button>
              ) : null}
              {headerLink}
              <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={this.customStyles}
                contentLabel={tl('Making a reservation')}
                className="SnellReservationInfo"
              >
                <a
                  href="/"
                  className="SnellHeader__modal-close"
                  role="button"
                  aria-label={tl('Close')}
                  onClick={(evt) => {
                    evt.preventDefault();
                    this.closeModal();
                  }}
                >
                  {tl('Close')}
                </a>
                <h2 className="SnellHeader__title">
                  {tl('Making a reservation')}
                </h2>
                <div className="SnellHeader__modal-content">
                  {this.state.modalContent !== null
                    ? renderHTML(this.state.modalContent)
                    : null}
                </div>
              </Modal>
            </div>
          );
          break;

        case 2:
          content = (
            <div className="SnellHeaderLeft SnellHeaderLeft--morePadding">
              <a
                href="/"
                className="Snell__button"
                id="cancel-button"
                aria-label={tl('Cancel')}
                onClick={(evt) => {
                  evt.preventDefault();
                  this.cancelCreatingReservation();
                }}
              >
                <SnellIconArrowLeft className="Snell__icon" />
              </a>
            </div>
          );
          break;

        default:
          content = <div className="SnellHeaderLeft" />;
          break;
      }
    }

    return content;
  }
}

class SnellHeaderRight extends Component {
  render() {
    return (
      <div className="SnellHeaderRight">
        {this.props.step === 1 && (
          <SnellCalendar
            handleFetchData={this.props.handleFetchData}
            isMobile={this.props.isMobile}
            contract={this.props.contract}
            slotRestrictionsOfInterest={
              this.props?.slotRestrictionsOfInterest || []
            }
            currentWeekNumber={this.props.currentWeekNumber}
          />
        )}
      </div>
    );
  }
}

const CtdLogout = withRouter(
  connect(mapStateToProps(['customer', 'authenticatedUser']))(Logout),
);

const CtdSnellHeader = connect(
  mapStateToProps([
    'progress',
    'contract',
    'freeStatus',
    'firstWeekWithData',
    'introStatus',
    'customization',
    'customer',
    'authenticatedUser',
    'tabsHidden',
  ]),
)(SnellHeader);

const CtdSnellHeaderLeft = withRouter(
  connect(
    mapStateToProps([
      'modalToggled',
      'temporaryReservation',
      'contract',
      'customization',
    ]),
    mapDispatchToProps('toggleModal'),
  )(SnellHeaderLeft),
);

export default CtdSnellHeader;
