/* View container for 'Confirm reservation' step. */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { changeProgressAction } from '../../actions/index';
import inputOk from '../../assets/images/icon_input_ok.svg';
import inputWarning from '../../assets/images/icon_input_warning.svg';
import timetable from '../../assets/images/icon_timetable.svg';
import warning from '../../assets/images/icon_warning.svg';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import SnellInput from '../../components/SnellInput/SnellInput';
import SnellTimeFrame from '../../components/SnellTimeFrame/SnellTimeFrame';
import {
  formatMomentDate,
  reservationApi,
  translate as tl,
} from '../../utils/helpers.js';
import { isLimitedSlot } from '../../utils/slotHelpers.js';

/* eslint-disable */
import SnellIconTimetableSmall from '-!svg-react-loader?name=SnellIconTimetableSmall!../../assets/images/icon_timetable_small.svg';
import SnellIconClock from '-!svg-react-loader?name=SnellIconClock!../../assets/images/icon_clock.svg';
/* eslint-enable */

import './SnellConfirmChange.css';

const icons = {
  timetable,
  warning,
  inputOk,
  inputWarning,
};

class SnellConfirmChange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reservation: {},
      completingReservation: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(
      changeProgressAction(-1, tl('Confirm change of reservation')),
    );

    if (this.props.match.params.code) {
      reservationApi('GET', 'reservation', 'getReservationByCode', {
        code: this.props.match.params.code,
      })
        .then((response) => {
          this.setState({
            reservation: response.data,
            isLoading: false,
          });
          // this.props.dispatch(changeProgressAction(4, tl("Your reservation is confirmed")));
        })
        .catch((error) => {
          this.setState({
            // eslint-disable-next-line no-mixed-operators
            reservation: {
              status: (error.response && error.response.status) || 418,
            }, // Just a general error, asking to go back.
          });
          console.error(`SnellApp, SnellComplete: ${error}`);
        });
    } else {
      // The user probably accessed the page directly.
      this.setState({
        reservation: { status: 418 }, // Just a general error, asking to go back.
      });
    }
  }

  render() {
    const statusCodeGroup = '2';

    const originalPrice = parseFloat(
      this.state.reservation.originalReservationFee,
    );
    const reservationFees = this.props.customization.reservationFees;
    const newPrice = this.props.priceList
      ? reservationFees[this.props.slot.priceList.pricing]
      : originalPrice;
    const higherFee = originalPrice < newPrice;

    return !this.state.isLoading ? (
      <div className="SnellConfirmChange row">
        <div className="col">
          <img
            className="SnellConfirmChange__icon SnellConfirmChange__icon--timetable"
            src={icons.timetable}
            alt=""
          />
        </div>
        <div className="col">
          {statusCodeGroup === '2' && this.state.reservation ? (
            <SnellConfirmChangeContent
              slot={this.props.slot}
              match={this.props.match}
              reservation={this.state.reservation}
              completingReservation={this.completingReservation}
              history={this.props.history}
              higherFee={higherFee}
              slotRestrictionsOfInterest={
                this.props.customization.slotRestrictionsOfInterest
              }
            />
          ) : (
            <SnellConfirmChangeWarning
              top={true}
              message={`${tl('Something went wrong')}, ${tl(
                'please go back and reselect a slot',
              )}`}
            />
          )}
        </div>
        <div className="col" />
      </div>
    ) : (
      <div className="SnellReservationView__loaderWrapper">
        <div className="SnellReservationView__loader">Loading...</div>
      </div>
    );
  }
}

class SnellConfirmChangeContent extends Component {
  render() {
    if (!this.props.reservation) {
      return null;
    }
    const slot = this.props.slot;

    if (!slot.priceList) {
      return (
        <Redirect
          to={`/${tl('change-reservation')}/${this.props.reservation.code}`}
        />
      );
    }
    const date = slot.startTime.dateObject;

    const slotRestrictionsOfInterest = this.props.slotRestrictionsOfInterest;

    const limited = isLimitedSlot(slot, slotRestrictionsOfInterest);

    return (
      <div className="SnellConfirmChangeContent">
        <p>
          {tl(
            'You are making a change to your reservation. Your new time slot will be:',
          )}
        </p>
        <span className="SnellConfirmChangeContent__dateTime">
          <SnellIconTimetableSmall className="SnellConfirmChange__icon SnellConfirmChange__icon--timetableSmall" />
          <span className="SnellConfirmChangeContent__date">
            {formatMomentDate(date, true)}
          </span>
        </span>{' '}
        <span className="SnellConfirmChangeContent__dateTime">
          <SnellIconClock className="SnellConfirmChange__icon SnellConfirmChange__icon--clock" />
          <SnellTimeFrame date={date} />
          <Link
            className="SnellConfirmChangeContent__changeLink SnellApp__link"
            to={`/${tl('change-reservation')}/${this.props.reservation.code}`}
          >
            {tl('Change')}
          </Link>
        </span>
        {limited ? (
          <SnellConfirmChangeWarning
            message={tl('Limited availability of treatments')}
          />
        ) : null}
        {this.props.higherFee ? (
          <SnellConfirmChangeWarning
            message={tl(
              'The new time slot you selected has higher reservation fee than the original one. The difference will be paid at the clinic.',
            )}
          />
        ) : null}
        <CtdSnellConfirmChangeForm
          reservation={this.props.reservation}
          newSlot={slot}
          history={this.props.history}
        />
      </div>
    );
  }
}

class SnellConfirmChangeWarning extends Component {
  render() {
    const message = this.props.message;

    return message ? (
      <div
        className={`SnellConfirmChangeWarning ${
          this.props.top ? 'SnellConfirmChangeWarning--top' : ''
        }`}
      >
        <img
          className="SnellConfirmChange__icon SnellConfirmChange__icon--warning"
          src={icons.warning}
          alt=""
        />
        <span>{message}</span>
      </div>
    ) : null;
  }
}

class SnellConfirmChangeForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    reservationApi(
      'POST',
      'reservation',
      'moveReservation',
      {},
      {
        reservationId: this.props.reservation.id,
        newSlotId: this.props.newSlot.id,
      },
    )
      .then((response) => {
        this.props.history.push(
          `/${tl('confirmed')}/${this.props.reservation.code}`,
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <form className="SnellConfirmChangeForm" onSubmit={this.handleSubmit}>
        <SnellInput type="submit" value={tl('Confirm change')} />
      </form>
    );
  }
}

// Redux Connected Component
const CtdSnellConfirmChange = connect(
  mapStateToProps(['slot', 'customization']),
)(SnellConfirmChange);

const CtdSnellConfirmChangeForm = connect()(SnellConfirmChangeForm);

export default CtdSnellConfirmChange;
