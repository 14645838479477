import React, { Component } from 'react';

import './SnellTextarea.css';
import arrowRight from '../../assets/images/icon_arrow_right.svg';
import inputCheck from '../../assets/images/icon_input_check.svg';
import inputCross from '../../assets/images/icon_input_cross.svg';
import inputOk from '../../assets/images/icon_input_ok.svg';
import inputWarning from '../../assets/images/icon_input_warning.svg';

const icons = {
  arrowRight,
  inputCheck,
  inputCross,
  inputOk,
  inputWarning,
};

class SnellTextarea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: null,
      focus: false,
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.handleClick();
  };

  handleFocusBlur = (e) => {
    this.setState({
      focus: e.type === 'focus',
    });
  };

  render() {
    const additionalClasses = this.props.className ? this.props.className : '';
    const isSubmit = this.props.type === 'submit';
    const isButton = this.props.type === 'button';
    const hasFocus = this.state.focus ? 'SnellTextarea--focus' : '';

    let icon;
    let isValid = '';

    if (isSubmit || isButton) {
      icon = 'arrowRight';
    } else {
      switch (this.props.valid) {
        case true:
          icon = 'inputCheck';
          isValid = 'SnellTextarea--valid';
          break;
        case false:
          icon = 'inputCross';
          isValid = 'SnellTextarea--invalid';
          break;
        default:
        // Unknown
      }
    }

    return (
      <div
        className={`SnellTextarea ${
          isSubmit ? 'SnellTextarea--submit' : 'SnellTextarea--input'
        } ${hasFocus} ${isValid} ${additionalClasses}`}
      >
        <textarea
          ref={(input) => (this.input = input)}
          name={this.props.name}
          onChange={(e) => {
            this.props.onChange(e);
          }}
          onBlur={this.handleFocusBlur}
          onClick={this.props.onClick}
          onFocus={this.handleFocusBlur}
          value={this.props.value}
          placeholder={this.props.placeholder}
          tabIndex={this.props.htmlTabIndex}
          disabled={this.props.disabled}
        />
        {icon ? (
          <img
            className={`SnellTextarea__icon SnellTextarea__icon--${icon}`}
            src={icons[icon]}
            alt=""
          />
        ) : null}
        {icon === 'inputCheck' ? (
          <img
            className={'SnellTextarea__tag SnellTextarea__tag--inputOk'}
            src={icons.inputOk}
            alt=""
          />
        ) : null}
        {icon === 'inputCross' ? (
          <img
            className={'SnellTextarea__tag SnellTextarea__tag--inputWarning'}
            src={icons.inputWarning}
            alt=""
          />
        ) : null}
      </div>
    );
  }
}

export default SnellTextarea;
