import { reservationApi } from '../utils/helpers';

const getReservationSlotsBetween = async (startTime, endTime, contract) => {
  const slots = await reservationApi(
    'GET',
    'reservationslot',
    'getReservationSlotsBetween',
    {
      startTime,
      endTime,
      contract,
    },
  );
  return slots;
};

export { getReservationSlotsBetween };
