import React, { Component } from 'react';

import SnellInput from '../../components/SnellInput/SnellInput';
import { translate as tl } from '../../utils/helpers.js';

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: props.content,
    };
  }

  createText(elem, key) {
    return (
      <div
        key={key}
        className="question-text"
        dangerouslySetInnerHTML={{ __html: elem.value.join('') }}
      />
    );
  }

  updateQuestion(index, answer) {
    this.setState((state) => {
      const updated = state.content.map((elem, i) => {
        if (i !== index) {
          return elem;
        } else {
          return {
            ...elem,
            answer: answer,
          };
        }
      });

      return {
        content: updated,
      };
    });
  }

  createQuestion(elem, key, next) {
    const yes = elem.answer === true;
    const no = elem.answer === false;
    return (
      <div key={key} className={`question ${next ? 'selected' : ''}`}>
        {next && (
          <div key="next" className="attention">
            ▶
          </div>
        )}
        {this.createText(elem, 'q')}
        <div className="form">
          <div className="button-container">
            <div
              className={`label ${yes ? 'selected' : ''}`}
              onClick={(e) => this.updateQuestion(key, true)}
            >
              {tl('Yes')}
            </div>
          </div>

          <div className="button-container">
            <div
              className={`label ${no ? 'selected' : ''}`}
              onClick={(e) => this.updateQuestion(key, false)}
            >
              {tl('No')}
            </div>
          </div>
        </div>
      </div>
    );
  }

  submitQuestionnaire() {
    // TODO: Make "pass" condition somehow configurable
    const passed = this.state.content
      .filter((page) => page.type === 'question')
      .every((q) => q.answer === false);

    if (passed) {
      this.props.onSuccess();
    } else {
      this.props.onFail();
    }
  }

  render() {
    const next = this.state.content.findIndex(
      (e) => e.type === 'question' && e.answer === undefined,
    );

    const elems = this.state.content.map((elem, ind) => {
      if (elem.type === 'text') {
        return this.createText(elem, ind);
      } else if (elem.type === 'question') {
        return this.createQuestion(elem, ind, ind === next);
      } else {
        return null;
      }
    });

    const hasAnswers = this.state.content
      .filter((page) => page.type === 'question')
      .every((q) => q.answer !== undefined);

    return (
      <div className="question-page intro-page">
        <div className="content">{elems}</div>
        <SnellInput
          type="button"
          onClick={() => this.submitQuestionnaire()}
          disabled={!hasAnswers}
        />
      </div>
    );
  }
}

export default Questionnaire;
