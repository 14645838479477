import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { Link, Redirect } from 'react-router-dom';

import 'react-select/dist/react-select.css';

import { changeProgressAction } from '../../actions/index';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import SnellHealthQuestion from '../../components/SnellHealthQuestion/SnellHealthQuestion';
import SnellInput from '../../components/SnellInput/SnellInput';
import SnellTextarea from '../../components/SnellTextarea/SnellTextarea';
import {
  reservationApi,
  formatDateAndTime,
  getCurrentLanguage,
  translate as tl,
  translateAmnesisData as tla,
} from '../../utils/helpers.js';

import config from './SnellAmnesis.config';

import './SnellAmnesis.css';
import { anamnesisInfo } from '../../data/anamnesis.js';

class SnellAmnesis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reservation: {},
      isLoading: true,
      lastModified: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(changeProgressAction(5, tl('Preliminary Information')));

    if (this.props.match.params.code) {
      reservationApi('GET', 'reservation', 'getReservationByCode', {
        code: this.props.match.params.code,
      })
        .then((response) => {
          this.setState({ reservation: response.data });
          if (
            response.data.customer === null &&
            response.data.customer === undefined
          ) {
            // No customer
          } else {
            reservationApi('GET', 'reservation', 'getLatestAnamnesis', {
              customer: response.data.customer,
            })
              .then((response) => {
                this.setState({
                  lastModified: response.data.modified,
                  isLoading: false,
                });
              })
              .catch((error) => {
                this.setState({
                  isLoading: false,
                });
              });
          }
        })
        .catch((error) => {
          this.setState({
            // eslint-disable-next-line no-mixed-operators
            reservation: {
              status: (error.response && error.response.status) || 418,
            }, // Just a general error, asking to go back.
          });
        });
    } else {
      // The user probably accessed the page directly.
      console.log('No reservation code');
      this.setState({
        reservation: { status: 418 }, // Just a general error, asking to go back.
      });
    }
  }

  render() {
    const statusCodeGroup = '2';

    return statusCodeGroup ? (
      <div className="SnellAmnesis row">
        <div className="col">
          {statusCodeGroup === '2' ? (
            <SnellAmnesisContent
              slot={this.props.slot}
              reservation={this.state.reservation}
              match={this.props.match}
              location={this.props.location}
              isLoading={this.state.isLoading}
              lastModified={this.state.lastModified}
              completingReservation={this.completingReservation}
              history={this.props.history}
              customization={this.props.customization}
            />
          ) : null}
        </div>
      </div>
    ) : (
      <div className="SnellReservationView__loaderWrapper">
        <div className="SnellReservationView__loader">Loading...</div>
      </div>
    );
  }
}

class SnellAmnesisContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  componentDidMount() {
    const infoBlurb = this.props.customization.bookingInfo.join('');
    this.setState({ modalContent: infoBlurb });
  }

  goToEdit() {
    this.setState({ edit: true });
  }

  render() {
    if (this.props.isLoading === true) {
      return (
        <div className="SnellReservationView__loaderWrapper">
          <div className="SnellReservationView__loader">Loading...</div>
        </div>
      );
    } else if (
      this.props.lastModified !== null &&
      this.props.lastModified !== 0 &&
      this.state.edit === false
    ) {
      return (
        <form className="SnellAmnesisForm SnellAmnesisForm--tight">
          <p>
            {tla('lastModified')}{' '}
            <strong>
              {formatDateAndTime(new Date(this.props.lastModified), true)}
            </strong>
            .
          </p>
          <p>{tla('ifChanged')}</p>
          <SnellInput
            type="button"
            onClick={(e) => {
              e.preventDefault();
              this.goToEdit();
            }}
            value={tl('Proceed to update your information')}
          />
          <Link to={`/${tl('confirmed')}/${this.props.match.params.code}`}>
            {tl('My health information has not changed')}
          </Link>
        </form>
      );
    } else {
      return (
        <div className="SnellAmnesisContent">
          <CtdSnellAmnesisForm
            reservation={this.props.reservation}
            match={this.props.match}
            location={this.props.location}
            completingReservation={this.props.completingReservation}
            history={this.props.history}
          />
        </div>
      );
    }
  }
}

class SnellAmnesisForm extends Component {
  constructor(props) {
    super(props);

    const healthQuestions = {};
    _.each(config.healthQuestions, (q) => {
      healthQuestions[q.key] = null;
    });

    const questionInformation = {};
    _.each(config.healthQuestions, (q) => {
      questionInformation[q.infoKey] = null;
    });

    const questionError = {};
    _.each(config.healthQuestions, (q) => {
      questionError[q.key] = false;
    });
    _.each(config.otherQuestions, (q) => {
      questionError[q.key] = false;
    });

    const otherQuestions = {};
    _.each(config.otherQuestions, (q) => {
      otherQuestions[q.key] = null;
    });

    this.state = {
      edit: false,
      saved: false,
      infoContent: null,
      infoLoading: true,
      forwardToCheckin: false,
      firstName: { value: '' },
      lastName: { value: '' },
      occupation: { value: '' },
      address: { value: '' },
      postalCode: { value: '' },
      postalAddress: { value: '' },
      details: { value: '' },
      healthQuestions,
      otherQuestions,
      questionInformation,
      questionError,
    };
  }

  componentDidMount() {
    const lang = getCurrentLanguage();
    const anamnesis = anamnesisInfo(lang);
    const urlParams = new URLSearchParams(this.props.location.search);
    const forwardToCheckin = urlParams.get('forwardToCheckin');

    this.setState({
      infoContent: anamnesis,
      infoLoading: false,
      forwardToCheckin: forwardToCheckin === 'true',
    });
  }

  toggleAnswer(questionKey, value, type = 'health') {
    if (type === 'health') {
      this.setState((prevState) => ({
        healthQuestions: {
          ...prevState.healthQuestions,
          [questionKey]: value,
        },
        questionError: {
          ...prevState.questionError,
          [questionKey]: false,
        },
      }));
    } else {
      this.setState((prevState) => ({
        otherQuestions: {
          ...prevState.otherQuestions,
          [questionKey]: value,
        },
        questionError: {
          ...prevState.questionError,
          [questionKey]: false,
        },
      }));
    }
  }

  handleQuestionInfoChange(questionKey, value, isDate = false) {
    if (isDate === true) {
      this.setState((prevState) => ({
        questionInformation: {
          ...prevState.questionInformation,
          [questionKey]: value.format('YYYY-MM-DD'),
        },
      }));
    } else {
      this.setState((prevState) => ({
        questionInformation: {
          ...prevState.questionInformation,
          [questionKey]: value,
        },
      }));
    }
  }

  handleChange = (e) => {
    let valid;
    const value =
      e.target.type === 'checkbox'
        ? !this.state[e.target.name].value
        : e.target.value;

    if (e.target.name === 'postalCode') {
      // We require postal code to be 5 chars long and only numbers allowed
      const postalCodeCheck = /[0123456789]{5}/;
      valid = postalCodeCheck.test(value);
    }

    this.setState({
      [e.target.name]: {
        value,
        valid,
      },
    });
  };

  invalidFields() {
    const fieldStatus = {};

    _.each(config.healthQuestions, (q) => {
      if (
        this.state.healthQuestions[q.key] !== true &&
        this.state.healthQuestions[q.key] !== false
      ) {
        fieldStatus[q.key] = true;
      } else {
        fieldStatus[q.key] = false;
      }
    });

    _.each(config.otherQuestions, (q) => {
      if (
        this.state.otherQuestions[q.key] !== true &&
        this.state.otherQuestions[q.key] !== false
      ) {
        fieldStatus[q.key] = true;
      } else {
        fieldStatus[q.key] = false;
      }
    });

    this.setState((prevState) => ({
      questionError: fieldStatus,
    }));

    fieldStatus.postalCode = !this.state.postalCode.valid;

    console.log(fieldStatus);

    return _.filter(fieldStatus, (val) => {
      return val === true;
    }).length;
  }

  getPayload() {
    const payload = {
      firstName: this.state.firstName.value,
      lastName: this.state.lastName.value,
      address: this.state.address.value,
      postalCode: this.state.postalCode.value,
      postalArea: this.state.postalAddress.value,
      occupation: this.state.occupation.value,
      details: this.state.details.value,
    };

    _.each(this.state.healthQuestions, (item, key) => {
      if (item !== null) {
        _.extend(payload, { [key]: item });
      }
    });
    _.each(this.state.questionInformation, (item, key) => {
      _.extend(payload, { [key]: item });
    });

    _.each(this.state.otherQuestions, (item, key) => {
      if (item !== null) {
        _.extend(payload, { [key]: item });
      }
    });

    return payload;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.invalidFields() === 0) {
      const postData = this.getPayload();

      reservationApi(
        'POST',
        'reservation',
        'updateAnamnesis',
        {
          reservationId: this.props.reservation.id,
        },
        postData,
      )
        .then((response) => {
          if (response.data.modified) {
            this.setState({ saved: true });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  handleReasonChange = (selectedOption) => {
    this.setState({ reasons: selectedOption });
  };

  render() {
    if (this.state.infoLoading) {
      return (
        <div className="SnellReservationView__loaderWrapper">
          <div className="SnellReservationView__loader">Loading...</div>
        </div>
      );
    }

    if (this.state.saved === false) {
      return (
        <form className="SnellAmnesisForm" onSubmit={this.handleSubmit}>
          {this.state.infoContent !== null
            ? renderHTML(this.state.infoContent)
            : null}

          <SnellInput
            name="firstName"
            type="text"
            onChange={this.handleChange}
            valid={this.state.firstName.valid}
            value={this.state.firstName.value}
            placeholder={tla('firstName')}
          />

          <SnellInput
            name="lastName"
            type="text"
            onChange={this.handleChange}
            valid={this.state.lastName.valid}
            value={this.state.lastName.value}
            placeholder={tla('lastName')}
          />

          <SnellInput
            name="address"
            type="text"
            onChange={this.handleChange}
            valid={this.state.address.valid}
            value={this.state.address.value}
            placeholder={tla('address')}
          />

          <SnellInput
            name="postalCode"
            type="text"
            onChange={this.handleChange}
            valid={this.state.postalCode.valid}
            value={this.state.postalCode.value}
            maxLength="5"
            placeholder={tla('postalCode')}
          />

          <SnellInput
            name="postalAddress"
            type="text"
            onChange={this.handleChange}
            valid={this.state.postalAddress.valid}
            value={this.state.postalAddress.value}
            placeholder={tla('city')}
          />

          <SnellInput
            name="occupation"
            type="text"
            onChange={this.handleChange}
            valid={this.state.occupation.valid}
            value={this.state.occupation.value}
            placeholder={tla('occupation')}
          />

          <SnellTextarea
            name="details"
            type="text"
            onChange={this.handleChange}
            valid={this.state.details.valid}
            value={this.state.details.value}
            placeholder={tla('moreInformation')}
          />

          <h3>{tla('health')}</h3>

          {config.healthQuestions.map((item) => {
            return (
              <div key={item.key}>
                <SnellHealthQuestion
                  questionKey={item.key}
                  infoKey={item.infoKey}
                  toggleAnswer={(q, v) => this.toggleAnswer(q, v, 'health')}
                  hasError={this.state.questionError[item.key]}
                  handleInfoChange={(value) => {
                    this.handleQuestionInfoChange(
                      item.infoKey,
                      value,
                      item.options.isDate,
                    );
                  }}
                  options={item.options}
                />
                <hr />
              </div>
            );
          })}

          <h3>{tla('otherQuestions')}</h3>

          {config.otherQuestions.map((item) => {
            return (
              <div key={item.key}>
                <SnellHealthQuestion
                  questionKey={item.key}
                  toggleAnswer={(q, v) => this.toggleAnswer(q, v, 'other')}
                  hasError={this.state.questionError[item.key]}
                  options={item.options}
                />
                <hr />
              </div>
            );
          })}

          <SnellInput type="submit" value={tl('Save information')} />
        </form>
      );
    } else if (this.state.forwardToCheckin) {
      return <Redirect to={`/checkin/${this.props.match.params.code}?`} />;
    } else {
      return (
        <Redirect to={`/${tl('confirmed')}/${this.props.match.params.code}`} />
      );
    }
  }
}

// Redux Connected Component
const CtdSnellAmnesis = connect(mapStateToProps(['slot', 'customization']))(
  SnellAmnesis,
);

const CtdSnellAmnesisForm = connect()(SnellAmnesisForm);

export default CtdSnellAmnesis;
