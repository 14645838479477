import React, { useEffect, useRef, useState } from 'react';

import 'react-day-picker/style.css';
import './SnellCalendar.css';
import {
  differenceInCalendarISOWeeks,
  endOfDay,
  lastDayOfMonth,
} from 'date-fns';
import moment from 'moment';
import { DayPicker, Root, getDefaultClassNames } from 'react-day-picker';

import { getReservationSlotsBetween } from '../../service/ReservationSlotService';
import {
  getCurrentLanguage,
  getDateFnsLocale,
  getDatesBetween,
} from '../../utils/helpers';

/* eslint-disable */
import SnellCalendarIcon from '-!svg-react-loader?name=SnellCalendarIcon!../../assets/images/calendar.svg';
/* eslint-enable */
import {
  divideDaysWithLimitations,
  divideSlotLimitationsByDate,
} from '../../utils/slotHelpers';

import SnellCalendarCloseButton from './SnellCalendarCloseButton';
import SnellCalendarLoader from './SnellCalendarLoader';

const SnellCalendar = (props) => {
  const {
    contract,
    handleFetchData,
    isMobile,
    slotRestrictionsOfInterest,
    currentWeekNumber,
  } = props;

  const [loading, setLoading] = useState(false);
  const [limited, setLimited] = useState([]);
  const [unlimited, setUnlimited] = useState([]);
  const [disabled, setDisabled] = useState([]);

  const dialogRef = useRef();

  const today = new Date();
  const lang = getCurrentLanguage();
  const defaultClassNames = getDefaultClassNames();

  useEffect(() => {
    updateCalendarLimitations(today);
  }, [contract]);

  const toggleDialog = () => {
    if (dialogRef?.current?.open) {
      dialogRef.current.close();
    } else {
      isMobile ? dialogRef.current.showModal() : dialogRef.current.show();
    }
  };

  const handleSelect = (e) => {
    if (e) {
      let periodStart;
      if (isMobile) {
        periodStart = moment(e).add(-1, 'day');
      } else {
        const firstDayOfCurrentWeek = moment()
          .isoWeek(currentWeekNumber)
          .startOf('isoWeek')
          .toDate();

        periodStart =
          currentWeekNumber +
          differenceInCalendarISOWeeks(e, firstDayOfCurrentWeek);
      }

      handleFetchData(periodStart, true, contract);
    }
    if (dialogRef?.current) {
      dialogRef.current.close();
    }
  };

  // Fetches slots for current month in SnellCalendar. Calendar days are styled based on slot availabilities.
  const updateCalendarLimitations = async (start) => {
    setLoading(true);
    // If month start is less than today, use today as start time of period.
    const realStart = Math.max(new Date(start), today);

    const startTimeMillis = moment(realStart).format('X');
    const endTime = endOfDay(lastDayOfMonth(realStart));
    const endTimeMillis = moment(endTime).format('X');

    // Set the days initially rendered in calendar to be disabled initially
    const monthDays = getDatesBetween(start, lastDayOfMonth(start));
    setDisabled(monthDays);

    try {
      const slots = await getReservationSlotsBetween(
        startTimeMillis,
        endTimeMillis,
        contract,
      );

      if (!slots?.data) {
        return;
      }

      const days = divideSlotLimitationsByDate(
        slots.data,
        slotRestrictionsOfInterest,
      );

      const {
        limitedDays,
        unlimitedDays,
        disabledDays,
      } = divideDaysWithLimitations(monthDays, days);

      setLimited(limitedDays.map((d) => new Date(d)));
      setUnlimited(unlimitedDays.map((d) => new Date(d)));
      setDisabled(disabledDays.map((d) => new Date(d)));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={toggleDialog}
        className="Snell__button SnellCalendarIconWrapper"
      >
        <SnellCalendarIcon className="Snell__icon icon" />
      </button>
      <dialog ref={dialogRef} className="SnellCalendarWrapper">
        <DayPicker
          today={today}
          disabled={[...disabled, { before: today }]}
          modifiers={{ limited, unlimited }}
          classNames={{
            nav: `${defaultClassNames.nav} rdpCustomNavigation`,
            month_caption: `${defaultClassNames.month_caption} rdpCustomMonthCaption`,
          }}
          modifiersClassNames={{
            limited: 'SnellCalendarDay--limited',
            unlimited: 'SnellCalendarDay--unlimited',
          }}
          components={{
            Root: (props) => (
              <>
                <SnellCalendarCloseButton
                  handleClick={() => {
                    if (dialogRef?.current) {
                      dialogRef.current.close();
                    }
                  }}
                />
                <Root {...props} />
              </>
            ),
          }}
          showWeekNumber
          fixedWeeks
          mode={'single'}
          onSelect={handleSelect}
          onMonthChange={updateCalendarLimitations}
          footer={loading && <SnellCalendarLoader />}
          lang={lang}
          locale={getDateFnsLocale(lang)}
        />
      </dialog>
    </div>
  );
};

export default SnellCalendar;
