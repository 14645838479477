import getISOWeek from 'date-fns/getISOWeek';
import moment from 'moment';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import {
  changeProgressAction,
  selectContractAction,
} from '../../actions/index';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import SlotInfoPanel from '../../components/SlotInfoPanel/SlotInfoPanel';
import { translate as tl } from '../../utils/helpers.js';

import SnellReservationDay from './SnellReservationDay';
import './SnellReservation.css';
import SnellReservationWeekNavigation from './SnellReservationWeekNavigation.js';

const queryString = require('query-string');

class SnellReservationWeek extends Component {
  constructor(props) {
    super(props);
    this.fireGTMevent();
  }

  fireGTMevent() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'loadBookingCalendar',
    });
  }

  componentDidMount() {
    const contract = this.getCurrentContract();

    if (contract) {
      this.props.dispatch(selectContractAction(contract));
    } else {
      this.props.dispatch(selectContractAction(0));
    }

    // Initial data load. Data loading functionality located in SnellReservation component.
    // Data loading is located to SnellReservation so that we can easily alter the data in
    // SnellHeader (arrow navigation) and pass it forward to SnellReservationWeek component

    if (this.props.isMobile === true) {
      const startDay = moment().startOf('day');
      this.props.fetchDays(
        startDay,
        'changeCurrentDays',
        this.getCurrentContract(),
      );
    } else {
      this.props.fetchWeek(
        getISOWeek(this.props.today),
        'changeCurrentWeek',
        this.getCurrentContract(),
      );
    }

    this.props.dispatch(changeProgressAction(1, tl('Reservation')));
  }

  getCurrentContract() {
    let contract;

    const queryParams = queryString.parse(window.location.search);

    // Contracts
    if (queryParams.contract) {
      contract = queryParams.contract;
    } else if (this.props.contract) {
      contract = this.props.contract;
    } else {
      contract = null;
    }

    return contract;
  }

  render() {
    const noRootCanalTreatments = !this.props.customization
      .noRootCanalTreatments
      ? tl('noRootCanalTreatments')
      : this.props.customization.noRootCanalTreatments.join('');

    let days = {};

    if (!this.props.weeklyData && !this.props.dailyData) {
      return null;
    }

    if (this.props.isMobile === false) {
      days = this.props.weeklyData;
      days = days[this.props.currentWeekNumber];
    } else {
      const dayIndex = this.props.currentDay.format('X');
      days = this.props.dailyData;
      days = days[dayIndex];
    }

    const touchScreenUserUI = () => {
      if (isMobile) {
        return (
          <div className="mobile">
            <SlotInfoPanel customization={this.props.customization} />
            <ul>
              <li className="mobileWarning">{noRootCanalTreatments}</li>
            </ul>
          </div>
        );
      } else {
        return null;
      }
    };

    const firstHour = days
      ? days
          .map((dd) =>
            dd
              .filter((s) => !s.fake)
              .map((s) => moment(s.startTime.dateObject).hour())
              .reduce((a, b) => (a === null || a > b ? b : a), null),
          )
          .filter((a) => a !== null)
          .reduce((a, b) => (a === null || a > b ? b : a), null)
      : null;
    return (
      <div className="relative">
        <SnellReservationWeekNavigation
          currentWeek={this.props.currentWeekNumber}
          currentDay={this.props.currentDay}
          contract={this.getCurrentContract()}
          isMobile={this.props.isMobile}
          firstWeekWithData={this.props.firstWeekWithData}
          handleFetchData={this.props.handleFetchData}
          today={this.props.today}
        />
        <div className="SnellReservationWeek">
          {days ? (
            days.map((day, key) => {
              return (
                <SnellReservationDay
                  key={`${day[0]}_${key}`}
                  day={day}
                  today={this.props.today}
                  match={this.props.match}
                  currentReservation={this.props.currentReservation}
                  firstHour={firstHour}
                />
              );
            })
          ) : (
            <div className="SnellReservationView__loaderWrapper">
              <div className="SnellReservationView__loader">Loading...</div>
            </div>
          )}
        </div>
        <div>{touchScreenUserUI()}</div>
      </div>
    );
  }
}

const CtdSnellReservationWeek = connect(
  mapStateToProps([
    'weeklyData',
    'dailyData',
    'firstWeekWithData',
    'startDays',
    'customization',
    'authenticatedUser',
  ]),
)(SnellReservationWeek);

export default CtdSnellReservationWeek;
