import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import alertRound from '../../assets/images/alert-round.png';
import inputOk from '../../assets/images/icon_input_ok.svg';
import inputWarning from '../../assets/images/icon_input_warning.svg';
import timetable from '../../assets/images/icon_timetable.svg';
import warning from '../../assets/images/icon_warning.svg';
import { mapStateToProps } from '../../assets/scripts/redux.js';
import SnellCheckbox from '../../components/SnellCheckbox/SnellCheckbox';
import SnellInput from '../../components/SnellInput/SnellInput';
import {
  translate as tl,
  getCurrentLanguage,
  getISOlanguage,
  ssnValidator,
  validatePhoneNumber,
  dateOfBirthFormat,
  isAllowedToBook,
} from '../../utils/helpers.js';

import './CustomerForm.css';

const icons = {
  timetable,
  warning,
  inputOk,
  inputWarning,
  alertRound,
};

const initValue = (field, def, fixedValues) => {
  const hasValue = fixedValues && fixedValues[field];
  if (hasValue) {
    return {
      value: fixedValues[field],
      valid: hasValue,
      canEdit: !['ssn', 'dateOfBirth', 'lastName'].includes(field),
    };
  } else {
    return { value: def, valid: null, canEdit: true };
  }
};

const initializeFormData = (fixedValues) => {
  const data = {
    ssn: initValue('ssn', '', fixedValues),
    firstName: initValue('firstName', '', fixedValues),
    lastName: initValue('lastName', '', fixedValues),
    dateOfBirth: initValue('dateOfBirth', '', fixedValues),
    email: initValue('email', '', fixedValues),
    phone: initValue('phone', '', fixedValues),
    terms: { value: '' },
    reasons: initValue('reasons', [], fixedValues),
    limitedTreatments: {
      value: 'limitedTreatments',
      valid: true,
      canEdit: false,
    },
    customerFormNotification: {
      value: 'customerFormNotification',
      valid: true,
      canEdit: false,
    },
    details: initValue('details', '', fixedValues),
  };
  return data;
};

// Fields for Form

const textInput = (name, placeholder, onChange, inputState, onFocusChange) => (
  <SnellInput
    key={name}
    name={name}
    type="text"
    onChange={onChange}
    valid={inputState.valid}
    value={inputState.value}
    disabled={!inputState.canEdit}
    placeholder={placeholder}
    onFocusChange={onFocusChange}
  />
);

// {value, valid, canEdit, handleChange}
const ssnField = (onChange, inputState, onFocusChange) =>
  textInput(
    'ssn',
    tl('Social security number'),
    onChange,
    inputState,
    onFocusChange,
  );

const emailField = (onChange, inputState, onFocusChange) =>
  textInput('email', tl('Email'), onChange, inputState, onFocusChange);

const phoneField = (onChange, inputState, onFocusChange) =>
  textInput('phone', tl('Phone number'), onChange, inputState, onFocusChange);

const lastNameField = (onChange, inputState, onFocusChange) =>
  textInput('lastName', tl('Lastname'), onChange, inputState, onFocusChange);

const firstNameField = (onChange, inputState, onFocusChange) =>
  textInput('firstName', tl('Firstname'), onChange, inputState, onFocusChange);

const dateOfBirthField = (dobFormat) => (onChange, inputState, onFocusChange) =>
  textInput(
    'dateOfBirth',
    `${tl('Date of birth')} (${tl(dobFormat)})`,
    onChange,
    inputState,
    onFocusChange,
  );

const reasonsField = (options) => (
  onChange,
  inputState,
  onFocusChange_ignored,
) => (
  <div>
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label className="visuallyhidden" htmlFor="reasons">
      {tl('Reasons')}
    </label>
    <Select
      key="reasons"
      name="reasons"
      id="reasons"
      className={`SnellAmnesis__reason-select ${
        inputState.valid === false ? 'Reasons_invalid' : ''
      }`}
      value={inputState.value}
      onChange={onChange}
      disabled={!inputState.canEdit}
      multi={true}
      placeholder={tl('Reasons')}
      closeOnSelect={false}
      options={options}
    />
  </div>
);

const detailsField = () => (onChange, inputState, onFocusChange) => (
  // eslint-disable-next-line no-sequences
  (inputState.valid = true),
  textInput('details', tl('Details'), onChange, inputState, onFocusChange)
);

const detailsFieldAsBSN = (detailsText) => (
  onChange,
  inputState,
  onFocusChange,
) =>
  textInput(
    'details',
    detailsText ? detailsText : tl('Details'),
    onChange,
    inputState,
    onFocusChange,
  );

const termsField = (agreementUrl) => (
  onChange,
  inputState,
  onFocusChange_ignored,
) => (
  <div
    className={`CustomerForm__terms ${
      inputState.value === false ? 'CustomerForm__terms--invalid' : ''
    }`}
    key="termsCheckbox"
  >
    <SnellCheckbox
      id="terms"
      name="terms"
      checked={inputState.value}
      handleChange={onChange}
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label htmlFor="terms">
      {tl('I have read and agree to')}{' '}
      {agreementUrl ? (
        <a className="SnellApp__link" href={agreementUrl} target="__blank">
          {tl('the terms')}
        </a>
      ) : (
        tl('the terms')
      )}
    </label>
  </div>
);

const formNotificationField = (limited, message) => (
  onChange,
  inputState,
  onFocusChange,
) => <div>{limited && <CustomerFormNotification message={message} />}</div>;

const ssnInvalid = (minimumAge, patientTooYoungMsg, useBirthYearAge) => (
  ssn,
) => {
  const validSsn = ssnValidator()(ssn);

  if (!validSsn) {
    return tl('Invalid ssn');
  } else if (minimumAge && !isAllowedToBook(minimumAge, ssn, useBirthYearAge)) {
    return patientTooYoungMsg || true;
  } else {
    return false;
  }
};

const emailInvalid = (email) => {
  const emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

  return emailCheck.test(email) ? false : tl('Invalid email address');
};

const phoneInvalid = (region) => (phone) => {
  const isValid = validatePhoneNumber(phone, region);
  return !isValid ? tl('Invalid phone number') : false;
};

const lastNameInvalid = (lastName) => {
  return lastName.trim().length < 2 ? tl('Too short last name') : false;
};

const firstNameInvalid = (firstName) => {
  return firstName.trim().length < 2 ? tl('Too short first name') : false;
};

const dateOfBirthInvalid = (format, minimumAge, patientTooYoungMsg) => (
  dob,
) => {
  if (dob.length !== 10) {
    return true;
  }
  for (let i = 0; i < format.length; ++i) {
    const shouldBeNum = ['D', 'M', 'Y'].includes(format.charAt(i));
    const isNum = !isNaN(dob.charAt(i));
    if (shouldBeNum !== isNum) {
      return true;
    }
  }

  const date = moment(dob, format);
  if (!date.isValid()) {
    return 'incorrect_date';
  } else if (minimumAge) {
    // This can be fooled as it is local computer time but should not really matter
    const now = moment(moment.now());
    if (moment.duration(now.diff(date)).years() < minimumAge) {
      return patientTooYoungMsg && true;
    }
  }
  return false;
};

const reasonsInvalid = (maxReasonAmount) => (reasons) => {
  if (maxReasonAmount && reasons.length > maxReasonAmount) {
    const errorMsg = `${tl(
      'Too many reservation reasons',
    )} ${maxReasonAmount})`;
    return errorMsg;
  }
  return reasons.length ? false : tl('You need to select a reservation reason');
};

const termsInvalid = (terms) => {
  return terms;
};

const detailsInvalid = (details) => {
  return false;
};

const limitedTreatmentsInvalid = (limitedTreatments) => {
  return limitedTreatments;
};

const formatDobForSubmit = (format, dob) => {
  const defaultFormat = 'YYYY-MM-DD';
  if (format === defaultFormat) {
    return dob;
  } else {
    return moment(dob, format).format(defaultFormat);
  }
};

const toString = (maybeStr) => (typeof maybeStr === 'string' ? maybeStr : null);

const onFormFieldChange = (
  field,
  cbs,
  oldData,
  fields,
  dobFormat,
  minimumAge,
  tooYoungMsg,
  maxReasonAmount,
  region,
  useBirthYearAge,
) => (e) => {
  let value =
    field === 'reasons'
      ? e
      : e.target.type === 'checkbox'
      ? !oldData[field].value
      : e.target.value;

  const validatorFuncs = {
    ssn: ssnInvalid(minimumAge, tooYoungMsg, useBirthYearAge),
    email: emailInvalid,
    phone: phoneInvalid(region),
    lastName: lastNameInvalid,
    firstName: firstNameInvalid,
    dateOfBirth: dateOfBirthInvalid(dobFormat, minimumAge, null),
    reasons: reasonsInvalid(maxReasonAmount),
    terms: termsInvalid,
    limitedTreatments: limitedTreatmentsInvalid,
    details: detailsInvalid,
  };

  if (field === 'ssn') {
    value = value.toUpperCase();
  } else if (field === 'email') {
    value = value.toLowerCase();
  }

  const isInvalidMsg = validatorFuncs[field](value);
  const updated = {
    ...oldData,
    [field]: {
      value,
      valid: !isInvalidMsg,
      errorMsg: toString(isInvalidMsg),
      canEdit: oldData[field].canEdit,
    },
  };
  updated.valid = fields.map((f) => updated[f]).every((input) => input.valid);

  cbs.forEach((cb) => cb(updated));
};

const extractValues = (formData, dobFormat) => {
  const language = getCurrentLanguage();
  const isoLanguage = getISOlanguage(language);
  const reasonIds = _.map(formData.reasons.value, 'value');

  const params = {
    ssn: formData.ssn.value,
    dateOfBirth: formatDobForSubmit(dobFormat, formData.dateOfBirth.value),
    lastName: formData.lastName.value.trim(),
    firstName: formData.firstName.value.trim(),
    phone: formData.phone.value.trim(),
    email: formData.email.value,
    details: formData.details.value,
  };

  // Set the locale if not Finnish
  if (isoLanguage !== 'fi') {
    _.extend(params, { locale: isoLanguage });
  }

  // Set the reasons if set
  if (reasonIds.length > 0) {
    _.extend(params, { reasons: reasonIds });
  }
  return params;
};

// props: formData, submit
// state: triedSubmit, errorMsg
class CustomerFormF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      triedSubmit: false,
      submitting: false,
      errorMsg: null,
      submitDisabled: false,
      fieldInFocus: null,
      complainedFields: new Set(),
      performedInitialValidation: false,
    };
  }

  componentDidMount() {
    // Perform initial validation for ssn field if it has a prefilled value
    const { customization, settings } = this.props;
    const { ssn } = this.props.formData;

    const useBirthYearAge = settings.customer_age_check_by_year === 'true';

    if (!this.state.performedInitialValidation && ssn.value) {
      const ssnValidation = ssnInvalid(
        customization.minimumAge,
        customization.patientTooYoungErrorMessage,
        useBirthYearAge,
      )(ssn.value);

      if (ssnValidation) {
        // If the ssn is invalid, update the state with the error message
        this.props.onChange({
          ...this.props.formData,
          ssn: {
            ...ssn,
            valid: false,
            errorMsg: toString(ssnValidation),
          },
        });
      }

      // Update the flag to indicate that the initial validation has been performed
      this.setState({ performedInitialValidation: true });
    }
  }

  onFocusChange = (field) => (hasFocus) => {
    this.setState((ps) => {
      let fieldInFocus;
      const complainedFields = new Set(this.state.complainedFields);
      const oldData = this.props.formData[ps.fieldInFocus];

      if (!hasFocus) {
        if (ps.fieldInFocus !== field) {
          fieldInFocus = ps.fieldInFocus;
        } else {
          fieldInFocus = null;
          if (oldData && oldData.valid === false) {
            complainedFields.add(field);
          }
        }
      } else {
        if (oldData && oldData.valid === false) {
          complainedFields.add(ps.fieldInFocus);
        }
        fieldInFocus = field;
      }

      return { fieldInFocus, complainedFields };
    });
  };

  submit = () => {
    if (this.state.submitting) {
      return true;
    }

    this.setState({ submitting: true });

    const dobFormat = dateOfBirthFormat(this.props.customization);
    const params = extractValues(this.props.formData, dobFormat);
    // Submit needs to use side effects to keep things going
    this.props
      .submit(params)
      .then((status) => {
        if (!status.ok) {
          this.setState({
            submitting: false,
            errorMsg: status.errorMsg,
            submitDisabled: !status.canRecover,
          });
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      triedSubmit: true,
    });
    if (this.props.valid) {
      const values = extractValues(
        this.props.formData,
        dateOfBirthFormat(this.props.customization),
      );
      this.submit(values);
    }
  };

  resetState = (updated) =>
    this.setState((ps) => {
      return {
        triedSubmit: false,
        errorMsg: null,
        complainedFields: new Set(
          [...ps.complainedFields].filter(
            (f) => updated[f] && !updated[f].valid,
          ),
        ),
      };
    });

  formFields = () => {
    const dobFormat = dateOfBirthFormat(this.props.customization);
    const agreementUrl = this.props.customization.agreementUrl;
    const limited = this.props.limited;
    const limitedSlotNotification = this.props.customization
      .limitedSlotNotification
      ? this.props.customization.limitedSlotNotification
      : tl('Limited availability of treatments');
    const customerFormNotification = this.props.customization
      .customerFormNotification;
    const maxReasonAmount = this.props.customization.maxReasonAmount;
    const formFuncs = {
      ssn: ssnField,
      email: emailField,
      phone: phoneField,
      lastName: lastNameField,
      firstName: firstNameField,
      dateOfBirth: dateOfBirthField(dobFormat),
      reasons: reasonsField(this.props.formData.reasonOptions),
      terms: termsField(agreementUrl),
      limitedTreatments: formNotificationField(
        limited,
        limitedSlotNotification,
      ),
      customerFormNotification: formNotificationField(
        true,
        customerFormNotification,
      ),
      details: this.props.customization.detailsFieldAsBSN
        ? detailsFieldAsBSN(this.props.customization.detailsFieldAsBSN)
        : detailsField(),
    };
    const minimumAge = this.props.customization.minimumAge;
    const tooYoungMessage = this.props.customization
      .patientTooYoungErrorMessage;
    const region = this.props.customization.region;
    const useBirthYearAge = this.props.settings.customer_age_check_by_year;

    return this.props.fields.map((field) => {
      const changeCb = onFormFieldChange(
        field,
        [this.props.onChange, this.resetState],
        this.props.formData,
        this.props.fields,
        dobFormat,
        minimumAge,
        tooYoungMessage,
        maxReasonAmount,
        region,
        useBirthYearAge,
      );
      return formFuncs[field](
        changeCb,
        this.props.formData[field],
        this.onFocusChange(field),
      );
    });
  };

  renderErrorBox(errors) {
    return (
      <div className="errorBox">
        <p>{tl('Form contains invalid values')}</p>
        <ul>
          {errors
            .filter((err) => err)
            .map((err, i) => (
              <li key={i}>{err}</li>
            ))}
        </ul>
      </div>
    );
  }

  render() {
    const errors = this.props.customization.confirmationFields
      .filter(
        (f) =>
          this.state.complainedFields.has(f) || this.state.fieldInFocus !== f,
      )
      .map((f) => this.props.formData[f])
      .filter((d) => d.valid === false)
      .map((d) => d.errorMsg);

    return (
      <form className="CustomerForm" onSubmit={this.handleSubmit}>
        {errors.length > 0 && this.renderErrorBox(errors)}
        {this.formFields()}

        <div className="CustomerForm__errors">
          {this.state.errorMsg && (
            <CustomerFormWarning message={this.state.errorMsg} />
          )}
        </div>

        <div className="CustomerForm__submit">
          <SnellInput
            type="submit"
            disabled={!this.props.valid || this.state.triedSubmit}
            value={this.props.submitText}
          />
        </div>
      </form>
    );
  }
}

class CustomerFormWarning extends Component {
  render() {
    const message = this.props.message;

    return message ? (
      <div
        className={`CustomerFormWarning ${
          this.props.top ? 'CustomerFormWarning--top' : ''
        }`}
      >
        <img
          className="SnellConfirm__icon SnellConfirm__icon--warning"
          src={icons.warning}
          alt=""
        />
        <span>{message}</span>
      </div>
    ) : null;
  }
}

class CustomerFormNotification extends Component {
  render() {
    const message = this.props.message;

    return message ? (
      <div
        className={`CustomerFormNotification ${
          this.props.top ? 'CustomerFormNotification--top' : ''
        }`}
      >
        <img
          className="SnellConfirm__icon SnellConfirm__icon--notification"
          src={icons.alertRound}
          alt=""
        />
        <span>{message}</span>
      </div>
    ) : null;
  }
}

const CustomerForm = connect(
  mapStateToProps(['customization', 'authenticatedUser', 'settings']),
)(CustomerFormF);

// Wrapper to provide state management for customer form
class ManagedCustomerForm extends Component {
  constructor(props) {
    super(props);
    this.state = initializeFormData(props.fixedValues);

    if (props.reasonOptions) {
      this.state.reasonOptions = [...props.reasonOptions].sort((a, b) =>
        a.label <= b.label ? -1 : 1,
      );
    }
  }

  onChange = (formData) => {
    this.setState(formData);
  };

  render() {
    return (
      <CustomerForm
        formData={this.state}
        onChange={this.onChange}
        valid={this.state.valid}
        {...this.props}
      />
    );
  }
}

export { initializeFormData, extractValues, ManagedCustomerForm, CustomerForm };
