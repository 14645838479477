import Checkbox from '@material-ui/core/Checkbox';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import React, { Component } from 'react';

/* eslint-disable */
import SnellIconCheckbox from '-!svg-react-loader?name=SnellIconCheckbox!../../assets/images/icon_checkbox.svg';
import SnellIconCheckboxChecked from '-!svg-react-loader?name=SnellIconCheckboxChecked!../../assets/images/icon_checkbox_checked.svg';
/* eslint-enable */

import './SnellCheckbox.css';

class SnellCheckbox extends Component {
  componentDidMount() {
    this.input.addEventListener('focus', () => {
      this.isFocused = true;
      this.forceUpdate();
    });

    this.input.addEventListener('blur', () => {
      this.isFocused = false;
      this.forceUpdate();
    });
  }

  render() {
    let className = 'SnellCheckbox';

    if (this.isFocused) {
      className += ' SnellCheckbox--focused';
    }

    if (this.props.checked === false) {
      className += ' SnellCheckbox--invalid';
    }

    return (
      <MuiThemeProvider
        theme={createMuiTheme({ palette: { primary: { main: '#78B9D2' } } })}
      >
        <Checkbox
          className={className}
          id={this.props.id}
          name={this.props.name}
          inputRef={(input) => (this.input = input)}
          onChange={(e) => this.props.handleChange(e)}
          checked={this.props.checked}
          color="primary"
          disableRipple={true}
          icon={<SnellIconCheckbox className="SnellIconCheckbox" />}
          checkedIcon={
            <SnellIconCheckboxChecked className="SnellIconCheckboxChecked" />
          }
        />
      </MuiThemeProvider>
    );
  }
}

export default SnellCheckbox;
