/* View container for 'Reservation complete' step. */

import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import { selectContractAction } from '../../actions/index.js';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '../../assets/scripts/redux.js';
import { isAuthInUse, reservationApi } from '../../utils/helpers.js';

import './SnellMoveReservation.css';
import '../SnellHeader/SnellHeader.css';
import '../SnellReservation/SnellReservation.css';
import AuthenticationScreen from '../AuthenticationScreen/AuthenticationScreen';
import SnellHeader from '../SnellHeader/SnellHeader';
import CtdSnellReservationWeek from '../SnellReservation/SnellReservationWeek';

class SnellMoveReservation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reservation: {},
      isLoading: true,
      modalIsOpen: true,
    };

    this.customStyles = {
      overlay: {
        background: 'rgba(0,0,0,0.7)',
      },
      content: {
        top: '110px',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        position: 'absolute',
        transform: 'translateX(-50%)',
      },
    };
    this.afterOpenModal = this.afterOpenModal.bind(this);
  }

  componentDidMount() {
    if (this.props.currentCode) {
      reservationApi('GET', 'reservation', 'getReservationByCode', {
        code: this.props.currentCode,
      })
        .then((response) => {
          this.setState({
            reservation: response.data,
            isLoading: false,
          });
          if (!isNaN(Number(response.data.contract))) {
            this.props.dispatch(selectContractAction(response.data.contract));
          }
        })
        .catch((error) => {
          this.setState({
            // eslint-disable-next-line no-mixed-operators
            reservation: {
              status: (error.response && error.response.status) || 418,
            }, // Just a general error, asking to go back.
          });
          console.error(`SnellApp, SnellMoveReservation: ${error}`);
        });
    } else {
      // The user probably accessed the page directly.
      this.setState({
        reservation: { status: 418 }, // Just a general error, asking to go back.
      });
    }
  }

  afterOpenModal() {
    this.props.toggleModal(true);
  }

  render() {
    Modal.setAppElement('#root');

    if (
      isAuthInUse() &&
      this.props.authenticatedUser &&
      this.state.reservation.customer_ssn !== this.props.authenticatedUser.ssn
    ) {
      return <AuthenticationScreen status={'user-mismatch'} />;
    }

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={this.customStyles}
        contentLabel="Example Modal"
        className="SnellMoveReservation"
      >
        <MediaQuery maxWidth={799}>
          <SnellHeader
            handleFetchData={this.props.handleFetchData}
            currentWeekNumber={this.props.currentWeekNumber}
            isEditing={true}
            reservationCode={this.state.reservation.code}
            isMobile={this.props.isMobile}
            currentDay={this.props.currentDay}
          />
          <CtdSnellReservationWeek
            handleFetchData={this.props.handleMobileFetchData}
            week={this.props.week}
            today={this.props.today}
            match={this.props.match}
            currentDay={this.props.currentDay}
            currentWeekNumber={this.props.currentWeekNumber}
            contract={this.props.contract}
            isEditing={true}
            fetchDays={this.props.fetchDays}
            currentReservation={this.state.reservation}
            weekPart={this.props.weekPart}
            isMobile={this.props.isMobile}
            numDays={this.props.numDays}
            offset={this.props.offset}
          />
        </MediaQuery>
        <MediaQuery minWidth={800}>
          <SnellHeader
            handleFetchData={this.props.handleFetchData}
            currentWeekNumber={this.props.currentWeekNumber}
            isEditing={true}
            reservationCode={this.state.reservation.code}
            isMobile={this.props.isMobile}
            offset={this.props.offset}
          />
          <CtdSnellReservationWeek
            handleFetchData={this.props.handleFetchData}
            data={this.props.weeklyData}
            today={this.props.today}
            match={this.props.match}
            contract={this.props.contract}
            isEditing={true}
            currentReservation={this.state.reservation}
            weekPart={this.props.weekPart}
            numDays={7}
            currentDay={this.props.currentDay}
            fetchWeek={this.props.fetchWeek}
            currentWeekNumber={this.props.currentWeekNumber}
            advanceOnly={this.props.advanceOnly}
            weekNumber={this.props.currentWeekNumber}
            isMobile={false}
          />
        </MediaQuery>
      </Modal>
    );
  }
}

// Redux Connected Component
const CtdSnellMoveReservation = connect(
  mapStateToProps(['weeklyData', 'contract', 'authenticatedUser']),
  mapDispatchToProps('toggleModal'),
)(SnellMoveReservation);

export default CtdSnellMoveReservation;
