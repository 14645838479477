export default {
  healthQuestions: [
    // General
    {
      key: 'generalHealth',
      infoKey: 'generalHealthInfo',
      options: {
        true: 'good',
        false: 'notGood',
        detailsRequiredFor: false,
      },
    },
    {
      key: 'drugs',
      infoKey: 'drugsList',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'allergies',
      infoKey: 'allergiesList',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'pregnant',
      infoKey: 'pregnantDueDate',
      options: {
        detailsRequiredFor: true,
        isDate: true,
      },
    },
    {
      key: 'smokeOrSnuff',
      infoKey: 'smokeOrSnuffInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'localAnestheticProblems',
      infoKey: 'localAnestheticProblemsInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'radiation',
      infoKey: 'radiationInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    // Antibiotics needed
    {
      key: 'carditisOrEndocarditis',
      infoKey: 'carditisOrEndocarditisInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'valveOrProsthesis',
      infoKey: 'valveOrProsthesisInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'congenitalHeartMalformation',
      infoKey: 'congenitalHeartMalformationInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'artificialJoints',
      infoKey: 'artificialJointsInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    // Illnesses
    {
      key: 'asthmaOrLungDisease',
      infoKey: null,
    },
    {
      key: 'epilepsy',
      infoKey: null,
    },
    {
      key: 'hiv',
      infoKey: null,
    },
    {
      key: 'hepatitis',
      infoKey: null,
    },
    {
      key: 'diabetes',
      infoKey: null,
    },
    {
      key: 'coldsores',
      infoKey: null,
    },
    {
      key: 'hypertension',
      infoKey: null,
    },
    {
      key: 'mentalIllness',
      infoKey: null,
    },
    {
      key: 'rheumatics',
      infoKey: null,
    },
    {
      key: 'thyroidDisease',
      infoKey: 'thyroidDiseaseInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'cardioVascularDisease',
      infoKey: 'cardioVascularDiseaseInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'otherChronicDisease',
      infoKey: 'otherChronicDiseaseInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
    {
      key: 'otherFactorsAffectingTreatment',
      infoKey: 'otherFactorsAffectingTreatmentInfo',
      options: {
        detailsRequiredFor: true,
      },
    },
  ],
  otherQuestions: [
    {
      key: 'offerMailAllowed',
      infoKey: null,
    },
    {
      key: 'recallMailAllowed',
      infoKey: null,
    },
  ],
  reasons: [
    'CHECK_UP_AND_ASSESSMENT',
    'ACHE',
    'SENSITIVITY',
    'RESTORATION',
    'CRACK',
    'REMOVAL',
    'HYGIENIST',
    'OCCLUSAL_SPLINT',
    'ROOT_TREATMENT_CONTINUATION',
    'WHITENING',
    'TEETH_CLEANING',
  ],
  reasonsWithNoHygienist: [
    'CHECK_UP_AND_ASSESSMENT',
    'ACHE',
    'SENSITIVITY',
    'RESTORATION',
    'CRACK',
    'REMOVAL',
    'OCCLUSAL_SPLINT',
    'ROOT_TREATMENT_CONTINUATION',
  ],
};
